import { Divider, Input } from '@dls/react-core';
import { Icon } from '@dls/react-icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import React, { KeyboardEvent, MouseEventHandler, useState } from 'react';

export const SearchBox = ({
  id,
  onInput,
  onSearch,
}: {
  id: string;
  onInput: (input: string) => void;
  onSearch: (input: string) => void;
}) => {
  const [onChangeValue, setOnChangeValue] = useState<string>('');

  const handleMouseDownSearchBox: MouseEventHandler<Element> = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      onSearch((e.target as HTMLInputElement).value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setOnChangeValue(value);
    onInput(value);
  };

  const handleClearSearchBox = () => {
    setOnChangeValue('');
    onInput('');
    onSearch('');
  };

  const handleSearchButtonPressed = () => {
    onSearch(onChangeValue);
  };

  return (
    <>
      <Input
        inputProps={{ 'data-testid': `searchbox-${id}` }}
        type="text"
        className={clsx('DLS-SearchBox')}
        value={onChangeValue}
        onChange={handleChange}
        disableUnderline
        placeholder="Search subscriptions"
        autoComplete="off"
        onKeyDown={handleKeyDown}
        endAdornment={
          <InputAdornment position="end">
            <div>
              <IconButton onClick={handleClearSearchBox} onMouseDown={handleMouseDownSearchBox}>
                {onChangeValue ? <Icon name="CrossCircle" size="m" /> : null}
              </IconButton>
            </div>
            <div>
              {onChangeValue ? <Divider orientation="vertical" className="DLS-divider-vertical" flexItem /> : null}
            </div>
            <div>
              <IconButton onClick={handleSearchButtonPressed}>
                <Icon name="Search" size="m" />
              </IconButton>
            </div>
          </InputAdornment>
        }
      />
    </>
  );
};
