import React from 'react';

export const IcQuoteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-624 -802)">
        <g transform="translate(316 80)">
          <g transform="translate(24 706)">
            <g transform="translate(284 16)">
              <path d="M0 0H32V32H0z" />
              <path
                fill="#BFE2EB"
                d="M8.092 25.366c1.858 0 3.333-.526 4.424-1.578 1.092-1.052 1.638-2.401 1.638-4.048 0-1.646-.442-2.904-1.324-3.773-.883-.869-2.114-1.303-3.693-1.303-.929 0-1.603.251-2.02.754.464-2.515 2.461-4.596 5.991-6.243L10.67 5.333c-2.74 1.281-4.982 2.927-6.724 4.94-1.742 2.012-2.613 4.46-2.613 7.34 0 2.425.616 4.323 1.847 5.695 1.23 1.372 2.868 2.058 4.912 2.058zm16.513 0c1.858 0 3.333-.526 4.424-1.578 1.092-1.052 1.638-2.401 1.638-4.048 0-1.646-.442-2.904-1.324-3.773-.883-.869-2.114-1.303-3.693-1.303-.929 0-1.602.251-2.02.754.51-2.515 2.508-4.596 5.992-6.243l-2.44-3.842c-2.74 1.281-4.98 2.927-6.723 4.94-1.742 2.012-2.613 4.46-2.613 7.34 0 2.425.616 4.323 1.847 5.695 1.23 1.372 2.868 2.058 4.912 2.058z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
