import { Dialog, DialogContent, DialogTitle } from '@dls/react-core';
import React from 'react';
import styled from 'styled-components';

import { HideRootOnMobileGlobalStyle } from '../../global-style';
import { ColorDLS } from '../../utils/styling/colors';
import { Mobile } from '../../utils/styling/media-queries';
import { DLSIcon } from '../dls-icon/dls-icon';

export const IFrameDialog: React.FC<{ title: string; url?: string; onClose: () => void }> = ({
  onClose,
  title,
  url,
}) => {
  return (
    <>
      <HideRootOnMobileGlobalStyle />
      <StyledDialog
        open={true}
        onClose={onClose}
        aria-labelledby="iframe-dialog-title"
        aria-describedby="iframe-dialog-description"
      >
        <StyledDialogTitle id="iframe-dialog-title">
          {title}
          <CloseButton onClick={onClose} data-testid="iframe-dialog-close-button">
            <DLSIcon name="Cross" size={24} />
          </CloseButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledIframe src={url} allowFullScreen />
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
};

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const CloseButton = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

const StyledDialog = styled(Dialog)`
  && .MuiDialog-paper {
    width: 75vw;
    height: 75vh;

    ${Mobile} {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      max-width: 100vh;
      margin: 0;
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  .MuiTypography-root {
    font-family: CentraleSans, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: ${ColorDLS.Gray75};
  }

  &&&& {
    padding: 16px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  &&& {
    padding: 0;
    margin: 0;
    overflow: hidden;
    flex: 1;
  }
`;
