import { AppBar, NavigationTheme, Tab } from '@dls/react-core';
import { Tabs } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { Mobile } from '../../utils/styling/media-queries';

export type TabDefinition = Readonly<{ title: string; key: string }>;
type OnChangeTab = (event: React.ChangeEvent<Record<string, unknown>>, selectedTabIndex: number) => void;
export const TabBar: React.FC<{ tabs: TabDefinition[]; activeTabIndex: number; onChangeTab: OnChangeTab }> = ({
  tabs,
  activeTabIndex,
  onChangeTab,
}) => {
  return (
    <NavigationTheme>
      <AppBar position="fixed" color="secondary" style={{ marginTop: '64px' }}>
        <Tabs value={activeTabIndex} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <StyledTab label={tab.title} key={tab.key} />
          ))}
        </Tabs>
      </AppBar>
    </NavigationTheme>
  );
};

export const StyledTab = styled(Tab)`
  &&& {
    min-width: 144px;

    ${Mobile} {
      width: 50%;
    }
  }
`;
