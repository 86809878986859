import styled from 'styled-components';

import { ColorDLS } from '../../utils/styling/colors';
import { Mobile } from '../../utils/styling/media-queries';

export const PageHeaderTitle = styled.div`
  font-size: 14px;
  color: ${ColorDLS.Gray45};
  margin: 16px 0;

  ${Mobile} {
    margin: 16px 0 16px 16px;
  }
`;
