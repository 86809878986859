import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { NotificationContext } from '../../components/notification/notification-context';
import { Spinner } from '../../components/spinner/spinner';
import { useBookings } from '../../services/bookings/use-bookings';
import { useGetCourseDetails } from '../../services/course/use-get-course-details';
import { Mobile } from '../../utils/styling/media-queries';
import { DerivedBookingState, FrontendBookingState } from '../admin-page/booking-state';

import { CourseDetailCard } from './course-detail-card';

export const CourseDetailsPage: React.FC = () => {
  const params: { id: string } = useParams();
  const { data: courseDetails, loading: isCourseLoading, error } = useGetCourseDetails(params.id);
  const { bookedCourses, isLoading: isBookingsLoading } = useBookings();
  const { addNotification } = useContext(NotificationContext);

  const courseState = useMemo(() => {
    let mostImportantBookingState: DerivedBookingState | undefined;

    const sortedBookings = bookedCourses
      .filter((b) => b.courseId === courseDetails?.identifier)
      .sort((a, b) => b.lastUpdate.valueOf() - a.lastUpdate.valueOf());
    if (sortedBookings.length > 0) {
      const lastBooking = sortedBookings[0];
      if (lastBooking.expireDate && lastBooking.expireDate < new Date()) {
        mostImportantBookingState = FrontendBookingState.Expired;
      } else {
        mostImportantBookingState = lastBooking.state;
      }
    }
    return mostImportantBookingState;
  }, [bookedCourses, courseDetails]);

  if (error) {
    addNotification({
      title: '',
      error: true,
      body: error.message,
    });
  }
  const previousPageLink = courseDetails?.identifier === 'Effigos' ? '/' : '/courses';
  return (
    <PageContainer data-testid="course-details-page">
      <HeaderBar title={courseDetails?.title || ''} previousPage={previousPageLink} />
      <PageContent>
        {isCourseLoading || isBookingsLoading || !courseDetails ? (
          <Spinner />
        ) : (
          <CourseDetailCard courseDetails={courseDetails} state={courseState} />
        )}
      </PageContent>
    </PageContainer>
  );
};

const PageContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  ${Mobile} {
    margin-top: 0;
  }
`;
