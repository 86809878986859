import { LanguageCode } from '../i18n/language-code';

import { COOKIE_NOTICE_SCRIPT_URLPREFIX } from './ga360Constants';

const getCurrentCookieNoticeScriptLanguage: () => LanguageCode | null = () => {
  const scriptsCollection = document.getElementsByTagName('script');
  const scripts = Array.from(scriptsCollection);
  const cookieNoticeScript = scripts.find((s) => s.src.includes(COOKIE_NOTICE_SCRIPT_URLPREFIX));
  if (cookieNoticeScript !== undefined) {
    const re = new RegExp(/language=(.{2}_.{2})&/);
    const match = cookieNoticeScript.src.match(re);
    if (match) {
      const language = match[1];
      const languageCode = language.substring(0, 2) as LanguageCode;
      if (Object.values(LanguageCode).includes(languageCode)) {
        return languageCode;
      }
    }
  }
  return null;
};

export { getCurrentCookieNoticeScriptLanguage };
