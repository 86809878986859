import axios from 'axios';
import { computed, observable, runInAction } from 'mobx';
import { task } from 'mobx-task';

import { FullExam } from '../entities/backend/full-exam';
import { FullPdf } from '../entities/backend/full-pdf';
import { FullVideo } from '../entities/backend/full-video';

export class AssetsStore {
  @observable assetsAvailableForUser: (FullExam | FullVideo | FullPdf)[] = [];

  @observable private currentAssets: (FullExam | FullVideo | FullPdf | undefined)[] = [];

  @computed
  get isLoading() {
    return this.loadAssets.pending || this.loadAvailableAssets.pending;
  }

  @computed
  get courseAssets() {
    return this.currentAssets
      .filter((a) => a !== undefined)
      .slice()
      .sort((a, b) => (a === undefined || b === undefined ? 0 : a.sortIndex - b.sortIndex));
  }

  loadAvailableAssets = task.resolved(async () => {
    await runInAction(async () => {
      const { data: videos } = await axios.get<FullVideo[]>('/api/assets/videos');
      const { data: pdfs } = await axios.get<FullPdf[]>('/api/assets/pdfs');
      const { data: exams } = await axios.get<FullExam[]>('/api/assets/exams');
      videos.forEach((v) => {
        v.type = 'Video';
      });
      pdfs.forEach((v) => {
        v.type = 'Pdf';
      });
      exams.forEach((v) => {
        v.type = 'Exam';
      });
      this.assetsAvailableForUser = [...videos, ...pdfs, ...exams];
    });
  });

  loadAssets = task.resolved(async (assetIds: { id: string }[]) => {
    this.currentAssets = assetIds.map((a) =>
      this.assetsAvailableForUser.find((availableAsset) => a.id === availableAsset.sys.id),
    );
  });
}
