import styled from 'styled-components';

import { Mobile } from '../../utils/styling/media-queries';

export const PageContent = styled.section`
  ${Mobile} {
    width: 100%;
  }
  width: 648px;
`;
