import { Dialog, DialogContent, DialogTitle } from '@dls/react-core';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { HideRootOnMobileGlobalStyle } from '../global-style';
import { ColorDLS } from '../utils/styling/colors';
import { Mobile } from '../utils/styling/media-queries';

import { DLSIcon } from './dls-icon/dls-icon';

export type VideoItem = {
  id: string;
  title: string;
  coverImage: string;
  lengthSecond: number;
  videoUrl: string;
};

export interface VideoState {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

export interface VideoPlayerProps {
  title: string;
  progress: number;
  videoUrl: string;
  onClose: () => void;
  onUpdateVideoState?: (videoState: VideoState) => void;
  onUpdateDuration?: (duration: number) => void;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  onClose,
  title,
  videoUrl,
  progress,
  onUpdateVideoState,
  onUpdateDuration,
}) => {
  const [currentPlayer, setPlayer] = useState<ReactPlayer | undefined>(undefined);

  return (
    <>
      <HideRootOnMobileGlobalStyle />
      <StyledDialog
        open={true}
        onClose={onClose}
        aria-labelledby="video-dialog-title"
        aria-describedby="video-dialog-description"
        data-testid="video-player-dialog"
      >
        <DialogTitle id="video-dialog-title">
          {title}
          <CloseButton onClick={onClose} data-testid="video-player-close-button">
            <DLSIcon name="Cross" size={24} />
          </CloseButton>
        </DialogTitle>
        <DialogContent>
          <StyledDialogContentText id="video-dialog-description">
            <ReactPlayer
              role="video"
              preload="auto"
              playing={true}
              controls={true}
              url={videoUrl}
              onDuration={onUpdateDuration}
              onProgress={onUpdateVideoState}
              onReady={(player) => {
                setPlayer(player);
              }}
              onSeek={(seconds) =>
                onUpdateVideoState &&
                onUpdateVideoState({ playedSeconds: seconds, played: seconds, loadedSeconds: 0, loaded: 0 })
              }
              onStart={() => currentPlayer?.seekTo(progress, 'seconds')}
              width="100%"
              height="100%"
            />
          </StyledDialogContentText>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

const CloseButton = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

const StyledDialog = styled(Dialog)`
  &&& {
    .MuiDialog-paper {
      border: 0;
      ${Mobile} {
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        max-width: 100vh;
        margin: 0;
      }
    }

    .MuiDialogContent-root {
      padding: 0;

      ${Mobile} {
        display: flex;
        align-items: center;
        background: black;
        width: 100vw;
        overflow: hidden;
      }
    }

    .MuiDialogTitle-root {
      margin-bottom: 19px;

      .MuiTypography-root {
        font: bold 20px/24px CentraleSans;
        color: ${ColorDLS.Gray75};
      }
    }
  }
`;

const StyledDialogContentText = styled.div`
  color: ${ColorDLS.Gray75};
`;
