import { RequireAuth } from '@philips/iam-service';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthenticatedContainer } from './authenticated-container';
import { StaticPage } from './static-page/static-page';

export const MainRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/terms-and-conditions">
        <StaticPage endpoint="terms-and-conditions" />
      </Route>
      <Route path="/privacy-policy">
        <StaticPage endpoint="privacy-policy" />
      </Route>
      <Route path="/about">
        <StaticPage endpoint="about" />
      </Route>
      <RequireAuth>
        <AuthenticatedContainer />
      </RequireAuth>
    </Switch>
  );
};
