import { LanguageCode } from '../i18n/language-code';

import { setEddlContext } from './eddlContext';
import { COOKIE_NOTICE_SCRIPT_URLPREFIX } from './ga360Constants';

interface PhilipsCookieNoticeHandler {
  cookiebar: {
    openConsentManager: () => void;
  };
}

declare global {
  interface Window {
    philips: PhilipsCookieNoticeHandler;
  }
}

const cookieNoticeScriptUrlForLangCode = (langCode: LanguageCode) => {
  let domain = 'philips.co.uk';
  let language = 'en_GB';

  if (langCode === LanguageCode.German) {
    domain = 'philips.de';
    language = 'de_DE';
  }

  return `${COOKIE_NOTICE_SCRIPT_URLPREFIX}?domain=${domain}&c=teconsent&text=true&language=${language}&gtm=1&behavior=expressed`;
};

const removeAllCookieNoticeScripts = () => {
  const scriptsCollection = document.getElementsByTagName('script');
  const scripts = Array.from(scriptsCollection);
  const cookieNoticeScripts = scripts.filter((s) => s.src.includes(COOKIE_NOTICE_SCRIPT_URLPREFIX));
  cookieNoticeScripts.forEach((s) => s.parentNode?.removeChild(s));
};

const addCookieNoticeScript = (langCode: LanguageCode) => {
  const newCookieNoticeScript = document.createElement('script');
  newCookieNoticeScript.type = 'text/javascript';
  newCookieNoticeScript.src = cookieNoticeScriptUrlForLangCode(langCode);
  newCookieNoticeScript.defer = false;

  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag?.parentNode?.appendChild(newCookieNoticeScript);
};

const reloadCookieNoticeScript = (langCode: LanguageCode) => {
  removeAllCookieNoticeScripts();
  addCookieNoticeScript(langCode);
};

const reloadGAScripts = (langCode: LanguageCode) => {
  setEddlContext(langCode);
  reloadCookieNoticeScript(langCode);
};

export { reloadGAScripts };
