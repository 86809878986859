import { LanguageCode } from '../i18n/language-code';

import { eddlLocaleForLanguageCode } from './eddlLocaleForLanguageCode';
import type { EddlLocale } from './eddlLocaleForLanguageCode';

type EddlContext = {
  locale: EddlLocale;
  singlePageApplication: boolean;
};

declare global {
  interface Window {
    eddlContext: EddlContext;
  }
}

const eddlContextForLangCode = (langCode: LanguageCode) => {
  return {
    locale: eddlLocaleForLanguageCode(langCode),
    singlePageApplication: true,
  };
};

const setEddlContext = (langCode: LanguageCode) => {
  window.eddlContext = eddlContextForLangCode(langCode);
};

export { setEddlContext };
