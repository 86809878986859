import React from 'react';
import { Radar } from 'react-chartjs-2';

import { ColorDLS } from '../utils/styling/colors';

export type RadarChartDataset = {
  label: string;
  value: number | null;
  disabled?: boolean;
};

export const RadarChart: React.FC<{ dataset: RadarChartDataset[] }> = ({ dataset }) => {
  const data = {
    labels: dataset.flatMap((d) => d.label),
    datasets: [
      {
        backgroundColor: 'rgba(191,226,235,0.5)',
        borderColor: ColorDLS.Blue45,
        borderWidth: 1,
        data: dataset.flatMap((d) => d.value),
      },
    ],
  };

  return (
    <Radar
      data={data}
      width={343}
      height={238}
      options={{
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        scale: {
          angleLines: {
            color: ColorDLS.Gray10,
          },
          gridLines: {
            color: ColorDLS.Gray10,
          },
          pointLabels: {
            callback: (pointLabel) => {
              if (pointLabel === 'CT') {
                return '                       CT'; // Use this to center the graph for now
              }

              return pointLabel;
            },
          },
          ticks: {
            display: false,
            suggestedMin: 0,
            suggestedMax: 100,
            stepSize: 33.4,
          },
        },
        plugins: {
          tooltips: false,
          crosshair: false,
        },
      }}
    />
  );
};
