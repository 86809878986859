import React from 'react';
import styled from 'styled-components';

import { DLSIconArrowDown } from './icons/dls-icon-arrow-down';
import { DLSIconCalendar } from './icons/dls-icon-calendar';
import { DLSIconCheckmark } from './icons/dls-icon-checkmark';
import { DLSIconConversationSpeech } from './icons/dls-icon-conversation-speech';
import { DLSIconCross } from './icons/dls-icon-cross';
import { DLSIconDocumentPDF } from './icons/dls-icon-document-pdf';
import { DLSIconDownload } from './icons/dls-icon-download';
import { DLSIconEducation } from './icons/dls-icon-education';
import { DLSIconExport } from './icons/dls-icon-export';
import { DLSIconIdea } from './icons/dls-icon-idea';
import { DLSIconList } from './icons/dls-icon-list';
import { DLSIconLocation } from './icons/dls-icon-location';
import { DLSIconNavigationLeft } from './icons/dls-icon-navigation-left';
import { DLSIconNavigationRight } from './icons/dls-icon-navigation-right';
import { DLSIconOperating } from './icons/dls-icon-operating';
import { DLSIconPersonPortrait } from './icons/dls-icon-person-portrait';
import { DLSIconPlay } from './icons/dls-icon-play';
import { DLSIconQuestionMarkCircle } from './icons/dls-icon-question-mark-circle';
import { DLSIconStudy } from './icons/dls-icon-study';
import { DLSIconTag } from './icons/dls-icon-tag';
import { DLSIconThreeDotsVertical } from './icons/dls-icon-three-dots-vertical';
import { DLSIconXrayCustom } from './icons/dls-icon-x-ray-custom';

export type IconName =
  | 'ConversationSpeech'
  | 'Idea'
  | 'List'
  | 'Operating'
  | 'PersonPortrait'
  | 'Study'
  | 'Xray'
  | 'NavigationLeft'
  | 'NavigationRight'
  | 'Calendar'
  | 'Location'
  | 'Education'
  | 'Export'
  | 'DocumentPDF'
  | 'QuestionMarkCircle'
  | 'Checkmark'
  | 'ThreeDotsVertical'
  | 'Play'
  | 'Cross'
  | 'Tag'
  | 'ArrowDown'
  | 'Download';

type Color = React.CSSProperties['fill'];

const ICON_MAPPING: Record<IconName, React.ComponentType<{ fill: Color }>> = {
  ConversationSpeech: DLSIconConversationSpeech,
  Idea: DLSIconIdea,
  List: DLSIconList,
  Operating: DLSIconOperating,
  PersonPortrait: DLSIconPersonPortrait,
  Study: DLSIconStudy,
  Xray: DLSIconXrayCustom,
  NavigationLeft: DLSIconNavigationLeft,
  NavigationRight: DLSIconNavigationRight,
  Calendar: DLSIconCalendar,
  Location: DLSIconLocation,
  Education: DLSIconEducation,
  Export: DLSIconExport,
  DocumentPDF: DLSIconDocumentPDF,
  QuestionMarkCircle: DLSIconQuestionMarkCircle,
  Checkmark: DLSIconCheckmark,
  ThreeDotsVertical: DLSIconThreeDotsVertical,
  Play: DLSIconPlay,
  Cross: DLSIconCross,
  ArrowDown: DLSIconArrowDown,
  Tag: DLSIconTag,
  Download: DLSIconDownload,
};

export const DLSIcon: React.FC<{ name: IconName; size?: number; color?: Color; className?: string }> = ({
  name,
  size = 48,
  color = 'currentColor',
  className,
}) => {
  const IconComponent = ICON_MAPPING[name];
  if (!IconComponent) {
    return null;
  }

  return (
    <IconContainer className={className} style={{ width: size, height: size }}>
      <IconComponent fill={color} />
    </IconContainer>
  );
};

const IconContainer = styled.div``;
