import { format, formatDistanceToNow } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import React from 'react';

import { LanguageCode } from './language-code';
import { useTranslation } from './translation-provider';

const localeMapping = {
  [LanguageCode.English]: enLocale,
  [LanguageCode.German]: deLocale,
};

export const useLocalizedDateFns = () => {
  const [, i18n] = useTranslation();

  return React.useMemo(() => {
    const locale = localeMapping[i18n.language as LanguageCode];

    return {
      format(date: Date, formatStr = 'PP') {
        return format(date, formatStr, {
          locale,
        });
      },
      formatDistanceToNow(
        date: Date,
        options: {
          includeSeconds?: boolean;
          addSuffix?: boolean;
        } = {},
      ) {
        return formatDistanceToNow(date, {
          ...options,
          locale,
        });
      },
    };
  }, [i18n.language]);
};
