import { Button } from '@dls/react-labs';
import { Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';

import { ColorDLS, ColorGrey } from '../../utils/styling/colors';
import { Mobile } from '../../utils/styling/media-queries';

export const SkillsPageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0 20px 0;

  ${Mobile} {
    height: 100%;
    margin-top: 0;
  }

  z-index: 0;
`;

export const SkillsBackButton = styled(Button)`
  ${Mobile} {
    && {
      display: none;
    }
  }
`;

export const StyledCard = styled(Card)`
  &&.MuiCard-root {
    ${Mobile} {
      width: 100%;
    }

    border-radius: 2px;
    width: 648px;

    .MuiCardContent-root {
      padding: 24px 24px 0 24px;
      ${Mobile} {
        padding: 16px;
      }
    }
  }
`;

export const StyledCardMedia = styled(CardMedia)`
  width: 100%;
  height: 248px;

  ${Mobile} {
    height: 192px;
  }
`;

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;

  ${Mobile} {
    height: 100%;
  }
`;

export const StyledCardActions = styled(CardActions)`
  && {
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
    justify-content: space-between;

    ${Mobile} {
      justify-content: center;
    }
  }
`;

export const CardTitle = styled.div`
  font: 20px/24px CentraleSans;
  color: ${ColorDLS.Gray75};
  line-height: 28px;
`;

export const CardText = styled.div`
  color: ${ColorDLS.Gray75};
  line-height: 22px;
  opacity: 0.8;
`;

export const TakeQuizButton = styled(Button)`
  width: 168px;
  display: flex;
  justify-content: center;

  ${Mobile} {
    width: 100%;
  }
`;

export const EmptyCoursesBlock = styled.div`
  background-color: ${ColorDLS.Blue05};
  padding: 16px 22px;
`;

export const EmptyCourse = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const RecommendedCoursesTitle = styled.div`
  font: 14px/18px CentraleSans;
  color: ${ColorDLS.Blue45};
  line-height: 20px;
  opacity: 0.8;
  margin-bottom: 8px;
`;

export const Courses = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${Mobile} {
    flex-direction: column;
  }
`;

export const CourseImage = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background-color: ${ColorDLS.Blue10};
  margin-right: 16px;
`;

export const CourseContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CourseContentLine = styled.div<{ width: number }>`
  height: 8px;
  width: ${(props) => props.width}px;
  border-radius: 8px;
  background-color: ${ColorDLS.Blue15};

  &:first-child {
    margin-bottom: 8px;
  }
`;

export const QuoteBox = styled(EmptyCoursesBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px 0 24px;
  margin-bottom: 24px;
`;

export const QuoteText = styled.div`
  font: 20px/24px CentraleSans;
  color: ${ColorDLS.Blue45};
  line-height: 28px;
  text-align: center;
  font-style: italic;
  width: 75%;
`;

export const ChartWrapper = styled.div`
  margin-bottom: 24px;
`;

export const CourseThumbnail = styled(Avatar)`
  &.MuiAvatar-root {
    &&& {
      margin: 16px 24px 16px 8px;
      width: 96px;
      height: 96px;
      border-radius: 0;
    }
  }
`;

export const CoureListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font: bold 16px/22px CentraleSans;
    color: ${ColorDLS.Gray75} !important;
  }

  .MuiListItemText-secondary {
    &.MuiTypography-body2 {
      font: 14px/20px CentraleSans;
      color: ${ColorGrey.Shade51};
    }
  }
`;
