import { eddlLocaleForLanguageCode } from './eddlLocaleForLanguageCode';
import { EddlPageViewActionInteractionGoal } from './eddlTypes';
import type { GA360PageDescription, EddlPageViewAction } from './eddlTypes';

const trackPage = (pageDescription: GA360PageDescription) => {
  const { langCode, description, url } = pageDescription;

  const eddlAction: EddlPageViewAction = {
    trigger: 'event.icarus.navigation',
    event: 'pageEvent',
    interaction: {
      goal: EddlPageViewActionInteractionGoal.VirtualPageView,
      description,
      url,
      interactive: true,
    },
  };

  if (langCode) {
    eddlAction.locale = eddlLocaleForLanguageCode(langCode);
  }

  window.eddlEvents = window.eddlEvents || [];
  window.eddlEvents.push(eddlAction);
};

export { trackPage };
