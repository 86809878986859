import React from 'react';

export const DLSIconStudy: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-836 -189)">
          <g transform="translate(316 128)">
            <g transform="translate(440)">
              <g transform="translate(80 61)">
                <path d="M0 0H48V48H0z" />
                <path
                  fill={fill}
                  fillRule="nonzero"
                  d="M24.2 28.8l13.4-6.2v12.2c0 3.2-6.2 6-13.6 6-7.4 0-13.6-2.8-13.6-6V22.6l13.4 6.2h.4zM46 17.6L24 7.4 2 17.6l22 10.2 22-10.2zM42 32h2V22h-2v10z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
