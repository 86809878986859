import { LanguageCode } from '../i18n/language-code';

enum EddlLocaleCountry {
  GB = 'GB',
  DE = 'DE',
}

enum EddlLocaleCurrency {
  EUR = 'EUR',
  GBP = 'GBP',
}

enum EddlLocaleCode {
  'en_GB' = 'en_GB',
  'de_DE' = 'de_DE',
}

type EddlLocale = {
  country: EddlLocaleCountry;
  language: LanguageCode;
  currency: EddlLocaleCurrency;
  code: EddlLocaleCode;
};

const eddlLocaleForLanguageCode = (langCode: LanguageCode) => {
  switch (langCode) {
    case LanguageCode.English:
      return {
        country: EddlLocaleCountry.GB, // ISO 3166-1 alpha-2 country code
        language: langCode, // ISO 639-1 language code
        currency: EddlLocaleCurrency.EUR, // ISO 4217 curreccy code
        code: EddlLocaleCode.en_GB, // language in lower case + country in upper case
      };
    case LanguageCode.German:
      return {
        country: EddlLocaleCountry.DE,
        language: langCode,
        currency: EddlLocaleCurrency.EUR,
        code: EddlLocaleCode.de_DE,
      };
    default:
      throw new Error(`Unsupported LanguageCode: ${langCode}`);
  }
};

export type { EddlLocale };
export { eddlLocaleForLanguageCode };
