import { EddlMicrositeEventType } from './eddlTypes';
import type { GA360MicrositeEvent, EddlMicrositeEvent } from './eddlTypes';

const emitMicrositeEvent = (event: GA360MicrositeEvent) => {
  const {
    micrositeName,
    action,
    goal,
    interactive,
    componentType,
    micrositeID,
    description,
    status,
    url,
    value,
    interactionID,
  } = event;

  const eddlAction: EddlMicrositeEvent = {
    trigger: `event.${micrositeName}.${action}`,
    event: EddlMicrositeEventType.CustomEvent,
    interaction: {
      goal,
      interactive,
    },
    component: {
      name: micrositeName,
      type: componentType,
      id: micrositeID,
    },
  };
  if (description) {
    eddlAction.interaction.description = description;
  }
  if (status) {
    eddlAction.interaction.status = status;
  }
  if (url) {
    eddlAction.interaction.url = url;
  }
  if (value) {
    eddlAction.interaction.value = value;
  }
  if (interactionID) {
    eddlAction.interaction.id = interactionID;
  }

  window.eddlEvents = window.eddlEvents || [];
  window.eddlEvents.push(eddlAction);
};

export { emitMicrositeEvent };
