import React from 'react';

import { VideoCard } from '../../../components/video-card';
import { VideoItem } from '../../../components/video-player';
import { FullVideo } from '../../../entities/backend/full-video';

import { AssetComponentProps } from './asset-list-types';

export const AssetComponentVideo: React.FC<AssetComponentProps> = ({ asset, handlePlayVideo }) => {
  const video = asset as FullVideo;
  const videoItem: VideoItem = {
    id: video.sys.id,
    title: video.name,
    coverImage: video.thumbnail?.url || '',
    lengthSecond: video.length || 0,
    videoUrl: video.video?.url || '',
  };

  return (
    <VideoCard
      onPlayVideo={() => handlePlayVideo(videoItem)}
      key={video.sys.id}
      title={video.name}
      coverImage={videoItem.coverImage}
      hasBeenWatchedFully={video.hasBeenWatchedFully}
      blockedInPreview={!video.video}
      progress={video.progress}
      lengthSecond={videoItem.lengthSecond}
    />
  );
};
