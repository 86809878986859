/* istanbul ignore file */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Dimension: any;
  HexColor: any;
  JSON: any;
  Quality: any;
};

export type Asset = {
  __typename?: 'Asset';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
};


export type AssetTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type AssetDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type AssetContentTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type AssetFileNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type AssetSizeArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type AssetUrlArgs = {
  transform?: Maybe<ImageTransformOptions>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetWidthArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type AssetHeightArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Asset>>;
};

export type AssetFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  url_exists?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  size_exists?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Int']>;
  size_not?: Maybe<Scalars['Int']>;
  size_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_gt?: Maybe<Scalars['Int']>;
  size_gte?: Maybe<Scalars['Int']>;
  size_lt?: Maybe<Scalars['Int']>;
  size_lte?: Maybe<Scalars['Int']>;
  contentType_exists?: Maybe<Scalars['Boolean']>;
  contentType?: Maybe<Scalars['String']>;
  contentType_not?: Maybe<Scalars['String']>;
  contentType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_contains?: Maybe<Scalars['String']>;
  contentType_not_contains?: Maybe<Scalars['String']>;
  fileName_exists?: Maybe<Scalars['Boolean']>;
  fileName?: Maybe<Scalars['String']>;
  fileName_not?: Maybe<Scalars['String']>;
  fileName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_contains?: Maybe<Scalars['String']>;
  fileName_not_contains?: Maybe<Scalars['String']>;
  width_exists?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  width_not?: Maybe<Scalars['Int']>;
  width_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_gt?: Maybe<Scalars['Int']>;
  width_gte?: Maybe<Scalars['Int']>;
  width_lt?: Maybe<Scalars['Int']>;
  width_lte?: Maybe<Scalars['Int']>;
  height_exists?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  height_not?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_gt?: Maybe<Scalars['Int']>;
  height_gte?: Maybe<Scalars['Int']>;
  height_lt?: Maybe<Scalars['Int']>;
  height_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<AssetFilter>>>;
  AND?: Maybe<Array<Maybe<AssetFilter>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  courseCollection?: Maybe<CourseCollection>;
  staticPageCollection?: Maybe<StaticPageCollection>;
  pdfCollection?: Maybe<PdfCollection>;
  videoCollection?: Maybe<VideoCollection>;
  developmentsCollection?: Maybe<DevelopmentsCollection>;
  newsItemsCollection?: Maybe<NewsItemsCollection>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsCourseCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsStaticPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsPdfCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsDevelopmentsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsNewsItemsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum AssetOrder {
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags_exists?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<ContentfulMetadataTagsFilter>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Course = Entry & {
  __typename?: 'Course';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<CourseLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  description?: Maybe<CourseDescription>;
  price?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  examsCollection?: Maybe<CourseExamsCollection>;
  location?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  videoCollection?: Maybe<CourseVideoCollection>;
  pdfFileCollection?: Maybe<CoursePdfFileCollection>;
  thumbnail?: Maybe<Asset>;
  dateStart?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  sortIndex?: Maybe<Scalars['Int']>;
  flyer?: Maybe<Asset>;
  interestArea?: Maybe<Entry>;
  thresholdScoreSkillsQuiz?: Maybe<Scalars['Int']>;
  isShown?: Maybe<Scalars['Boolean']>;
  showDate?: Maybe<Scalars['Boolean']>;
  showLocation?: Maybe<Scalars['Boolean']>;
  externalCourseUrl?: Maybe<Scalars['String']>;
};


export type CourseLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type CourseTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseIdentifierArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CoursePriceArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseLevelArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseExamsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type CourseLocationArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type CoursePdfFileCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type CourseThumbnailArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type CourseDateStartArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseDateEndArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseSortIndexArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseFlyerArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type CourseInterestAreaArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type CourseThresholdScoreSkillsQuizArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseIsShownArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseShowDateArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseShowLocationArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CourseExternalCourseUrlArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type CourseCollection = {
  __typename?: 'CourseCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Course>>;
};

export type CourseDescription = {
  __typename?: 'CourseDescription';
  json: Scalars['JSON'];
  links: CourseDescriptionLinks;
};

export type CourseDescriptionAssets = {
  __typename?: 'CourseDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type CourseDescriptionEntries = {
  __typename?: 'CourseDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type CourseDescriptionLinks = {
  __typename?: 'CourseDescriptionLinks';
  entries: CourseDescriptionEntries;
  assets: CourseDescriptionAssets;
};

export type CourseExamsCollection = {
  __typename?: 'CourseExamsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type CourseFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  identifier_exists?: Maybe<Scalars['Boolean']>;
  identifier?: Maybe<Scalars['String']>;
  identifier_not?: Maybe<Scalars['String']>;
  identifier_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  identifier_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  identifier_contains?: Maybe<Scalars['String']>;
  identifier_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  price_exists?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['String']>;
  price_not?: Maybe<Scalars['String']>;
  price_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  price_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  price_contains?: Maybe<Scalars['String']>;
  price_not_contains?: Maybe<Scalars['String']>;
  level_exists?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  level_not?: Maybe<Scalars['String']>;
  level_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  level_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  level_contains?: Maybe<Scalars['String']>;
  level_not_contains?: Maybe<Scalars['String']>;
  examsCollection_exists?: Maybe<Scalars['Boolean']>;
  Location_exists?: Maybe<Scalars['Boolean']>;
  Location?: Maybe<Scalars['String']>;
  Location_not?: Maybe<Scalars['String']>;
  Location_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  Location_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  Location_contains?: Maybe<Scalars['String']>;
  Location_not_contains?: Maybe<Scalars['String']>;
  type_exists?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  videoCollection_exists?: Maybe<Scalars['Boolean']>;
  pdfFileCollection_exists?: Maybe<Scalars['Boolean']>;
  thumbnail_exists?: Maybe<Scalars['Boolean']>;
  dateStart_exists?: Maybe<Scalars['Boolean']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  dateStart_not?: Maybe<Scalars['DateTime']>;
  dateStart_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  dateStart_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  dateStart_gt?: Maybe<Scalars['DateTime']>;
  dateStart_gte?: Maybe<Scalars['DateTime']>;
  dateStart_lt?: Maybe<Scalars['DateTime']>;
  dateStart_lte?: Maybe<Scalars['DateTime']>;
  dateEnd_exists?: Maybe<Scalars['Boolean']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  dateEnd_not?: Maybe<Scalars['DateTime']>;
  dateEnd_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  dateEnd_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  dateEnd_gt?: Maybe<Scalars['DateTime']>;
  dateEnd_gte?: Maybe<Scalars['DateTime']>;
  dateEnd_lt?: Maybe<Scalars['DateTime']>;
  dateEnd_lte?: Maybe<Scalars['DateTime']>;
  sortIndex_exists?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortIndex_not?: Maybe<Scalars['Int']>;
  sortIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_gt?: Maybe<Scalars['Int']>;
  sortIndex_gte?: Maybe<Scalars['Int']>;
  sortIndex_lt?: Maybe<Scalars['Int']>;
  sortIndex_lte?: Maybe<Scalars['Int']>;
  flyer_exists?: Maybe<Scalars['Boolean']>;
  interestArea_exists?: Maybe<Scalars['Boolean']>;
  thresholdScoreSkillsQuiz_exists?: Maybe<Scalars['Boolean']>;
  thresholdScoreSkillsQuiz?: Maybe<Scalars['Int']>;
  thresholdScoreSkillsQuiz_not?: Maybe<Scalars['Int']>;
  thresholdScoreSkillsQuiz_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  thresholdScoreSkillsQuiz_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  thresholdScoreSkillsQuiz_gt?: Maybe<Scalars['Int']>;
  thresholdScoreSkillsQuiz_gte?: Maybe<Scalars['Int']>;
  thresholdScoreSkillsQuiz_lt?: Maybe<Scalars['Int']>;
  thresholdScoreSkillsQuiz_lte?: Maybe<Scalars['Int']>;
  isShown_exists?: Maybe<Scalars['Boolean']>;
  isShown?: Maybe<Scalars['Boolean']>;
  isShown_not?: Maybe<Scalars['Boolean']>;
  showDate_exists?: Maybe<Scalars['Boolean']>;
  showDate?: Maybe<Scalars['Boolean']>;
  showDate_not?: Maybe<Scalars['Boolean']>;
  showLocation_exists?: Maybe<Scalars['Boolean']>;
  showLocation?: Maybe<Scalars['Boolean']>;
  showLocation_not?: Maybe<Scalars['Boolean']>;
  externalCourseUrl_exists?: Maybe<Scalars['Boolean']>;
  externalCourseUrl?: Maybe<Scalars['String']>;
  externalCourseUrl_not?: Maybe<Scalars['String']>;
  externalCourseUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalCourseUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalCourseUrl_contains?: Maybe<Scalars['String']>;
  externalCourseUrl_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<CourseFilter>>>;
  AND?: Maybe<Array<Maybe<CourseFilter>>>;
};

export type CourseLinkingCollections = {
  __typename?: 'CourseLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type CourseLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum CourseOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  LevelAsc = 'level_ASC',
  LevelDesc = 'level_DESC',
  LocationAsc = 'Location_ASC',
  LocationDesc = 'Location_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  DateStartAsc = 'dateStart_ASC',
  DateStartDesc = 'dateStart_DESC',
  DateEndAsc = 'dateEnd_ASC',
  DateEndDesc = 'dateEnd_DESC',
  SortIndexAsc = 'sortIndex_ASC',
  SortIndexDesc = 'sortIndex_DESC',
  ThresholdScoreSkillsQuizAsc = 'thresholdScoreSkillsQuiz_ASC',
  ThresholdScoreSkillsQuizDesc = 'thresholdScoreSkillsQuiz_DESC',
  IsShownAsc = 'isShown_ASC',
  IsShownDesc = 'isShown_DESC',
  ShowDateAsc = 'showDate_ASC',
  ShowDateDesc = 'showDate_DESC',
  ShowLocationAsc = 'showLocation_ASC',
  ShowLocationDesc = 'showLocation_DESC',
  ExternalCourseUrlAsc = 'externalCourseUrl_ASC',
  ExternalCourseUrlDesc = 'externalCourseUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type CoursePdfFileCollection = {
  __typename?: 'CoursePdfFileCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type CourseVideoCollection = {
  __typename?: 'CourseVideoCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};


export type Developments = Entry & {
  __typename?: 'Developments';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<DevelopmentsLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<DevelopmentsDescription>;
  thumbnail?: Maybe<Asset>;
  link?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Scalars['DateTime']>;
};


export type DevelopmentsLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type DevelopmentsTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type DevelopmentsDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type DevelopmentsThumbnailArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type DevelopmentsLinkArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type DevelopmentsSortIndexArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type DevelopmentsPostDateArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type DevelopmentsCollection = {
  __typename?: 'DevelopmentsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Developments>>;
};

export type DevelopmentsDescription = {
  __typename?: 'DevelopmentsDescription';
  json: Scalars['JSON'];
  links: DevelopmentsDescriptionLinks;
};

export type DevelopmentsDescriptionAssets = {
  __typename?: 'DevelopmentsDescriptionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type DevelopmentsDescriptionEntries = {
  __typename?: 'DevelopmentsDescriptionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type DevelopmentsDescriptionLinks = {
  __typename?: 'DevelopmentsDescriptionLinks';
  entries: DevelopmentsDescriptionEntries;
  assets: DevelopmentsDescriptionAssets;
};

export type DevelopmentsFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  thumbnail_exists?: Maybe<Scalars['Boolean']>;
  link_exists?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  link_not?: Maybe<Scalars['String']>;
  link_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  link_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  link_contains?: Maybe<Scalars['String']>;
  link_not_contains?: Maybe<Scalars['String']>;
  sortIndex_exists?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortIndex_not?: Maybe<Scalars['Int']>;
  sortIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_gt?: Maybe<Scalars['Int']>;
  sortIndex_gte?: Maybe<Scalars['Int']>;
  sortIndex_lt?: Maybe<Scalars['Int']>;
  sortIndex_lte?: Maybe<Scalars['Int']>;
  postDate_exists?: Maybe<Scalars['Boolean']>;
  postDate?: Maybe<Scalars['DateTime']>;
  postDate_not?: Maybe<Scalars['DateTime']>;
  postDate_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  postDate_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  postDate_gt?: Maybe<Scalars['DateTime']>;
  postDate_gte?: Maybe<Scalars['DateTime']>;
  postDate_lt?: Maybe<Scalars['DateTime']>;
  postDate_lte?: Maybe<Scalars['DateTime']>;
  OR?: Maybe<Array<Maybe<DevelopmentsFilter>>>;
  AND?: Maybe<Array<Maybe<DevelopmentsFilter>>>;
};

export type DevelopmentsLinkingCollections = {
  __typename?: 'DevelopmentsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type DevelopmentsLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum DevelopmentsOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SortIndexAsc = 'sortIndex_ASC',
  SortIndexDesc = 'sortIndex_DESC',
  PostDateAsc = 'postDate_ASC',
  PostDateDesc = 'postDate_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export type EmailTemplate = Entry & {
  __typename?: 'EmailTemplate';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<EmailTemplateLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  text?: Maybe<EmailTemplateText>;
};


export type EmailTemplateLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type EmailTemplateTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type EmailTemplateTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type EmailTemplateTextArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type EmailTemplateCollection = {
  __typename?: 'EmailTemplateCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<EmailTemplate>>;
};

export type EmailTemplateFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  type_exists?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  text_exists?: Maybe<Scalars['Boolean']>;
  text_contains?: Maybe<Scalars['String']>;
  text_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<EmailTemplateFilter>>>;
  AND?: Maybe<Array<Maybe<EmailTemplateFilter>>>;
};

export type EmailTemplateLinkingCollections = {
  __typename?: 'EmailTemplateLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type EmailTemplateLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum EmailTemplateOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type EmailTemplateText = {
  __typename?: 'EmailTemplateText';
  json: Scalars['JSON'];
  links: EmailTemplateTextLinks;
};

export type EmailTemplateTextAssets = {
  __typename?: 'EmailTemplateTextAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type EmailTemplateTextEntries = {
  __typename?: 'EmailTemplateTextEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type EmailTemplateTextLinks = {
  __typename?: 'EmailTemplateTextLinks';
  entries: EmailTemplateTextEntries;
  assets: EmailTemplateTextAssets;
};

export type Entry = {
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type EntryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  OR?: Maybe<Array<Maybe<EntryFilter>>>;
  AND?: Maybe<Array<Maybe<EntryFilter>>>;
};

export enum EntryOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Exams = Entry & {
  __typename?: 'Exams';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ExamsLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  examUrl?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
  blockedInPreview?: Maybe<Scalars['Boolean']>;
};


export type ExamsLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ExamsNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type ExamsExamUrlArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type ExamsSortIndexArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type ExamsBlockedInPreviewArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type ExamsCollection = {
  __typename?: 'ExamsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Exams>>;
};

export type ExamsFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  examUrl_exists?: Maybe<Scalars['Boolean']>;
  examUrl?: Maybe<Scalars['String']>;
  examUrl_not?: Maybe<Scalars['String']>;
  examUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  examUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  examUrl_contains?: Maybe<Scalars['String']>;
  examUrl_not_contains?: Maybe<Scalars['String']>;
  sortIndex_exists?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortIndex_not?: Maybe<Scalars['Int']>;
  sortIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_gt?: Maybe<Scalars['Int']>;
  sortIndex_gte?: Maybe<Scalars['Int']>;
  sortIndex_lt?: Maybe<Scalars['Int']>;
  sortIndex_lte?: Maybe<Scalars['Int']>;
  blockedInPreview_exists?: Maybe<Scalars['Boolean']>;
  blockedInPreview?: Maybe<Scalars['Boolean']>;
  blockedInPreview_not?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<ExamsFilter>>>;
  AND?: Maybe<Array<Maybe<ExamsFilter>>>;
};

export type ExamsLinkingCollections = {
  __typename?: 'ExamsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ExamsLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum ExamsOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortIndexAsc = 'sortIndex_ASC',
  SortIndexDesc = 'sortIndex_DESC',
  BlockedInPreviewAsc = 'blockedInPreview_ASC',
  BlockedInPreviewDesc = 'blockedInPreview_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export enum ImageFormat {
  Jpg = 'JPG',
  JpgProgressive = 'JPG_PROGRESSIVE',
  Png = 'PNG',
  Png8 = 'PNG8',
  Webp = 'WEBP',
  Avif = 'AVIF'
}

export enum ImageResizeFocus {
  Center = 'CENTER',
  Top = 'TOP',
  TopRight = 'TOP_RIGHT',
  Right = 'RIGHT',
  BottomRight = 'BOTTOM_RIGHT',
  Bottom = 'BOTTOM',
  BottomLeft = 'BOTTOM_LEFT',
  Left = 'LEFT',
  TopLeft = 'TOP_LEFT',
  Face = 'FACE',
  Faces = 'FACES'
}

export enum ImageResizeStrategy {
  Fit = 'FIT',
  Pad = 'PAD',
  Fill = 'FILL',
  Scale = 'SCALE',
  Crop = 'CROP',
  Thumb = 'THUMB'
}

export type ImageTransformOptions = {
  width?: Maybe<Scalars['Dimension']>;
  height?: Maybe<Scalars['Dimension']>;
  quality?: Maybe<Scalars['Quality']>;
  cornerRadius?: Maybe<Scalars['Int']>;
  resizeStrategy?: Maybe<ImageResizeStrategy>;
  resizeFocus?: Maybe<ImageResizeFocus>;
  backgroundColor?: Maybe<Scalars['HexColor']>;
  format?: Maybe<ImageFormat>;
};

export type InterestArea = Entry & {
  __typename?: 'InterestArea';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<InterestAreaLinkingCollections>;
  interestArea?: Maybe<Scalars['String']>;
  questionsCollection?: Maybe<InterestAreaQuestionsCollection>;
};


export type InterestAreaLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type InterestAreaInterestAreaArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type InterestAreaQuestionsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type InterestAreaCollection = {
  __typename?: 'InterestAreaCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<InterestArea>>;
};

export type InterestAreaFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  interestArea_exists?: Maybe<Scalars['Boolean']>;
  interestArea?: Maybe<Scalars['String']>;
  interestArea_not?: Maybe<Scalars['String']>;
  interestArea_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  interestArea_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  interestArea_contains?: Maybe<Scalars['String']>;
  interestArea_not_contains?: Maybe<Scalars['String']>;
  questionsCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<InterestAreaFilter>>>;
  AND?: Maybe<Array<Maybe<InterestAreaFilter>>>;
};

export type InterestAreaLinkingCollections = {
  __typename?: 'InterestAreaLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type InterestAreaLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum InterestAreaOrder {
  InterestAreaAsc = 'interestArea_ASC',
  InterestAreaDesc = 'interestArea_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type InterestAreaQuestionsCollection = {
  __typename?: 'InterestAreaQuestionsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};


export type NewsItems = Entry & {
  __typename?: 'NewsItems';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<NewsItemsLinkingCollections>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  summaryText?: Maybe<NewsItemsSummaryText>;
  sortIndex?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Asset>;
};


export type NewsItemsLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type NewsItemsLinkArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type NewsItemsTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type NewsItemsSummaryTextArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type NewsItemsSortIndexArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type NewsItemsThumbnailArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type NewsItemsCollection = {
  __typename?: 'NewsItemsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<NewsItems>>;
};

export type NewsItemsFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  link_exists?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  link_not?: Maybe<Scalars['String']>;
  link_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  link_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  link_contains?: Maybe<Scalars['String']>;
  link_not_contains?: Maybe<Scalars['String']>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  summaryText_exists?: Maybe<Scalars['Boolean']>;
  summaryText_contains?: Maybe<Scalars['String']>;
  summaryText_not_contains?: Maybe<Scalars['String']>;
  sortIndex_exists?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortIndex_not?: Maybe<Scalars['Int']>;
  sortIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_gt?: Maybe<Scalars['Int']>;
  sortIndex_gte?: Maybe<Scalars['Int']>;
  sortIndex_lt?: Maybe<Scalars['Int']>;
  sortIndex_lte?: Maybe<Scalars['Int']>;
  thumbnail_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<NewsItemsFilter>>>;
  AND?: Maybe<Array<Maybe<NewsItemsFilter>>>;
};

export type NewsItemsLinkingCollections = {
  __typename?: 'NewsItemsLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type NewsItemsLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum NewsItemsOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SortIndexAsc = 'sortIndex_ASC',
  SortIndexDesc = 'sortIndex_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type NewsItemsSummaryText = {
  __typename?: 'NewsItemsSummaryText';
  json: Scalars['JSON'];
  links: NewsItemsSummaryTextLinks;
};

export type NewsItemsSummaryTextAssets = {
  __typename?: 'NewsItemsSummaryTextAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type NewsItemsSummaryTextEntries = {
  __typename?: 'NewsItemsSummaryTextEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type NewsItemsSummaryTextLinks = {
  __typename?: 'NewsItemsSummaryTextLinks';
  entries: NewsItemsSummaryTextEntries;
  assets: NewsItemsSummaryTextAssets;
};

export type Pdf = Entry & {
  __typename?: 'Pdf';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<PdfLinkingCollections>;
  pdfTitle?: Maybe<Scalars['String']>;
  pdfFile?: Maybe<Asset>;
  sortIndex?: Maybe<Scalars['Int']>;
  blockedInPreview?: Maybe<Scalars['Boolean']>;
};


export type PdfLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PdfPdfTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type PdfPdfFileArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type PdfSortIndexArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type PdfBlockedInPreviewArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type PdfCollection = {
  __typename?: 'PdfCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Pdf>>;
};

export type PdfFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  pdfTitle_exists?: Maybe<Scalars['Boolean']>;
  pdfTitle?: Maybe<Scalars['String']>;
  pdfTitle_not?: Maybe<Scalars['String']>;
  pdfTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pdfTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pdfTitle_contains?: Maybe<Scalars['String']>;
  pdfTitle_not_contains?: Maybe<Scalars['String']>;
  pdfFile_exists?: Maybe<Scalars['Boolean']>;
  sortIndex_exists?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortIndex_not?: Maybe<Scalars['Int']>;
  sortIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_gt?: Maybe<Scalars['Int']>;
  sortIndex_gte?: Maybe<Scalars['Int']>;
  sortIndex_lt?: Maybe<Scalars['Int']>;
  sortIndex_lte?: Maybe<Scalars['Int']>;
  blockedInPreview_exists?: Maybe<Scalars['Boolean']>;
  blockedInPreview?: Maybe<Scalars['Boolean']>;
  blockedInPreview_not?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<PdfFilter>>>;
  AND?: Maybe<Array<Maybe<PdfFilter>>>;
};

export type PdfLinkingCollections = {
  __typename?: 'PdfLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type PdfLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum PdfOrder {
  PdfTitleAsc = 'pdfTitle_ASC',
  PdfTitleDesc = 'pdfTitle_DESC',
  SortIndexAsc = 'sortIndex_ASC',
  SortIndexDesc = 'sortIndex_DESC',
  BlockedInPreviewAsc = 'blockedInPreview_ASC',
  BlockedInPreviewDesc = 'blockedInPreview_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export type Query = {
  __typename?: 'Query';
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  course?: Maybe<Course>;
  courseCollection?: Maybe<CourseCollection>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplateCollection?: Maybe<EmailTemplateCollection>;
  staticPage?: Maybe<StaticPage>;
  staticPageCollection?: Maybe<StaticPageCollection>;
  exams?: Maybe<Exams>;
  examsCollection?: Maybe<ExamsCollection>;
  pdf?: Maybe<Pdf>;
  pdfCollection?: Maybe<PdfCollection>;
  video?: Maybe<Video>;
  videoCollection?: Maybe<VideoCollection>;
  skillsetAnalysis?: Maybe<SkillsetAnalysis>;
  skillsetAnalysisCollection?: Maybe<SkillsetAnalysisCollection>;
  skillQuestion?: Maybe<SkillQuestion>;
  skillQuestionCollection?: Maybe<SkillQuestionCollection>;
  skillAnswer?: Maybe<SkillAnswer>;
  skillAnswerCollection?: Maybe<SkillAnswerCollection>;
  interestArea?: Maybe<InterestArea>;
  interestAreaCollection?: Maybe<InterestAreaCollection>;
  developments?: Maybe<Developments>;
  developmentsCollection?: Maybe<DevelopmentsCollection>;
  newsItems?: Maybe<NewsItems>;
  newsItemsCollection?: Maybe<NewsItemsCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryAssetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<AssetFilter>;
  order?: Maybe<Array<Maybe<AssetOrder>>>;
};


export type QueryCourseArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryCourseCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<CourseFilter>;
  order?: Maybe<Array<Maybe<CourseOrder>>>;
};


export type QueryEmailTemplateArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryEmailTemplateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<EmailTemplateFilter>;
  order?: Maybe<Array<Maybe<EmailTemplateOrder>>>;
};


export type QueryStaticPageArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryStaticPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<StaticPageFilter>;
  order?: Maybe<Array<Maybe<StaticPageOrder>>>;
};


export type QueryExamsArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryExamsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<ExamsFilter>;
  order?: Maybe<Array<Maybe<ExamsOrder>>>;
};


export type QueryPdfArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryPdfCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<PdfFilter>;
  order?: Maybe<Array<Maybe<PdfOrder>>>;
};


export type QueryVideoArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<VideoFilter>;
  order?: Maybe<Array<Maybe<VideoOrder>>>;
};


export type QuerySkillsetAnalysisArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySkillsetAnalysisCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<SkillsetAnalysisFilter>;
  order?: Maybe<Array<Maybe<SkillsetAnalysisOrder>>>;
};


export type QuerySkillQuestionArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySkillQuestionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<SkillQuestionFilter>;
  order?: Maybe<Array<Maybe<SkillQuestionOrder>>>;
};


export type QuerySkillAnswerArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySkillAnswerCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<SkillAnswerFilter>;
  order?: Maybe<Array<Maybe<SkillAnswerOrder>>>;
};


export type QueryInterestAreaArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryInterestAreaCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<InterestAreaFilter>;
  order?: Maybe<Array<Maybe<InterestAreaOrder>>>;
};


export type QueryDevelopmentsArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryDevelopmentsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<DevelopmentsFilter>;
  order?: Maybe<Array<Maybe<DevelopmentsOrder>>>;
};


export type QueryNewsItemsArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryNewsItemsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<NewsItemsFilter>;
  order?: Maybe<Array<Maybe<NewsItemsOrder>>>;
};


export type QueryEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<EntryFilter>;
  order?: Maybe<Array<Maybe<EntryOrder>>>;
};

export type SkillAnswer = Entry & {
  __typename?: 'SkillAnswer';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<SkillAnswerLinkingCollections>;
  answer?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['String']>;
  sortIndex?: Maybe<Scalars['Int']>;
};


export type SkillAnswerLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SkillAnswerAnswerArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type SkillAnswerPointsArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type SkillAnswerSortIndexArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type SkillAnswerCollection = {
  __typename?: 'SkillAnswerCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<SkillAnswer>>;
};

export type SkillAnswerFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  answer_exists?: Maybe<Scalars['Boolean']>;
  answer?: Maybe<Scalars['String']>;
  answer_not?: Maybe<Scalars['String']>;
  answer_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  answer_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  answer_contains?: Maybe<Scalars['String']>;
  answer_not_contains?: Maybe<Scalars['String']>;
  points_exists?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['String']>;
  points_not?: Maybe<Scalars['String']>;
  points_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  points_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  points_contains?: Maybe<Scalars['String']>;
  points_not_contains?: Maybe<Scalars['String']>;
  sortIndex_exists?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortIndex_not?: Maybe<Scalars['Int']>;
  sortIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_gt?: Maybe<Scalars['Int']>;
  sortIndex_gte?: Maybe<Scalars['Int']>;
  sortIndex_lt?: Maybe<Scalars['Int']>;
  sortIndex_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<SkillAnswerFilter>>>;
  AND?: Maybe<Array<Maybe<SkillAnswerFilter>>>;
};

export type SkillAnswerLinkingCollections = {
  __typename?: 'SkillAnswerLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type SkillAnswerLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum SkillAnswerOrder {
  AnswerAsc = 'answer_ASC',
  AnswerDesc = 'answer_DESC',
  PointsAsc = 'points_ASC',
  PointsDesc = 'points_DESC',
  SortIndexAsc = 'sortIndex_ASC',
  SortIndexDesc = 'sortIndex_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type SkillQuestion = Entry & {
  __typename?: 'SkillQuestion';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<SkillQuestionLinkingCollections>;
  skillQuestion?: Maybe<Scalars['String']>;
  skillAnswersCollection?: Maybe<SkillQuestionSkillAnswersCollection>;
  sortIndex?: Maybe<Scalars['Int']>;
};


export type SkillQuestionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SkillQuestionSkillQuestionArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type SkillQuestionSkillAnswersCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type SkillQuestionSortIndexArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type SkillQuestionCollection = {
  __typename?: 'SkillQuestionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<SkillQuestion>>;
};

export type SkillQuestionFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  skillQuestion_exists?: Maybe<Scalars['Boolean']>;
  skillQuestion?: Maybe<Scalars['String']>;
  skillQuestion_not?: Maybe<Scalars['String']>;
  skillQuestion_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  skillQuestion_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  skillQuestion_contains?: Maybe<Scalars['String']>;
  skillQuestion_not_contains?: Maybe<Scalars['String']>;
  skillAnswersCollection_exists?: Maybe<Scalars['Boolean']>;
  sortIndex_exists?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortIndex_not?: Maybe<Scalars['Int']>;
  sortIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_gt?: Maybe<Scalars['Int']>;
  sortIndex_gte?: Maybe<Scalars['Int']>;
  sortIndex_lt?: Maybe<Scalars['Int']>;
  sortIndex_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<SkillQuestionFilter>>>;
  AND?: Maybe<Array<Maybe<SkillQuestionFilter>>>;
};

export type SkillQuestionLinkingCollections = {
  __typename?: 'SkillQuestionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type SkillQuestionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum SkillQuestionOrder {
  SkillQuestionAsc = 'skillQuestion_ASC',
  SkillQuestionDesc = 'skillQuestion_DESC',
  SortIndexAsc = 'sortIndex_ASC',
  SortIndexDesc = 'sortIndex_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type SkillQuestionSkillAnswersCollection = {
  __typename?: 'SkillQuestionSkillAnswersCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type SkillsetAnalysis = Entry & {
  __typename?: 'SkillsetAnalysis';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<SkillsetAnalysisLinkingCollections>;
  interestAreasCollection?: Maybe<SkillsetAnalysisInterestAreasCollection>;
  name?: Maybe<Scalars['String']>;
  introduction?: Maybe<SkillsetAnalysisIntroduction>;
};


export type SkillsetAnalysisLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type SkillsetAnalysisInterestAreasCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type SkillsetAnalysisNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type SkillsetAnalysisIntroductionArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type SkillsetAnalysisCollection = {
  __typename?: 'SkillsetAnalysisCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<SkillsetAnalysis>>;
};

export type SkillsetAnalysisFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  interestAreasCollection_exists?: Maybe<Scalars['Boolean']>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  introduction_exists?: Maybe<Scalars['Boolean']>;
  introduction_contains?: Maybe<Scalars['String']>;
  introduction_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<SkillsetAnalysisFilter>>>;
  AND?: Maybe<Array<Maybe<SkillsetAnalysisFilter>>>;
};

export type SkillsetAnalysisInterestAreasCollection = {
  __typename?: 'SkillsetAnalysisInterestAreasCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type SkillsetAnalysisIntroduction = {
  __typename?: 'SkillsetAnalysisIntroduction';
  json: Scalars['JSON'];
  links: SkillsetAnalysisIntroductionLinks;
};

export type SkillsetAnalysisIntroductionAssets = {
  __typename?: 'SkillsetAnalysisIntroductionAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type SkillsetAnalysisIntroductionEntries = {
  __typename?: 'SkillsetAnalysisIntroductionEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type SkillsetAnalysisIntroductionLinks = {
  __typename?: 'SkillsetAnalysisIntroductionLinks';
  entries: SkillsetAnalysisIntroductionEntries;
  assets: SkillsetAnalysisIntroductionAssets;
};

export type SkillsetAnalysisLinkingCollections = {
  __typename?: 'SkillsetAnalysisLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type SkillsetAnalysisLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum SkillsetAnalysisOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type StaticPage = Entry & {
  __typename?: 'StaticPage';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<StaticPageLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<StaticPageText>;
  pageName?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
};


export type StaticPageLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type StaticPageTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type StaticPageTextArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type StaticPagePageNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type StaticPageImageArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type StaticPageCollection = {
  __typename?: 'StaticPageCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<StaticPage>>;
};

export type StaticPageFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  text_exists?: Maybe<Scalars['Boolean']>;
  text_contains?: Maybe<Scalars['String']>;
  text_not_contains?: Maybe<Scalars['String']>;
  pageName_exists?: Maybe<Scalars['Boolean']>;
  pageName?: Maybe<Scalars['String']>;
  pageName_not?: Maybe<Scalars['String']>;
  pageName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageName_contains?: Maybe<Scalars['String']>;
  pageName_not_contains?: Maybe<Scalars['String']>;
  image_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<StaticPageFilter>>>;
  AND?: Maybe<Array<Maybe<StaticPageFilter>>>;
};

export type StaticPageLinkingCollections = {
  __typename?: 'StaticPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type StaticPageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum StaticPageOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  PageNameAsc = 'pageName_ASC',
  PageNameDesc = 'pageName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type StaticPageText = {
  __typename?: 'StaticPageText';
  json: Scalars['JSON'];
  links: StaticPageTextLinks;
};

export type StaticPageTextAssets = {
  __typename?: 'StaticPageTextAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type StaticPageTextEntries = {
  __typename?: 'StaticPageTextEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type StaticPageTextLinks = {
  __typename?: 'StaticPageTextLinks';
  entries: StaticPageTextEntries;
  assets: StaticPageTextAssets;
};

export type Sys = {
  __typename?: 'Sys';
  id: Scalars['String'];
  spaceId: Scalars['String'];
  environmentId: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
};

export type SysFilter = {
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  publishedAt_exists?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: Maybe<Scalars['Boolean']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_not?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_gt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: Maybe<Scalars['DateTime']>;
  publishedVersion_exists?: Maybe<Scalars['Boolean']>;
  publishedVersion?: Maybe<Scalars['Float']>;
  publishedVersion_not?: Maybe<Scalars['Float']>;
  publishedVersion_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_gt?: Maybe<Scalars['Float']>;
  publishedVersion_gte?: Maybe<Scalars['Float']>;
  publishedVersion_lt?: Maybe<Scalars['Float']>;
  publishedVersion_lte?: Maybe<Scalars['Float']>;
};

export type Video = Entry & {
  __typename?: 'Video';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<VideoLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  video?: Maybe<Asset>;
  thumbnail?: Maybe<Asset>;
  sortIndex?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  blockedInPreview?: Maybe<Scalars['Boolean']>;
};


export type VideoLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type VideoNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type VideoVideoArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type VideoThumbnailArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type VideoSortIndexArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type VideoLengthArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type VideoBlockedInPreviewArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type VideoCollection = {
  __typename?: 'VideoCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Video>>;
};

export type VideoFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  video_exists?: Maybe<Scalars['Boolean']>;
  thumbnail_exists?: Maybe<Scalars['Boolean']>;
  sortIndex_exists?: Maybe<Scalars['Boolean']>;
  sortIndex?: Maybe<Scalars['Int']>;
  sortIndex_not?: Maybe<Scalars['Int']>;
  sortIndex_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortIndex_gt?: Maybe<Scalars['Int']>;
  sortIndex_gte?: Maybe<Scalars['Int']>;
  sortIndex_lt?: Maybe<Scalars['Int']>;
  sortIndex_lte?: Maybe<Scalars['Int']>;
  length_exists?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Int']>;
  length_not?: Maybe<Scalars['Int']>;
  length_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  length_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  length_gt?: Maybe<Scalars['Int']>;
  length_gte?: Maybe<Scalars['Int']>;
  length_lt?: Maybe<Scalars['Int']>;
  length_lte?: Maybe<Scalars['Int']>;
  blockedInPreview_exists?: Maybe<Scalars['Boolean']>;
  blockedInPreview?: Maybe<Scalars['Boolean']>;
  blockedInPreview_not?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<VideoFilter>>>;
  AND?: Maybe<Array<Maybe<VideoFilter>>>;
};

export type VideoLinkingCollections = {
  __typename?: 'VideoLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type VideoLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum VideoOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortIndexAsc = 'sortIndex_ASC',
  SortIndexDesc = 'sortIndex_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  BlockedInPreviewAsc = 'blockedInPreview_ASC',
  BlockedInPreviewDesc = 'blockedInPreview_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type CourseCollectionQueryVariables = Exact<{
  locale: Scalars['String'];
}>;


export type CourseCollectionQuery = (
  { __typename?: 'Query' }
  & { courseCollection?: Maybe<(
    { __typename?: 'CourseCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Course' }
      & Pick<Course, 'identifier' | 'location' | 'dateEnd' | 'dateStart' | 'level' | 'title' | 'price' | 'sortIndex' | 'isShown' | 'showDate' | 'showLocation' | 'externalCourseUrl' | 'thresholdScoreSkillsQuiz'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ), description?: Maybe<(
        { __typename?: 'CourseDescription' }
        & Pick<CourseDescription, 'json'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'url'>
      )>, interestArea?: Maybe<(
        { __typename?: 'Course' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'Developments' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'EmailTemplate' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'Exams' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'InterestArea' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'NewsItems' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'Pdf' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'SkillAnswer' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'SkillQuestion' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'SkillsetAnalysis' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'StaticPage' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'Video' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      )> }
    )>> }
  )> }
);

export type CourseByIdQueryVariables = Exact<{
  courseId: Scalars['String'];
  locale: Scalars['String'];
}>;


export type CourseByIdQuery = (
  { __typename?: 'Query' }
  & { course?: Maybe<(
    { __typename?: 'Course' }
    & Pick<Course, 'identifier' | 'level' | 'location' | 'title' | 'price' | 'dateEnd' | 'dateStart' | 'thresholdScoreSkillsQuiz'>
    & { description?: Maybe<(
      { __typename?: 'CourseDescription' }
      & Pick<CourseDescription, 'json'>
    )>, examsCollection?: Maybe<(
      { __typename: 'CourseExamsCollection' }
      & { items: Array<Maybe<(
        { __typename?: 'Course' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'Developments' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'EmailTemplate' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'Exams' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'InterestArea' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'NewsItems' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'Pdf' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'SkillAnswer' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'SkillQuestion' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'SkillsetAnalysis' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'StaticPage' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename?: 'Video' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      )>> }
    )>, flyer?: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'url'>
    )>, thumbnail?: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'url'>
    )>, pdfFileCollection?: Maybe<(
      { __typename?: 'CoursePdfFileCollection' }
      & { items: Array<Maybe<(
        { __typename: 'Course' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'Developments' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'EmailTemplate' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'Exams' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'InterestArea' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'NewsItems' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'Pdf' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'SkillAnswer' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'SkillQuestion' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'SkillsetAnalysis' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'StaticPage' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'Video' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      )>> }
    )>, sys: (
      { __typename?: 'Sys' }
      & Pick<Sys, 'id'>
    ), interestArea?: Maybe<(
      { __typename?: 'Course' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Developments' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'EmailTemplate' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Exams' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'InterestArea' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'NewsItems' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Pdf' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SkillAnswer' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SkillQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SkillsetAnalysis' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StaticPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Video' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>, videoCollection?: Maybe<(
      { __typename?: 'CourseVideoCollection' }
      & { items: Array<Maybe<(
        { __typename: 'Course' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'Developments' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'EmailTemplate' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'Exams' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'InterestArea' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'NewsItems' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'Pdf' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'SkillAnswer' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'SkillQuestion' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'SkillsetAnalysis' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'StaticPage' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      ) | (
        { __typename: 'Video' }
        & { sys: (
          { __typename?: 'Sys' }
          & Pick<Sys, 'id'>
        ) }
      )>> }
    )> }
  )> }
);

export type AllDevelopmentsQueryVariables = Exact<{
  locale: Scalars['String'];
}>;


export type AllDevelopmentsQuery = (
  { __typename?: 'Query' }
  & { developmentsCollection?: Maybe<(
    { __typename?: 'DevelopmentsCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Developments' }
      & Pick<Developments, 'postDate' | 'sortIndex' | 'title' | 'link'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id' | 'firstPublishedAt' | 'publishedAt' | 'publishedVersion'>
      ), description?: Maybe<(
        { __typename?: 'DevelopmentsDescription' }
        & Pick<DevelopmentsDescription, 'json'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'url'>
      )> }
    )>> }
  )> }
);

export type ExamByIdQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
}>;


export type ExamByIdQuery = (
  { __typename?: 'Query' }
  & { exams?: Maybe<(
    { __typename?: 'Exams' }
    & Pick<Exams, 'sortIndex' | 'name'>
    & { sys: (
      { __typename?: 'Sys' }
      & Pick<Sys, 'id'>
    ) }
  )> }
);

export type NewsItemsCollectionQueryVariables = Exact<{
  locale: Scalars['String'];
}>;


export type NewsItemsCollectionQuery = (
  { __typename?: 'Query' }
  & { newsItemsCollection?: Maybe<(
    { __typename?: 'NewsItemsCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'NewsItems' }
      & Pick<NewsItems, 'sortIndex' | 'title' | 'link'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id' | 'firstPublishedAt'>
      ), thumbnail?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'title' | 'url'>
      )>, summaryText?: Maybe<(
        { __typename?: 'NewsItemsSummaryText' }
        & Pick<NewsItemsSummaryText, 'json'>
      )> }
    )>> }
  )> }
);

export type PdfByIdQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
}>;


export type PdfByIdQuery = (
  { __typename?: 'Query' }
  & { pdf?: Maybe<(
    { __typename?: 'Pdf' }
    & Pick<Pdf, 'pdfTitle' | 'sortIndex'>
    & { sys: (
      { __typename?: 'Sys' }
      & Pick<Sys, 'id'>
    ) }
  )> }
);

export type AllDataForQuizQueryVariables = Exact<{
  locale: Scalars['String'];
}>;


export type AllDataForQuizQuery = (
  { __typename?: 'Query' }
  & { interestAreaCollection?: Maybe<(
    { __typename?: 'InterestAreaCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'InterestArea' }
      & Pick<InterestArea, 'interestArea'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ), questionsCollection?: Maybe<(
        { __typename?: 'InterestAreaQuestionsCollection' }
        & { items: Array<Maybe<(
          { __typename?: 'Course' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Developments' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'EmailTemplate' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Exams' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'InterestArea' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'NewsItems' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Pdf' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillAnswer' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillQuestion' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillsetAnalysis' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'StaticPage' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Video' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>> }
      )> }
    )>> }
  )>, skillQuestionCollection?: Maybe<(
    { __typename?: 'SkillQuestionCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'SkillQuestion' }
      & Pick<SkillQuestion, 'skillQuestion' | 'sortIndex'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ), skillAnswersCollection?: Maybe<(
        { __typename?: 'SkillQuestionSkillAnswersCollection' }
        & { items: Array<Maybe<(
          { __typename?: 'Course' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Developments' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'EmailTemplate' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Exams' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'InterestArea' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'NewsItems' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Pdf' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillAnswer' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillQuestion' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillsetAnalysis' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'StaticPage' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Video' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>> }
      )> }
    )>> }
  )>, skillAnswerCollection?: Maybe<(
    { __typename?: 'SkillAnswerCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'SkillAnswer' }
      & Pick<SkillAnswer, 'answer' | 'sortIndex' | 'points'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>> }
  )>, skillsetAnalysisCollection?: Maybe<(
    { __typename?: 'SkillsetAnalysisCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'SkillsetAnalysis' }
      & Pick<SkillsetAnalysis, 'name'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ), interestAreasCollection?: Maybe<(
        { __typename?: 'SkillsetAnalysisInterestAreasCollection' }
        & { items: Array<Maybe<(
          { __typename?: 'Course' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Developments' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'EmailTemplate' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Exams' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'InterestArea' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'NewsItems' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Pdf' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillAnswer' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillQuestion' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SkillsetAnalysis' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'StaticPage' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Video' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>> }
      )> }
    )>> }
  )> }
);

export type AllStaticPagesQueryVariables = Exact<{
  locale: Scalars['String'];
}>;


export type AllStaticPagesQuery = (
  { __typename?: 'Query' }
  & { staticPageCollection?: Maybe<(
    { __typename?: 'StaticPageCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'StaticPage' }
      & Pick<StaticPage, 'pageName' | 'title'>
      & { text?: Maybe<(
        { __typename?: 'StaticPageText' }
        & Pick<StaticPageText, 'json'>
      )>, image?: Maybe<(
        { __typename?: 'Asset' }
        & Pick<Asset, 'url'>
      )> }
    )>> }
  )> }
);

export type VideoByIdQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
}>;


export type VideoByIdQuery = (
  { __typename?: 'Query' }
  & { video?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'name' | 'length' | 'sortIndex'>
    & { sys: (
      { __typename?: 'Sys' }
      & Pick<Sys, 'id'>
    ), thumbnail?: Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'url'>
    )> }
  )> }
);


export const CourseCollectionDocument = gql`
    query courseCollection($locale: String!) {
  courseCollection(locale: $locale) {
    items {
      sys {
        id
      }
      identifier
      description {
        json
      }
      thumbnail {
        url
      }
      location
      dateEnd
      dateStart
      level
      title
      price
      sortIndex
      isShown
      showDate
      showLocation
      externalCourseUrl
      interestArea {
        sys {
          id
        }
      }
      thresholdScoreSkillsQuiz
      sys {
        id
      }
    }
  }
}
    `;

/**
 * __useCourseCollectionQuery__
 *
 * To run a query within a React component, call `useCourseCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCollectionQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCourseCollectionQuery(baseOptions: Apollo.QueryHookOptions<CourseCollectionQuery, CourseCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseCollectionQuery, CourseCollectionQueryVariables>(CourseCollectionDocument, options);
      }
export function useCourseCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseCollectionQuery, CourseCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseCollectionQuery, CourseCollectionQueryVariables>(CourseCollectionDocument, options);
        }
export type CourseCollectionQueryHookResult = ReturnType<typeof useCourseCollectionQuery>;
export type CourseCollectionLazyQueryHookResult = ReturnType<typeof useCourseCollectionLazyQuery>;
export type CourseCollectionQueryResult = Apollo.QueryResult<CourseCollectionQuery, CourseCollectionQueryVariables>;
export const CourseByIdDocument = gql`
    query courseById($courseId: String!, $locale: String!) {
  course(id: $courseId, locale: $locale) {
    identifier
    description {
      json
    }
    examsCollection {
      items {
        sys {
          id
        }
      }
      __typename
    }
    flyer {
      url
    }
    level
    location
    thumbnail {
      url
    }
    title
    pdfFileCollection {
      items {
        sys {
          id
        }
        __typename
      }
    }
    price
    sys {
      id
    }
    dateEnd
    dateStart
    interestArea {
      sys {
        id
      }
    }
    thresholdScoreSkillsQuiz
    videoCollection {
      items {
        sys {
          id
        }
        __typename
      }
    }
  }
}
    `;

/**
 * __useCourseByIdQuery__
 *
 * To run a query within a React component, call `useCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseByIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCourseByIdQuery(baseOptions: Apollo.QueryHookOptions<CourseByIdQuery, CourseByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseByIdQuery, CourseByIdQueryVariables>(CourseByIdDocument, options);
      }
export function useCourseByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseByIdQuery, CourseByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseByIdQuery, CourseByIdQueryVariables>(CourseByIdDocument, options);
        }
export type CourseByIdQueryHookResult = ReturnType<typeof useCourseByIdQuery>;
export type CourseByIdLazyQueryHookResult = ReturnType<typeof useCourseByIdLazyQuery>;
export type CourseByIdQueryResult = Apollo.QueryResult<CourseByIdQuery, CourseByIdQueryVariables>;
export const AllDevelopmentsDocument = gql`
    query allDevelopments($locale: String!) {
  developmentsCollection(locale: $locale) {
    items {
      sys {
        id
        firstPublishedAt
        publishedAt
        publishedVersion
      }
      postDate
      sortIndex
      title
      description {
        json
      }
      thumbnail {
        url
      }
      link
    }
  }
}
    `;

/**
 * __useAllDevelopmentsQuery__
 *
 * To run a query within a React component, call `useAllDevelopmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDevelopmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDevelopmentsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAllDevelopmentsQuery(baseOptions: Apollo.QueryHookOptions<AllDevelopmentsQuery, AllDevelopmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDevelopmentsQuery, AllDevelopmentsQueryVariables>(AllDevelopmentsDocument, options);
      }
export function useAllDevelopmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDevelopmentsQuery, AllDevelopmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDevelopmentsQuery, AllDevelopmentsQueryVariables>(AllDevelopmentsDocument, options);
        }
export type AllDevelopmentsQueryHookResult = ReturnType<typeof useAllDevelopmentsQuery>;
export type AllDevelopmentsLazyQueryHookResult = ReturnType<typeof useAllDevelopmentsLazyQuery>;
export type AllDevelopmentsQueryResult = Apollo.QueryResult<AllDevelopmentsQuery, AllDevelopmentsQueryVariables>;
export const ExamByIdDocument = gql`
    query examById($id: String!, $locale: String!) {
  exams(id: $id, locale: $locale) {
    sys {
      id
    }
    sortIndex
    name
  }
}
    `;

/**
 * __useExamByIdQuery__
 *
 * To run a query within a React component, call `useExamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useExamByIdQuery(baseOptions: Apollo.QueryHookOptions<ExamByIdQuery, ExamByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamByIdQuery, ExamByIdQueryVariables>(ExamByIdDocument, options);
      }
export function useExamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamByIdQuery, ExamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamByIdQuery, ExamByIdQueryVariables>(ExamByIdDocument, options);
        }
export type ExamByIdQueryHookResult = ReturnType<typeof useExamByIdQuery>;
export type ExamByIdLazyQueryHookResult = ReturnType<typeof useExamByIdLazyQuery>;
export type ExamByIdQueryResult = Apollo.QueryResult<ExamByIdQuery, ExamByIdQueryVariables>;
export const NewsItemsCollectionDocument = gql`
    query newsItemsCollection($locale: String!) {
  newsItemsCollection(locale: $locale) {
    items {
      sys {
        id
        firstPublishedAt
      }
      sortIndex
      title
      link
      thumbnail {
        title
        url
      }
      summaryText {
        json
      }
    }
  }
}
    `;

/**
 * __useNewsItemsCollectionQuery__
 *
 * To run a query within a React component, call `useNewsItemsCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsItemsCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsItemsCollectionQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useNewsItemsCollectionQuery(baseOptions: Apollo.QueryHookOptions<NewsItemsCollectionQuery, NewsItemsCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsItemsCollectionQuery, NewsItemsCollectionQueryVariables>(NewsItemsCollectionDocument, options);
      }
export function useNewsItemsCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsItemsCollectionQuery, NewsItemsCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsItemsCollectionQuery, NewsItemsCollectionQueryVariables>(NewsItemsCollectionDocument, options);
        }
export type NewsItemsCollectionQueryHookResult = ReturnType<typeof useNewsItemsCollectionQuery>;
export type NewsItemsCollectionLazyQueryHookResult = ReturnType<typeof useNewsItemsCollectionLazyQuery>;
export type NewsItemsCollectionQueryResult = Apollo.QueryResult<NewsItemsCollectionQuery, NewsItemsCollectionQueryVariables>;
export const PdfByIdDocument = gql`
    query pdfById($id: String!, $locale: String!) {
  pdf(id: $id, locale: $locale) {
    sys {
      id
    }
    pdfTitle
    sortIndex
  }
}
    `;

/**
 * __usePdfByIdQuery__
 *
 * To run a query within a React component, call `usePdfByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function usePdfByIdQuery(baseOptions: Apollo.QueryHookOptions<PdfByIdQuery, PdfByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PdfByIdQuery, PdfByIdQueryVariables>(PdfByIdDocument, options);
      }
export function usePdfByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PdfByIdQuery, PdfByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PdfByIdQuery, PdfByIdQueryVariables>(PdfByIdDocument, options);
        }
export type PdfByIdQueryHookResult = ReturnType<typeof usePdfByIdQuery>;
export type PdfByIdLazyQueryHookResult = ReturnType<typeof usePdfByIdLazyQuery>;
export type PdfByIdQueryResult = Apollo.QueryResult<PdfByIdQuery, PdfByIdQueryVariables>;
export const AllDataForQuizDocument = gql`
    query allDataForQuiz($locale: String!) {
  interestAreaCollection(locale: $locale) {
    items {
      sys {
        id
      }
      interestArea
      questionsCollection {
        items {
          sys {
            id
          }
        }
      }
    }
  }
  skillQuestionCollection(locale: $locale) {
    items {
      sys {
        id
      }
      skillAnswersCollection {
        items {
          sys {
            id
          }
        }
      }
      skillQuestion
      sortIndex
    }
  }
  skillAnswerCollection(locale: $locale) {
    items {
      sys {
        id
      }
      answer
      sortIndex
      points
    }
  }
  skillsetAnalysisCollection(locale: $locale) {
    items {
      name
      sys {
        id
      }
      interestAreasCollection {
        items {
          sys {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllDataForQuizQuery__
 *
 * To run a query within a React component, call `useAllDataForQuizQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDataForQuizQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDataForQuizQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAllDataForQuizQuery(baseOptions: Apollo.QueryHookOptions<AllDataForQuizQuery, AllDataForQuizQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllDataForQuizQuery, AllDataForQuizQueryVariables>(AllDataForQuizDocument, options);
      }
export function useAllDataForQuizLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllDataForQuizQuery, AllDataForQuizQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllDataForQuizQuery, AllDataForQuizQueryVariables>(AllDataForQuizDocument, options);
        }
export type AllDataForQuizQueryHookResult = ReturnType<typeof useAllDataForQuizQuery>;
export type AllDataForQuizLazyQueryHookResult = ReturnType<typeof useAllDataForQuizLazyQuery>;
export type AllDataForQuizQueryResult = Apollo.QueryResult<AllDataForQuizQuery, AllDataForQuizQueryVariables>;
export const AllStaticPagesDocument = gql`
    query allStaticPages($locale: String!) {
  staticPageCollection(locale: $locale) {
    items {
      pageName
      text {
        json
      }
      title
      image(preview: false) {
        url
      }
    }
  }
}
    `;

/**
 * __useAllStaticPagesQuery__
 *
 * To run a query within a React component, call `useAllStaticPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStaticPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStaticPagesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAllStaticPagesQuery(baseOptions: Apollo.QueryHookOptions<AllStaticPagesQuery, AllStaticPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllStaticPagesQuery, AllStaticPagesQueryVariables>(AllStaticPagesDocument, options);
      }
export function useAllStaticPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllStaticPagesQuery, AllStaticPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllStaticPagesQuery, AllStaticPagesQueryVariables>(AllStaticPagesDocument, options);
        }
export type AllStaticPagesQueryHookResult = ReturnType<typeof useAllStaticPagesQuery>;
export type AllStaticPagesLazyQueryHookResult = ReturnType<typeof useAllStaticPagesLazyQuery>;
export type AllStaticPagesQueryResult = Apollo.QueryResult<AllStaticPagesQuery, AllStaticPagesQueryVariables>;
export const VideoByIdDocument = gql`
    query videoById($id: String!, $locale: String!) {
  video(id: $id, locale: $locale) {
    sys {
      id
    }
    name
    length
    sortIndex
    thumbnail {
      url
    }
  }
}
    `;

/**
 * __useVideoByIdQuery__
 *
 * To run a query within a React component, call `useVideoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useVideoByIdQuery(baseOptions: Apollo.QueryHookOptions<VideoByIdQuery, VideoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoByIdQuery, VideoByIdQueryVariables>(VideoByIdDocument, options);
      }
export function useVideoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoByIdQuery, VideoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoByIdQuery, VideoByIdQueryVariables>(VideoByIdDocument, options);
        }
export type VideoByIdQueryHookResult = ReturnType<typeof useVideoByIdQuery>;
export type VideoByIdLazyQueryHookResult = ReturnType<typeof useVideoByIdLazyQuery>;
export type VideoByIdQueryResult = Apollo.QueryResult<VideoByIdQuery, VideoByIdQueryVariables>;