export enum BookingState {
  Requested = 'requested',
  Subscribed = 'subscribed',
  Rejected = 'rejected',
  Revoked = 'revoked',
}

export enum FrontendBookingState {
  Expired = 'expired',
}

export type DerivedBookingState = FrontendBookingState | BookingState;
