import React from 'react';

export const DLSIconPersonPortrait: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-396 -189)">
          <g transform="translate(316 128)">
            <g transform="translate(80 61)">
              <path d="M0 0H48V48H0z" />
              <path
                fill={fill}
                fillRule="nonzero"
                d="M24 22c-5.52 0-10-4.48-10-10S18.48 2 24 2s10 4.48 10 10-4.48 10-10 10zm9.62.14A13.899 13.899 0 0124 26c-3.73 0-7.11-1.47-9.62-3.86C9.63 22.92 6 27.03 6 32v14h36V32c0-4.97-3.63-9.08-8.38-9.86z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
