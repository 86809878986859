import i18n, { TOptions, InterpolationOptions, i18n as i18nType } from 'i18next';
import React from 'react';
import { useTranslation as upstreamUseTranslation, initReactI18next, I18nextProvider } from 'react-i18next';

import { getDefaultLanguageCode, LanguageCode } from './language-code';
import { translationLocaleDe } from './locales/de';
import { translationLocaleEn } from './locales/en';
import { TranslationModel } from './translation-model';

type TranslationKey = keyof TranslationModel;
export type TranslateFunction = (
  keysToTranslate: TranslationKey | TranslationKey[],
  vars?: Record<string, unknown>,
  tOptions?: TOptions<InterpolationOptions>,
) => string;

i18n.use(initReactI18next).init({
  fallbackLng: getDefaultLanguageCode(),
  resources: {
    [LanguageCode.English]: { translation: translationLocaleEn },
    [LanguageCode.German]: { translation: translationLocaleDe },
  },
  lng: getDefaultLanguageCode(),
  debug: false,
  interpolation: { escapeValue: false },
});

export const TranslationProvider: React.FC = ({ children }) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export const useTranslation = (): [TranslateFunction, i18nType, boolean] => {
  return upstreamUseTranslation();
};
