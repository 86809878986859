import { Card, CardActionArea, CardActions, CardContent, CardMedia } from '@material-ui/core';
import React, { useContext } from 'react';
import Markdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { PageHeaderTitle } from '../../components/layout/page-header-title';
import { NotificationContext } from '../../components/notification/notification-context';
import { Spinner } from '../../components/spinner/spinner';
import { CourseCollectionEntry } from '../../entities/course-collection-entry';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { Course, useCourseCollectionQuery } from '../../generated/client';
import { useTranslation } from '../../i18n/translation-provider';
import { linkStyle } from '../../utils/styling/link';
import { Mobile } from '../../utils/styling/media-queries';

export const CoursesPage: React.FC = () => {
  const [t, i18n] = useTranslation();
  const { data, loading, error } = useCourseCollectionQuery({ variables: { locale: i18n.language } });
  const { addNotification, notifications } = useContext(NotificationContext);

  useGAPageViewsTracking({ description: 'courses', url: '/courses' });

  if (error && !notifications.some((n) => n.body === error.message)) {
    addNotification({
      title: '',
      error: true,

      body: error.message,
    });
  }

  const courses = data?.courseCollection?.items
    .filter((course) => course?.isShown === true)
    .sort((a, b) => (!a?.sortIndex || !b?.sortIndex ? 0 : a.sortIndex - b.sortIndex));

  return (
    <PageContainer data-testid="courses-page">
      <HeaderBar title={t('COURSES')} previousPage="/" />
      <PageContainerInner>
        <PageContent>
          <PageHeaderTitle />
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <CardList>
                {courses && courses.length > 0 ? (
                  <>
                    {courses.map((c) => (
                      <CourseCard key={c?.sys.id} course={c as Course} />
                    ))}
                  </>
                ) : (
                  <>{t('NO_COURSES_FOUND')}</>
                )}
              </CardList>
            )}
          </div>
        </PageContent>
      </PageContainerInner>
    </PageContainer>
  );
};

const CourseCard: React.FC<{
  course: CourseCollectionEntry | null;
}> = ({ course }) => {
  const history = useHistory();
  const [t] = useTranslation();
  const link = `/courses/${course?.sys.id}`;

  return (
    <StyledCard>
      <CardActionArea onClick={() => history.push(link)} data-testid={`course-${course?.sys.id}`}>
        {course?.thumbnail?.url && <StyledCardMedia image={course?.thumbnail?.url || ''} />}
        <CardContent>
          <CardContentText>
            <Markdown source={course?.title || ''} />
          </CardContentText>
        </CardContent>
        <StyledCardActions>
          <StyledLinkLookingText>{t('LEARN_MORE')}</StyledLinkLookingText>
        </StyledCardActions>
      </CardActionArea>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  &&.MuiCard-root {
    border-radius: 2px;
    width: 320px;

    ${Mobile} {
      width: 100%;
    }

    .MuiCardActions-root {
      padding: 16px;
      margin-top: auto;
    }

    .MuiCardActionArea-root {
      width: 100%;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: stretch;
    }
  }
`;

const StyledLinkLookingText = styled.div`
  ${linkStyle}
`;

const StyledCardMedia = styled(CardMedia)`
  height: 211px;
`;

const StyledCardActions = styled(CardActions)`
  justify-content: space-between;
`;

const CardContentText = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  padding-bottom: 24px;

  ${Mobile} {
    grid-template-columns: 1fr;
  }
`;

const PageContainerInner = styled.div`
  display: flex;
  justify-content: center;
`;

const PageContent = styled.div`
  ${Mobile} {
    width: 100%;
  }
`;
