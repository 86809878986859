import { useContext } from 'react';

import { NotificationContext } from '../../components/notification/notification-context';
import { useAllDataForQuizQuery } from '../../generated/client';
import { useTranslation } from '../../i18n/translation-provider';

export const useGetQuizData = () => {
  const [, i18n] = useTranslation();
  const { addNotification } = useContext(NotificationContext);

  const { data, error, loading } = useAllDataForQuizQuery({ variables: { locale: i18n.language } });
  if (error) {
    addNotification({
      title: '',
      error: true,
      body: error.message,
    });
  }

  return { data, loading };
};
