import { createContext } from 'react';

import { NotificationWithId, Notification } from './notification';

export interface NotificationContextType {
  addNotification: (notification: Notification) => void;
  clearNotification: (notification: NotificationWithId) => void;
  notifications: Notification[];
}

export const NotificationContext = createContext<NotificationContextType>({} as NotificationContextType);
