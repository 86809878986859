import React from 'react';

export const DLSIconCalendar: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg viewBox="0 0 48 48" width="100%" height="100%">
      <path
        fill={fill}
        d="M37 10h-6l.005-8h5.983L37 10zm9-4v40H2V6h7v6h10V6h10v6h10V6h7zM15 30H9v6h6v-6zm0-8H9v6h6v-6zm8 8h-6v6h6v-6zm0-8h-6v6h6v-6zm8 8h-6v6h6v-6zm0-8h-6v6h6v-6zm8 0h-6v6h6v-6zM17 2h-5.988L11 10h6V2z"
      />
    </svg>
  );
};
