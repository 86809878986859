import React from 'react';

export const DLSIconLocation: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg viewBox="0 0 48 48" width="100%" height="100%">
      <path
        fill={fill}
        d="M23.996 2c-8.837 0-16 7.163-16 16 0 2.912.778 5.643 2.138 7.995C12.9 30.78 23.996 46 23.996 46s11.093-15.217 13.859-19.999A15.924 15.924 0 0039.996 18c0-8.837-7.163-16-16-16zm0 26c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10z"
      />
    </svg>
  );
};
