import { Divider, List } from '@dls/react-core';
import { Icon } from '@dls/react-icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { Spinner } from '../../components/spinner/spinner';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useTranslation } from '../../i18n/translation-provider';
import { useGetRecommendedCourses } from '../../services/course/use-get-recommended-courses';
import { useGetQuizScores } from '../../services/quiz/use-get-quiz-score';

import { SkillsEmptyPage } from './skills-empty-page';
import {
  CardTitle,
  ChartWrapper,
  CoureListItemText,
  CourseThumbnail,
  SkillsBackButton,
  SkillsPageContent,
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  TakeQuizButton,
} from './skills.styles';
import { SkillsetRadarChart } from './skillset-radar-chart/skillset-radar-chart';

export const SkillsOverviewPage: React.FC = () => {
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const { data: dataset, loading: datasetLoading } = useGetQuizScores();
  const { data: courses, loading: coursesLoading } = useGetRecommendedCourses(dataset, i18n.language);

  useGAPageViewsTracking({ description: 'SkillsOverviewPage', url: '/skills/overview' });

  const onCourseClick = (id = '') => {
    history.push(`/courses/${id}`);
  };

  const onReset = () => {
    history.push('/skills/interest-areas');
  };

  if (coursesLoading || datasetLoading) {
    return <Spinner />;
  }

  if (!dataset.length) {
    return <SkillsEmptyPage />;
  }

  return (
    <PageContainer data-testid="skills-overview-page">
      <HeaderBar title={t('SKILLS')} previousPage="/" />
      <SkillsPageContent>
        <StyledCard>
          <StyledCardContent>
            <ChartWrapper>
              <SkillsetRadarChart dataset={dataset} />
            </ChartWrapper>
            <CardTitle>{t('RECOMMENDED_COURSES')}</CardTitle>
          </StyledCardContent>
          <List>
            {courses.map((c, index) => (
              <React.Fragment key={c?.sys?.id}>
                <ListItem
                  data-testid="course-entry"
                  button
                  disableRipple
                  disableTouchRipple
                  onClick={() => onCourseClick(c?.sys?.id)}
                >
                  <ListItemAvatar>
                    <CourseThumbnail src={c?.thumbnail?.url || ''} />
                  </ListItemAvatar>

                  <CoureListItemText primary={c?.title} secondary={c?.level} />
                  <ListItemSecondaryAction>
                    <Icon name="NavigationRight" />
                  </ListItemSecondaryAction>
                </ListItem>
                {index < courses.length - 1 ? <Divider /> : null}
              </React.Fragment>
            ))}
          </List>
          <StyledCardActions>
            <SkillsBackButton
              variant="quietEmphasis"
              onClick={() => history.push('/')}
              data-testid="skills-back-button"
            >
              {t('BACK')}
            </SkillsBackButton>
            <TakeQuizButton variant="quietEmphasis" onClick={onReset} data-testid="test-again-button">
              {t('TEST_AGAIN')}
            </TakeQuizButton>
          </StyledCardActions>
        </StyledCard>
      </SkillsPageContent>
    </PageContainer>
  );
};
