import { ApolloProvider } from '@apollo/client';
import { GlobalStyle } from '@dls/react-globalstyle';
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { createApolloClient } from './apollo/apollo-client';
import { AppContextProvider, AppContextType } from './app-context';
import { AppStore } from './app-store';
import { NotificationWithId } from './components/notification/notification';
import { NotificationProvider } from './components/notification/notification-provider';
import { useNotificationStore } from './components/notification/notification-store';
import ServiceWorkerWrapper from './components/service-worker-wrapper/service-worker-wrapper';
import { IcarusGlobalStyle } from './global-style';
import { TranslationProvider } from './i18n/translation-provider';
import { MuiThemeProvider } from './mui-theme-provider';
import { MainRoutes } from './routes/main-routes';
import { DlsThemeProvider } from './utils/styling/dls-theme-provider';
import { useLazySingleton } from './utils/use-lazy-singleton/use-lazy-singleton';

export const App: React.FC = () => {
  const appStore = useLazySingleton(() => new AppStore()) as AppContextType;
  const [notifications, setNotifications] = useState<NotificationWithId[]>([]);
  const notificationStore = useNotificationStore(notifications, setNotifications);

  return (
    <ApolloProvider client={createApolloClient()}>
      <MuiThemeProvider>
        <DlsThemeProvider>
          <NotificationProvider
            context={notificationStore}
            defaultOptions={{ fullWidth: false, containerMargin: { top: 76 } }}
            notifications={notifications}
            setNotifications={setNotifications}
          >
            <AppContextProvider context={appStore}>
              <TranslationProvider>
                <ServiceWorkerWrapper />
                <GlobalStyle />
                <IcarusGlobalStyle />
                <BrowserRouter>
                  <MainRoutes />
                </BrowserRouter>
              </TranslationProvider>
            </AppContextProvider>
          </NotificationProvider>
        </DlsThemeProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};
