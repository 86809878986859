import React from 'react';

export const DLSIconDocumentPDF: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg viewBox="0 0 24 24" width="100%" height="100%">
      <path
        fill={fill}
        d="M18 17h-1v-6h4v1h-3v2h2v1h-2v2zM8 17H7v-6h2c2 0 2 1 2 2s0 2-2 2H8v2zm0-3h1c1 0 1 0 1-1s0-1-1-1H8v2zM6 1L1 6h5V1zm8 16h-2v-6h2c1.13 0 2 .87 2 2v2c0 1.13-.87 1.99-2 2zm-1-1h1c.56 0 1-.44 1-1v-2c0-.56-.44-1-1-1h-1v4zm10-7v10h-6v4H1V7h6V1h10v8h6zm-1 1H6v8h16v-8z"
      />
    </svg>
  );
};
