import React from 'react';

export const DLSIconConversationSpeech: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-836 -409)">
          <g transform="translate(316 128)">
            <g transform="translate(440 220)">
              <g transform="translate(80 61)">
                <path d="M0 0H48V48H0z" />
                <path
                  fill={fill}
                  fillRule="nonzero"
                  d="M42 31.8l.1 6.9-6.8-4.4c-1 .1-1.9.2-2.9.2-7.5 0-13.6-4-13.6-9s6.1-9.1 13.6-9.1 13.6 4 13.6 9c0 2.6-1.4 4.8-4 6.4zm-13.3-17C27.2 10.9 21.9 8 15.6 8 8.1 8 2 12 2 17c0 2.5 1.5 4.8 4 6.4v7.4l5.9-4.4.8-.5c1 .1 1.9.2 2.9.2h1.3v-.6c0-5.3 5-9.6 11.8-10.7z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
