import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider as UpstreamMuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';

import { muiTheme } from './mui-theme';

export const MuiThemeProvider: React.FC = ({ children }) => {
  return (
    <UpstreamMuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </UpstreamMuiThemeProvider>
  );
};
