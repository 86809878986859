import React from 'react';

const DEFAULT_VALUE = Symbol('__LAZY_SINGLETON_DEFAULT_VALUE__');

export function useLazySingleton<T>(creator: () => T) {
  const ref = React.useRef<T | typeof DEFAULT_VALUE>(DEFAULT_VALUE);

  if (ref.current === DEFAULT_VALUE) {
    ref.current = creator();
  }

  return ref.current as T;
}
