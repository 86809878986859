import React from 'react';

export const HighlightText = ({ searchText, text }: { text: string; searchText: string }) => {
  if (searchText.trim() === '') {
    return <span> {text} </span>;
  }
  // Split on highlight term and include term into parts, ignore case

  const parts: Record<string, string> = {};
  text.split(new RegExp(`(${searchText})`, 'gi')).forEach((part, index) => {
    parts[`${part}${index}`] = part;
  });

  return (
    <span>
      {Object.entries(parts).map(([partKey, part]) => (
        <span key={partKey} style={part.toLowerCase() === searchText.toLowerCase() ? { fontWeight: 'bold' } : {}}>
          {part}
        </span>
      ))}
    </span>
  );
};
