function copyToArrayBuffer(nodeBuffer: Buffer): ArrayBuffer {
  return nodeBuffer.buffer.slice(nodeBuffer.byteOffset, nodeBuffer.byteOffset + nodeBuffer.byteLength);
}

function copyToUint8Array(nodeBuffer: Buffer): Uint8Array {
  return new Uint8Array(copyToArrayBuffer(nodeBuffer));
}

function string2Uint8Array(str: string): Uint8Array {
  const buffer = Buffer.from(str, 'utf8');
  return copyToUint8Array(buffer);
}

function uint8Array2string(arr: Uint8Array): string {
  return Buffer.from(arr).toString('hex');
}

export { uint8Array2string, string2Uint8Array };
