import React from 'react';

export const DLSIconList: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-616 -189)">
          <g transform="translate(316 128)">
            <g transform="translate(220)">
              <g transform="translate(80 61)">
                <path d="M0 0H48V48H0z" />
                <path
                  fill={fill}
                  fillRule="nonzero"
                  d="M10 12H4V6h6v6zm34-6H14v6h30V6zM10 16H4v6h6v-6zm34 0H14v6h30v-6zM10 26H4v6h6v-6zm34 0H14v6h30v-6zM10 36H4v6h6v-6zm34 0H14v6h30v-6z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
