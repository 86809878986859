import { useEffect } from 'react';

import { LanguageCode } from '../i18n/language-code';
import { useTranslation } from '../i18n/translation-provider';

import { trackPage } from './pageViewTracking';

const useGAPageViewsTracking = (params: { description: string; url: string }) => {
  const [, { language }] = useTranslation();
  const { description, url } = params;

  useEffect(() => {
    trackPage({
      langCode: language as LanguageCode,
      description,
      url,
    });
  }, [language, description, url]);
};

export { useGAPageViewsTracking };
