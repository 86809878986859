import { useMemo } from 'react';

import { RadarChartDataset } from '../../components/radar-chart';
import { useCourseCollectionQuery, useAllDataForQuizQuery } from '../../generated/client';

export const useGetRecommendedCourses = (dataset: RadarChartDataset[], locale: string) => {
  const courses = useCourseCollectionQuery({ variables: { locale } });
  const quiz = useAllDataForQuizQuery({ variables: { locale } });

  return useMemo(() => {
    if (courses.loading || quiz.loading) {
      return {
        loading: true,
        data: [],
      };
    }

    const interestAreaCollection =
      quiz.data?.interestAreaCollection?.items.map((area) => ({ id: area?.sys.id, name: area?.interestArea })) || [];

    const quizResult = dataset.reduce((prev: Record<string, number | null>, current: RadarChartDataset) => {
      return {
        ...prev,
        [current.label]: current.value,
      };
    }, {});

    const courseItems =
      courses.data?.courseCollection?.items
        .slice()
        .sort((a, b) => (!a?.sortIndex || !b?.sortIndex ? 0 : a.sortIndex - b.sortIndex)) || [];

    const filteredCourses = courseItems.filter((course) => {
      if (!course) {
        return false;
      }
      const { interestArea, thresholdScoreSkillsQuiz } = course;

      const courseInterestAreaId = interestArea?.sys.id;

      if (!courseInterestAreaId) {
        return true;
      }

      const interestAreaName = interestAreaCollection.find((area) => area.id === courseInterestAreaId)?.name;

      if (!interestAreaName) {
        return true;
      }

      const scoreForInterestArea = quizResult[interestAreaName];

      if (scoreForInterestArea === null) {
        return false;
      }

      if (!scoreForInterestArea || !thresholdScoreSkillsQuiz) {
        return true;
      }

      return scoreForInterestArea < thresholdScoreSkillsQuiz;
    });

    return {
      loading: false,
      data: filteredCourses,
    };
  }, [courses, dataset, quiz]);
};
