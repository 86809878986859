import { Button, FormControlLabel, Radio, RadioGroup } from '@dls/react-core';
import { CardContent, FormControl } from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAppContext } from '../../app-context';
import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { NotificationContext } from '../../components/notification/notification-context';
import { Spinner } from '../../components/spinner/spinner';
import { SkillQuizResult } from '../../entities/quiz/skill-quiz-result';
import { createMicrositeEvent } from '../../ga360/createMicrositeEvent';
import { createMicrositeIdFromEmail } from '../../ga360/createMicrositeId';
import { emitMicrositeEvent } from '../../ga360/micrositeEvent';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useTranslation } from '../../i18n/translation-provider';
import { useGetQuestionsPerAreaFromQuiz } from '../../services/quiz/use-get-questions-per-area-from-quiz';
import { ColorDLS } from '../../utils/styling/colors';

import QuizHeader from './quiz-header.svg';
import { SkillsBackButton, SkillsPageContent, StyledCard, StyledCardActions, StyledCardMedia } from './skills.styles';
import { useGetQuizData } from './use-get-quiz-data';

const QuestionGroup = styled.section`
  margin-bottom: 48px;

  && {
    .MuiFormControlLabel-root {
      height: auto;
      margin-bottom: 8px;
    }

    .MuiRadio-colorSecondary {
      position: relative;
      width: 36px;
      height: 36px;
    }

    .MuiFormControlLabel-root.radioButton {
      margin-left: -10px;
    }

    .MuiFormControlLabel-label {
      opacity: 0.8;
      font: 400 16px/20px CentraleSans;
      color: ${ColorDLS.Gray75};
    }
  }
`;
const QuestionGroupTitle = styled.h2`
  font: 400 20px/24px CentraleSans;
  color: ${ColorDLS.Gray45};
`;

const Question = styled.div`
  margin-bottom: 32px;

  &&&& {
    .PrivateSwitchBase-input-4 {
      display: none;
    }
  }
`; // PrivateSwitchBase-input-4 removes focus ring

const QuestionTitle = styled.h3`
  font-family: 500 16px/20px CentraleSans;
  color: ${ColorDLS.Gray75};
`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

export const SkillsQuizPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState<boolean>();
  const { addNotification } = useContext(NotificationContext);
  const { userInfoStore } = useAppContext();

  const params = useParams<{ interestAreas?: string }>();
  const preferredInterestAreas = params.interestAreas?.split(',') || [];

  useGAPageViewsTracking({ description: 'SkillsQuizPage', url: '/skills/quiz' });

  const { data, loading } = useGetQuizData();

  const quiz = data?.skillsetAnalysisCollection?.items[0];
  const quizTitle = quiz?.name || '';

  const availableQuestionsPerArea = useGetQuestionsPerAreaFromQuiz({ data });

  const [answerState, setAnswerState] = useState<Record<string, string>>({});
  const handleAnswerChange =
    (questionId: string) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setAnswerState((prevState) => ({
        ...prevState,
        [questionId]:
          data?.skillAnswerCollection?.items.find((answer) => answer?.sys.id === event.target.value)?.points || '',
      }));
    };

  async function onSubmitForm(event: React.FormEvent) {
    event.preventDefault();
    setIsSaving(true);
    try {
      const score: SkillQuizResult[] =
        data?.interestAreaCollection?.items.map((area) => {
          let percentage: number | null = null;
          if (preferredInterestAreas.includes(area?.sys.id || '')) {
            const totalScore =
              area?.questionsCollection?.items.reduce(
                (total, question) => total + (question ? parseInt(answerState[question.sys.id], 10) : 0),
                0,
              ) || 0;
            percentage = (totalScore / (area?.questionsCollection?.items.length || 1)) * 100;
          }

          return {
            interestArea: area?.interestArea || '',
            scorePercentage: percentage,
          };
        }) || [];
      await axios.put<{ results: SkillQuizResult[] } | ''>('/api/skillset-quiz/results/me', { results: score });
      history.push('/skills/overview');
      emitMicrositeEvent(
        createMicrositeEvent({
          micrositeID: createMicrositeIdFromEmail(userInfoStore?.userInfo?.username),
          action: `quiz-submitted`,
          goal: `quiz-submitted`,
        }),
      );
    } catch (e) {
      addNotification({
        title: 'Error saving skills',
        error: true,

        body: e as string,
      });
    } finally {
      setIsSaving(false);
    }
  }

  const filteredQuestionsPerArea = preferredInterestAreas.length
    ? availableQuestionsPerArea?.filter((area) => preferredInterestAreas.includes(area?.id || ''))
    : availableQuestionsPerArea;

  return (
    <PageContainer data-testid="skills-personal-plan-page">
      <HeaderBar title={quizTitle} previousPage="/skills" />
      <SkillsPageContent>
        <FormControl component="form" onSubmit={onSubmitForm}>
          <StyledCard>
            <StyledCardMedia image={QuizHeader} title={t('SKILLS')} />
            <CardContent>
              {loading || isSaving ? (
                <Spinner />
              ) : (
                <div>
                  {filteredQuestionsPerArea?.map((a) => (
                    <QuestionGroup key={a?.id} data-testid={`question-group-${a?.id}`}>
                      <QuestionGroupTitle>{a?.name}</QuestionGroupTitle>
                      {a?.questionsCollection.map((question) => (
                        <Question key={question.id}>
                          <QuestionTitle>{question?.title}</QuestionTitle>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="vertical"
                              id="vertical-radios"
                              name={`radios-${question.id}`}
                              onChange={handleAnswerChange(question.id)}
                            >
                              {question.answers.map((answer) => (
                                <FormControlLabel
                                  key={`answer${answer.id}`}
                                  className="radioButton"
                                  value={answer.id}
                                  control={<Radio className="dlsRadioIcon" />}
                                  label={answer.answer}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Question>
                      ))}
                    </QuestionGroup>
                  ))}
                </div>
              )}
            </CardContent>
            <StyledCardActions>
              <UnstyledLink
                to={`/skills/interest-areas/${preferredInterestAreas.join(',')}`}
                data-testid="skills-back-button"
              >
                <SkillsBackButton variant="quietEmphasis">{t('BACK')}</SkillsBackButton>
              </UnstyledLink>
              <Button dls_variant="primary" type="submit" data-testid="skills-quiz-submit-button">
                Submit
              </Button>
            </StyledCardActions>
          </StyledCard>
        </FormControl>
      </SkillsPageContent>
    </PageContainer>
  );
};
