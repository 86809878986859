import React from 'react';

export const DLSIconIdea: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-396 -409)">
          <g transform="translate(316 128)">
            <g transform="translate(0 220)">
              <g transform="translate(80 61)">
                <path d="M0 0H48V48H0z" />
                <path
                  fill={fill}
                  fillRule="nonzero"
                  d="M24.12 8.09c-7.17 0-12.99 5.82-12.99 12.99 0 2.77.87 5.33 2.35 7.43C14.83 30.61 18 36 18 36h12s3.65-5.68 4.89-7.65h-.01c1.4-2.07 2.23-4.57 2.23-7.27 0-7.17-5.82-12.99-12.99-12.99zM18 41h12v-3H18v3zm0 5h12v-3H18v3zm7.01-44H23v4h2.01V2zM13.87 4.44l-1.74 1 2 3.46 1.74-1-2-3.46zm-8.42 7.69l-1 1.74 3.46 2 1-1.74-3.46-2zm38.11 1.74l-1-1.74-3.46 2 1 1.74 3.46-2zm-7.69-8.42l-1.74-1-2 3.46 1.74 1 2-3.46z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
