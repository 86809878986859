import { Card, CardContent } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ContentfulRichText, ContentfulRichTextType } from '../../components/contentful-rich-text';
import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { useTranslation } from '../../i18n/translation-provider';
import { Mobile } from '../../utils/styling/media-queries';

type StaticPageResponse = {
  title: string;
  text: ContentfulRichTextType;
};

export const StaticPage: React.FC<{ endpoint: string }> = ({ endpoint }) => {
  const [, i18n] = useTranslation();

  const [page, setPage] = useState<StaticPageResponse | undefined>(undefined);

  useEffect(() => {
    const fetchPage = async () => {
      const result = await axios.get<StaticPageResponse>(`/api/public/${endpoint}?locale=${i18n.language}`);
      setPage(result.data);
    };
    fetchPage();
  }, [endpoint, i18n]);

  return (
    <PageContainer>
      <HeaderBar title={page?.title || ''} previousPage="/" hideProfileIcon={true} />
      <PageContent>
        <StyledCard>
          <CardContent>{page ? <ContentfulRichText content={page.text || undefined} /> : null}</CardContent>
        </StyledCard>
      </PageContent>
    </PageContainer>
  );
};

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const StyledCard = styled(Card)`
  &&.MuiCard-root {
    border-radius: 2px;
    width: 648px;
    margin-bottom: 24px;

    ${Mobile} {
      width: 100%;
    }

    .MuiCardContent-root {
      padding: 24px;
    }
  }
`;
