import React from 'react';

export const DLSIconOperating: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-616 -409)">
          <g transform="translate(316 128)">
            <g transform="translate(220 220)">
              <g transform="translate(80 61)">
                <path d="M0 0H48V48H0z" />
                <path
                  fill={fill}
                  fillRule="nonzero"
                  d="M27.3 23.1c.6-1 1-2 1.3-3.1H32v-6h-3.4c-.3-1.1-.7-2.1-1.3-3.1l2.4-2.4-4.2-4.2-2.4 2.4c-1-.6-2-1-3.1-1.3V2h-6v3.4c-1.1.3-2.1.7-3.1 1.3L8.5 4.3 4.3 8.5l2.4 2.4c-.6 1-1 2-1.3 3.1H2v6h3.4c.3 1.1.7 2.1 1.3 3.1l-2.4 2.4 4.2 4.2 2.4-2.4c.9.6 2 1 3.1 1.3V32h6v-3.4c1.1-.3 2.1-.7 3.1-1.3l2.4 2.4 4.2-4.2-2.4-2.4zM17 21c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm29 17v-4h-3.3c-.1-.5-.3-.9-.5-1.3l2.3-2.3-2.8-2.8-2.3 2.3c-.4-.2-.9-.4-1.3-.5V26h-4v3.3c-.5.1-.9.3-1.3.5l-2.3-2.3-2.8 2.8 2.3 2.3c-.2.4-.4.9-.5 1.3H26v4h3.3c.1.5.3.9.5 1.3l-2.3 2.3 2.8 2.8 2.3-2.3c.4.2.9.4 1.3.5V46h4v-3.3c.5-.1.9-.3 1.3-.5l2.3 2.3 2.8-2.8-2.3-2.3c.2-.4.4-.9.5-1.3H46V38zm-10 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
