// ISO 639-1 codes
export enum LanguageCode {
  English = 'en',
  German = 'de',
}

export type Language = {
  code: LanguageCode;
  label: string;
};

export const AVAILABLE_LANGUAGES = Object.entries(LanguageCode).map(([label, code]) => ({ code, label } as Language));

export const getDefaultLanguageCode = () =>
  process.env.NODE_ENV === 'production' ? LanguageCode.German : LanguageCode.English;
