import React from 'react';

export const DLSIconQuestionMarkCircle: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg viewBox="0 0 48 48" width="100%" height="100%">
      <path
        fill={fill}
        d="M24 2C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2zm-.616 37.861c-1.892 0-3.212-.836-3.212-2.948 0-2.068 1.32-2.949 3.212-2.949 1.848 0 3.212.881 3.212 2.949 0 2.112-1.364 2.948-3.212 2.948zm2.244-12.41v2.288h-4.797v-2.464c0-6.117 7.085-6.205 7.085-10.782 0-2.42-1.937-3.521-4.312-3.521-2.597 0-5.193 1.32-6.469 2.068l-1.892-4.269C17.223 9.364 20.392 8 24 8c4.621 0 9.637 2.289 9.637 7.965 0 7.129-8.009 6.821-8.009 11.486z"
      />
    </svg>
  );
};
