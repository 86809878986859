import React from 'react';

import { ClickablePaperItem } from '../../../components/clickable-paper-item';
import { FullExam } from '../../../entities/backend/full-exam';

import { AssetComponentProps } from './asset-list-types';

export const AssetComponentExam: React.FC<AssetComponentProps> = ({ asset, setCurrentExam }) => {
  const exam = asset as FullExam;
  return (
    <ClickablePaperItem
      disabled={!exam.examUrl}
      key={exam.sys.id}
      icon="QuestionMarkCircle"
      label={exam.name}
      onClick={() => {
        setCurrentExam(exam);
      }}
    />
  );
};
