import React from 'react';
import styled from 'styled-components';

import { RadarChart, RadarChartDataset } from '../../../components/radar-chart';

type SkillsetRadarChartProps = {
  dataset: RadarChartDataset[];
};

export const SkillsetRadarChart: React.FC<SkillsetRadarChartProps> = ({ dataset }) => {
  return (
    <ChartContainer>
      <RadarChartContainer>
        <RadarChart dataset={dataset} />
      </RadarChartContainer>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  font-size: 32px;
`;

const RadarChartContainer = styled.div`
  width: 343px;
  height: 238px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
