import React, { useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { TabBar } from './tab-bar';

type TabBarContextType = { addTab: (tabKey: string, tab: TabDefinition) => void; removeTab: (tabKey: string) => void };
const TabBarContext = React.createContext<TabBarContextType | undefined>(undefined);

type TabDefinition = Readonly<{ title: string; route: string; children: JSX.Element }>;
export const TabBarWithRouting: React.FC = ({ children }) => {
  const [tabsRecord, setTabsRecord] = React.useState<Record<string, TabDefinition>>({});
  const tabBarContext = React.useMemo(
    () => ({
      addTab: (tabKey: string, tab: TabDefinition) => {
        setTabsRecord((t) => ({ ...t, [tabKey]: tab }));
      },
      removeTab: (tabKey: string) => {
        setTabsRecord((t) => {
          const newTabs = { ...t };
          delete newTabs[tabKey];
          return newTabs;
        });
      },
    }),
    [setTabsRecord],
  );
  const tabs = React.useMemo(() => Object.values(tabsRecord), [tabsRecord]);
  const history = useHistory();
  const routeMatch = useRouteMatch(tabs.map((tab) => tab.route));
  const activeTabIndex = tabs.findIndex((tab) => tab.route === routeMatch?.path);
  const activeTab = tabs[activeTabIndex];

  const setActiveTab = React.useCallback(
    (route: string) => {
      history.push(route);
    },
    [history],
  );

  const activeRoute = useMemo(() => activeTab?.route, [activeTab]);
  const routeMatchPath = useMemo(() => routeMatch?.path, [routeMatch]);

  React.useEffect(() => {
    if (!activeRoute && tabs.length) {
      setActiveTab(tabs[0].route);
    }
  }, [tabs, activeRoute, routeMatchPath, setActiveTab]);

  return (
    <TabBarContext.Provider value={tabBarContext}>
      <TabBar
        activeTabIndex={activeTabIndex}
        tabs={tabs.map((tab) => ({ title: tab.title, key: tab.route }))}
        onChangeTab={(event, index) => setActiveTab(tabs[index].route)}
      />
      {children}
      {activeTab && activeTab.children}
    </TabBarContext.Provider>
  );
};

export const RoutedTab: React.FC<TabDefinition> = (props) => {
  const tabBarContext = React.useContext(TabBarContext);

  React.useEffect(() => {
    if (!tabBarContext) {
      /* eslint-disable no-console */
      console.error('RoutedTab should be used as children of TabBarWithRouting');
      return;
    }

    tabBarContext.addTab(props.route, props);

    // eslint-disable-next-line consistent-return
    return () => {
      tabBarContext.removeTab(props.route);
    };
  }, [tabBarContext, props]);

  return null;
};
