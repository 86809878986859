import type { GA360MicrositeEvent } from './eddlTypes';

type CreateMicrositeEventParams = {
  micrositeID: string;
  action: string;
  goal: string;
};

const createMicrositeEvent = (params: CreateMicrositeEventParams) => {
  const { micrositeID, action, goal } = params;

  const micrositeEvent: GA360MicrositeEvent = {
    micrositeName: 'icarus',
    micrositeID,
    componentType: 'link',
    action,
    goal,
    interactive: true,
  };
  return micrositeEvent;
};

export { createMicrositeEvent };
