import React from 'react';

import { AppStore } from './app-store';

export type AppContextType = AppStore;

// this context will always be available to all components
export const AppContext = React.createContext<AppContextType>({} as AppContextType);

export const useAppContext = () => {
  return React.useContext(AppContext);
};

/* export type PickInjectedDependencies<K extends keyof AppContextType> = Pick<AppStore, K>; */

export const AppContextProvider: React.FC<{ context: AppContextType }> = ({ context, children }) => {
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
