import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { SkillsInterestAreasPage } from './skills-interest-areas-page';
import { SkillsOverviewPage } from './skills-overview-page';
import { SkillsPersonalPlanPage } from './skills-personal-plan-page';
import { SkillsQuizPage } from './skills-quiz-page';

export const SkillsPage: React.FC = () => {
  const match = useRouteMatch('/skills');
  return (
    <>
      {match?.isExact && <Redirect to="/skills/overview" />}
      <Route path="/skills/overview" exact>
        <SkillsOverviewPage />
      </Route>
      <Route path="/skills/interest-areas/:interestAreas?">
        <SkillsInterestAreasPage />
      </Route>
      <Route path="/skills/quiz/:interestAreas?">
        <SkillsQuizPage />
      </Route>
      <Route path="/skills/personal-plan" exact>
        <SkillsPersonalPlanPage />
      </Route>
    </>
  );
};
