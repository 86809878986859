import React from 'react';

export const NoCoursesIcon: React.FC = () => {
  return (
    <svg width="120px" height="120px" viewBox="0 0 120 120" version="1.1">
      <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3.0.1-my-icarus---courses---empty---alt---1280" transform="translate(-580.000000, -226.000000)">
          <g id="Group-6" transform="translate(316.000000, 172.000000)">
            <g id="img-no_courses" transform="translate(264.000000, 54.000000)">
              <circle id="Oval" fill="#F0F9FA" cx="60" cy="60" r="60" />
              <g id="Group-3" transform="translate(1.000000, 24.000000)">
                <path
                  d="M26,34 L92.5625,34 L92.5625,52.7574189 C92.5624989,58.86876 88.854887,64.3690706 83.1899214,66.6618102 C75.2203643,69.8872701 67.2508071,71.5 59.28125,71.5 C51.311694,71.5 43.342138,69.8872705 35.372582,66.6618115 C29.7076133,64.3690743 26,58.868762 26,52.757419 L26,34 L26,34 Z"
                  id="Rectangle"
                  fill="#1B578E"
                />
                <path
                  d="M83.1899214,66.6618102 C75.2203643,69.8872701 67.2508071,71.5 59.28125,71.5 C51.311694,71.5 43.342138,69.8872705 35.372582,66.6618115 C30.4714762,64.6782264 27.0354553,60.293764 26.1979418,55.18988 C27.4742703,56.3961918 28.9746821,57.3895116 30.6529308,58.0999524 C40.1020898,62.0999923 49.5512488,64.1000123 59.0004077,64.1000123 C68.4495655,64.1000123 77.8987234,62.0999928 87.3478812,58.0999539 C89.3222561,57.264157 91.0504987,56.0368461 92.457749,54.5310026 C91.8189166,59.9134775 88.3011064,64.5931981 83.1899214,66.6618102 Z"
                  id="Combined-Shape"
                  fill="#1474A4"
                />
                <rect id="Rectangle" fill="#1A3670" x="12" y="24.84375" width="3.75" height="21.09375" />
                <path
                  d="M11.53125,45.9375 L16.21875,45.9375 L17.9265124,60.8804209 C18.0307233,61.7922124 17.4596354,62.6454369 16.5769712,62.8966891 C15.6763142,63.153063 14.7756571,63.28125 13.875,63.28125 C12.9743385,63.28125 12.0736771,63.1530618 11.1730156,62.8966853 C10.2903645,62.645423 9.71928343,61.7922075 9.82348714,60.880425 L11.53125,45.9375 L11.53125,45.9375 Z"
                  id="Rectangle-Copy-3"
                  fill="#1A3670"
                />
                <circle id="Oval" fill="#1A3670" cx="13.875" cy="45.9375" r="3.75" />
                <path
                  d="M1.16930464,26.9007755 L58.4499536,50.1896228 C58.902174,50.373484 59.408272,50.3737267 59.8606685,50.1902993 L117.299156,26.9014521 C118.258809,26.512354 118.721335,25.4189755 118.332237,24.4593229 C118.141722,23.9894464 117.769033,23.6167573 117.299156,23.4262425 L59.8606685,0.137395198 C59.408272,-0.046032222 58.902174,-0.0457895132 58.4499536,0.138071733 L1.16930464,23.426919 C0.210025647,23.8169373 -0.251451315,24.9107589 0.138566996,25.8700379 C0.329062309,26.3385752 0.700767274,26.7102802 1.16930464,26.9007755 Z"
                  id="Path-2"
                  fill="#1A3670"
                />
                <path
                  d="M9.76306381,23.50777 L63.5480638,44.56477 L59.7736948,46.0491868 C59.4328799,46.1831281 59.0566657,46.1997157 58.7071025,46.0989784 L58.5591023,46.0486222 L9.24180767,26.613367 C8.83840799,26.4543928 8.51837874,26.1441937 8.35436675,25.7531849 C8.01857013,24.9526373 8.41589097,24.0398097 9.24180767,23.7143275 L9.24180767,23.7143275 L9.76306381,23.50777 Z"
                  id="Combined-Shape"
                  fill="#1D4C84"
                />
                <path
                  d="M9.76306381,23.50777 L63.5480638,44.56477 L59.7736948,46.0491868 C59.4328799,46.1831281 59.0566657,46.1997157 58.7071025,46.0989784 L58.5591023,46.0486222 L9.24180767,26.613367 C8.83840799,26.4543928 8.51837874,26.1441937 8.35436675,25.7531849 C8.01857013,24.9526373 8.41589097,24.0398097 9.24180767,23.7143275 L9.24180767,23.7143275 L9.76306381,23.50777 Z"
                  id="Combined-Shape"
                  fill="#1C417A"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
