import useMediaQuery from '@material-ui/core/useMediaQuery';

import { BreakpointsByDevice } from './breakpoint';

const { mobile, tablet, laptop } = BreakpointsByDevice;

const between = (min: number, max: number) => {
  return `@media (min-width: ${min}px) and (max-width: ${max}px)`;
};

const smallerThan = (threshold: number) => {
  return `@media (max-width: ${threshold}px)`;
};

const biggerThan = (threshold: number) => {
  return `@media (min-width: ${threshold}px)`;
};

export const Mobile = between(mobile.min, mobile.max);
export const Tablet = between(tablet.min, tablet.max);
export const TabletAndSmaller = smallerThan(tablet.max);
export const TabletAndBigger = biggerThan(tablet.min);
export const LaptopAndBigger = biggerThan(laptop.min);

export const useMobile = () => {
  return useMediaQuery(Mobile);
};
