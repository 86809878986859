import { createGlobalStyle } from 'styled-components';

import { ColorDLS } from './utils/styling/colors';
import { Mobile } from './utils/styling/media-queries';

export const IcarusGlobalStyle = createGlobalStyle`

  html {
    ${Mobile} {
      width: 100vw;
      overflow-x: hidden;
    }
  }

  button {
    font-family: CentraleSans, Tahoma, sans-serif;
  }

  body {
    font-family: CentraleSans, Tahoma, sans-serif;
    background-color: ${ColorDLS.Gray05};
    color: #212121;

    .MuiDialog-root, .MuiBackdrop-root {
      ${Mobile} {
        width: 100vw;
        height: 100vh;
      }
    }

    .MuiDialog-container {
      ${Mobile} {
        width: 100vw;
        height: 100vh;
      }
    }
  }

  #root {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: auto;
  }
`;

export const HideRootOnMobileGlobalStyle = createGlobalStyle`
  ${Mobile} {
    #root {
      display: none;
    }
  }
`;
