import { TranslationValues } from '../translation-types';

export const translationLocaleEn: TranslationValues = {
  ADMIN_PAGE: 'Learning Academy ICARUS',
  PROJECT_TITLE: 'Learning Academy ICARUS',
  DASHBOARD_TITLE: 'Qualify - Develop - Connect',
  MY_ICARUS: 'My Icarus',
  MY_COURSES: 'My courses',
  MY_PROFILE: 'My profile',
  SKILLS: 'Skills',
  SKILLS_QUIZ: 'Skills quiz',
  COURSES: 'Courses',
  NEWS: 'News',
  DEVELOPMENTS: 'Developments',
  EFFIGOS: 'X-ray simulator',
  TERMS_AND_CONDITIONS: 'Terms & conditions',
  COOKIE_BELEID: 'Cookie policy',
  PRIVACY_POLICY: 'Privacy policy',
  ABOUT: 'About',
  COMING_SOON: 'Coming Soon',
  COMING_SOON_DESCRIPTION: 'This feature is under development and will be available soon.',
  OK: 'Ok',
  COURSE_DETAILS: 'Course details',
  DATE: 'Date',
  SCHEDULE_FOLLOWS: 'Schedule follows',
  LOCATION: 'Location',
  DETAILS: 'Details',
  COURSE_FEE: 'Course fee',
  DOWNLOAD_FLYER: 'Download flyer',
  PREVIEW: 'Preview',
  OPEN_COURSE: 'Open',
  SUBSCRIBE: 'Subscribe',
  MEDICAL_COURSES: 'Medical courses',
  NO_COURSES_FOUND: 'No courses found',
  LEARN_MORE: 'Learn more',
  READ_MORE: 'Read more',
  SKILLS_OVERVIEW_TITLE: 'Areas for improvement',
  BACK: 'Back',
  PERSONAL_PLAN: 'Personal Plan',
  PERSONAL_PLAN_TITLE: 'Personalized learning plan',
  TEST_AGAIN: 'Test again',
  SUBSCRIBED: 'Subscribed',
  BOOKED: 'Booked',
  EXPIRED: 'Expired',
  SUBSCRIBE_AFTER_EXPIRATION: 'Subscribe',
  REQUESTED: 'Requested',
  MY_COURSES_TITLE: 'My courses',
  STATUS_SUBSCRIBED: 'Status: Subscribed',
  STATUS_UNSUBSCRIBED: 'Status: Unsubscribed',
  STATUS_BOOKED: 'Status: Booked',
  STATUS_REQUESTED: 'Status: Requested',
  STATUS_REVOKED: 'Status: Revoked',
  STATUS_REJECTED: 'Status: Rejected',
  STATUS_EXPIRED: 'Status: Expired',
  EMAIL_ADDRESS: 'E-mail address',
  EMAIL_ADDRESS_HINT: 'Enter email address',
  FIRST_NAME: 'First name',
  FIRST_NAME_HINT: 'Enter first name',
  LAST_NAME: 'Last name',
  LAST_NAME_HINT: 'Enter last name',
  PROFESSION: 'Profession',
  PROFESSION_HINT: 'Select profession',
  FIELD_OF_ACTIVITY_LABEL: 'Organization Type',
  FIELD_OF_ACTIVITY_HINT: 'Select organization type',
  ORGANIZATION_LABEL: 'Organization',
  ORGANIZATION_HINT: 'Select organization',
  WORKING_EXPERIENCE_LABEL: 'Working experience',
  WORKING_EXPERIENCE_HINT: 'Select working experience',
  FOCUS: 'Focus',
  SAVE: 'Save',
  SIGN_OUT: 'Sign out',
  PROFESSIONS_MTRA: 'MTRA',
  PROFESSIONS_LEADING_MTRA: 'Senior MTRA',
  PROFESSIONS_MFA: 'MFA',
  PROFESSIONS_RADIOLOGIST: 'Radiologist',
  PROFESSIONS_OTHER_CERTIFIED_XRAY: 'Other technical job with X-Ray certification',
  PROFESSIONS_OTHER: 'Other',
  FIELD_OF_ACTIVITY_HOSPITAL: 'Hospital',
  FIELD_OF_ACTIVITY_OFFICE: 'Office',
  FIELD_OF_ACTIVITY_MVZ: 'Medical care center',
  WORK_EXPERIENCE_LONG: '> 5 years',
  WORK_EXPERIENCE_MEDIUM: '3-5 years',
  WORK_EXPERIENCE_SHORT: '1-2 years',
  FOCUS_AREA_CT: 'CT',
  FOCUS_AREA_MR: 'MR',
  FOCUS_AREA_XRAY: 'X-Ray',
  FOCUS_AREA_NUCLEAR_MEDICINE: 'Nuclear Medicin',
  FOCUS_AREA_VETERINARY_MEDICINE: 'Veterinary Medicine',
  FOCUS_AREA_RADIOTHERAPY: 'Radiotherapy',
  FOCUS_AREA_INTERVENTIONAL_RADIOLOGY: 'Interventional Radiology',
  LANGUAGE: 'Language',
  APPLY: 'Apply',
  NO_COURSES_TITLE: 'You are not subscribed to any courses',
  NO_COURSES_NOT_SUBSCRIBED: "You didn't subscribe to any courses.",
  NO_COURSES_BROWSE_COURSES: 'Click on the Browse courses button to find courses.',
  NO_COURSES_BROWSE_BUTTON: 'Browse courses',
  PREPARATION: 'Preparation',
  OVERVIEW: 'Overview',
  SKILLS_RECOMMENDATION: 'Take the quiz to receive personal course recommendations.',
  TAKE_QUIZ: 'Take quiz',
  RECOMMENDED_COURSES: 'Recommended courses',
  CONTINUE: 'Continue',
  CHOOSE_AREAS: 'Choose the areas which are relevant to you.',
  DELETE: 'Delete Account',
  PERMANENTLY_DELETE: 'Permanently Delete',
  PHILIPS_PROJECT_TITLE: 'Philips Learning Academy Icarus',
  DELETE_ACCOUNT_QUESTION: 'Delete Your Account?',
  DELETE_ACCOUNT_ARE_YOU_SURE: 'Are you sure you want to delete your account? This action is not reversible!',
  CANCEL: 'Cancel',
};
