import { Button } from '@dls/react-labs';
import { Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import Markdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAppContext } from '../../app-context';
import { ContentfulRichText } from '../../components/contentful-rich-text';
import { DLSIcon, IconName } from '../../components/dls-icon/dls-icon';
import { CourseDetails } from '../../entities/course-details';
import { createMicrositeEvent } from '../../ga360/createMicrositeEvent';
import { createMicrositeIdFromEmail } from '../../ga360/createMicrositeId';
import { emitMicrositeEvent } from '../../ga360/micrositeEvent';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useTranslation } from '../../i18n/translation-provider';
import { ColorDLS } from '../../utils/styling/colors';
import { linkStyle } from '../../utils/styling/link';
import { BookingState, DerivedBookingState, FrontendBookingState } from '../admin-page/booking-state';

export const CourseDetailCard: React.FC<{
  courseDetails: CourseDetails;
  state: DerivedBookingState | undefined;
}> = ({ courseDetails, state }) => {
  const { userInfoStore } = useAppContext();
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [courseRequested, setCourseRequested] = useState<boolean>(false);

  const buttonText = () => {
    if (state === FrontendBookingState.Expired) {
      return t('SUBSCRIBE_AFTER_EXPIRATION');
    }
    if (state === BookingState.Subscribed) {
      return t('BOOKED');
    }
    if (state === BookingState.Requested || courseRequested) return t('REQUESTED');
    return t('SUBSCRIBE');
  };

  async function RequestCourse(event: React.MouseEvent) {
    event.preventDefault();
    setIsRequesting(true);

    try {
      await axios.post('/api/bookings/requests', { courseIdentifier: courseDetails.identifier });
      setCourseRequested(true);
      emitMicrositeEvent(
        createMicrositeEvent({
          micrositeID: createMicrositeIdFromEmail(userInfoStore?.userInfo?.username),
          action: `course-requested-${courseDetails.title}`,
          goal: `course-requested-${courseDetails.id}-${courseDetails.title}`,
        }),
      );
    } finally {
      setIsRequesting(false);
    }
  }

  const previewCourse = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (courseDetails.identifier !== 'Effigos') {
      history.push(`/courses/preview/${courseDetails.id}`);
    } else {
      const version = state === BookingState.Subscribed ? 'full' : 'demo';
      emitMicrositeEvent(
        createMicrositeEvent({
          micrositeID: createMicrositeIdFromEmail(userInfoStore?.userInfo?.username),
          action: `course-previewing-${courseDetails.title}`,
          goal: `course-previewing-${courseDetails.id}-${courseDetails.title}-${version}`,
        }),
      );
      const uiLink =
        i18n.language === 'en'
          ? 'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxyb290Pg0KICA8c2V0dGluZ3MgbGFuZ3VhZ2U9IkVOIiAvPg0KPC9yb290Pg'
          : 'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxyb290Pg0KICA8c2V0dGluZ3MgbGFuZ3VhZ2U9IkRFIiAvPg0KPC9yb290Pg';
      window.location.assign(`/effigos?uiLink=${uiLink}`);
    }
  };

  useGAPageViewsTracking({ description: courseDetails.title, url: `/courses/${courseDetails.id}` });

  return (
    <StyledCard>
      {courseDetails.image && <StyledCardMedia image={courseDetails.image} />}
      <CardContent>
        <CardTitle>{t('COURSE_DETAILS')}</CardTitle>
        <InformationContainer>
          <InformationRow icon="Calendar" title={t('DATE')}>
            <span>
              {!courseDetails.startDate || !courseDetails.endDate ? (
                t('SCHEDULE_FOLLOWS')
              ) : (
                <>
                  {courseDetails.startDate} - {courseDetails.endDate}
                </>
              )}
            </span>
          </InformationRow>
          <InformationRow icon="Location" title={t('LOCATION')}>
            <Markdown source={courseDetails.location} />
          </InformationRow>
          <InformationRow icon="Education" title={t('DETAILS')}>
            <ContentfulRichText content={courseDetails.description} />
          </InformationRow>
          <InformationRow icon="Tag" title={t('COURSE_FEE')}>
            {courseDetails.price}
          </InformationRow>
        </InformationContainer>
        <InformationRowContainer>
          <ExportIconContainer>
            <DLSIcon name="Download" size={24} color={ColorDLS.Blue45} />
          </ExportIconContainer>
          <ExportLinkContainer>
            <ExportLink onClick={() => window.open(courseDetails.flyer)} data-testid="download-flyer-button">
              {t('DOWNLOAD_FLYER')}
            </ExportLink>
          </ExportLinkContainer>
        </InformationRowContainer>
      </CardContent>
      <StyledCardActions>
        <StyledButton variant="secondary" onClick={previewCourse} data-testid="course-preview-button">
          {state === BookingState.Subscribed ? t('OPEN_COURSE') : t('PREVIEW')}
        </StyledButton>
        <StyledButton
          variant="primary"
          disabled={
            state === BookingState.Subscribed || state === BookingState.Requested || isRequesting || courseRequested
          }
          onClick={RequestCourse}
          data-testid="course-subscribe-button"
        >
          {buttonText()}
        </StyledButton>
      </StyledCardActions>
    </StyledCard>
  );
};

const InformationRow: React.FC<{ icon: IconName; title: string; children: string | JSX.Element }> = ({
  icon,
  title,
  children,
}) => {
  return (
    <InformationRowContainer>
      <InformationIconContainer>
        <DLSIcon name={icon} size={24} color={ColorDLS.Blue45} />
      </InformationIconContainer>
      <InformationTextContainer>
        <InformationTitle>{title}</InformationTitle>
        <InformationSubtitle>{children}</InformationSubtitle>
      </InformationTextContainer>
    </InformationRowContainer>
  );
};

const ExportIconContainer = styled.div``;

const InformationRowContainer = styled.div`
  padding: 15px 0;
  display: flex;
`;

const InformationIconContainer = styled.div`
  margin-top: 3px;
`;

const InformationTextContainer = styled.div`
  margin-left: 15px;
`;

const ExportLinkContainer = styled(InformationTextContainer)`
  display: flex;
  align-items: center;
`;

const InformationTitle = styled.div`
  font-weight: 500;
`;

const InformationSubtitle = styled.div`
  font-size: 14px;

  p {
    margin: 0;
  }
`;

const InformationContainer = styled.div``;

const StyledCard = styled(Card)`
  &&.MuiCard-root {
    border-radius: 2px;
    width: 648px;

    .MuiCardContent-root {
      padding: 24px;
    }
  }
`;

const StyledCardMedia = styled(CardMedia)`
  height: 240px;
`;

const StyledCardActions = styled(CardActions)`
  && {
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
  }
`;

const CardTitle = styled.div`
  font-size: 14px;
  color: ${ColorDLS.Gray45};

  margin-bottom: 16px;
`;

const ExportLink = styled.a`
  ${linkStyle};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex: 1;
  justify-content: center;
`;
