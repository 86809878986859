// https://share.philips.com/sites/STS20160310160030/Pages/Guidelines/3.0.0/Responsive.aspx
export enum Breakpoint {
  // Mobile like
  XS_MIN = 0,
  XS_MAX = 680,

  // Tablet like
  SM_MIN = 681,
  SM_MAX = 960,

  // Laptop, small desktop, tablet in portrait
  MD_MIN = 961,
  MD_MAX = 1280,

  // Desktop like
  LG_MIN = 1281,
  LG_MAX = 1920,

  // Big desktop like
  XL_MIN = 1921,
  XL_MAX = Infinity,
}

export enum Device {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Laptop = 'laptop',
  Desktop = 'desktop',
  BigDesktop = 'bigDesktop',
}

type DeviceBreakpoint = Record<'min' | 'max', number>;

const Mobile: DeviceBreakpoint = {
  min: Breakpoint.XS_MIN,
  max: Breakpoint.XS_MAX,
};
const Tablet: DeviceBreakpoint = {
  min: Breakpoint.SM_MIN,
  max: Breakpoint.SM_MAX,
};
const Laptop: DeviceBreakpoint = {
  min: Breakpoint.MD_MIN,
  max: Breakpoint.MD_MAX,
};
const Desktop: DeviceBreakpoint = {
  min: Breakpoint.LG_MIN,
  max: Breakpoint.LG_MAX,
};
const BigDesktop: DeviceBreakpoint = {
  min: Breakpoint.XL_MIN,
  max: Breakpoint.XL_MAX,
};

export const BreakpointsByDevice: Record<Device, DeviceBreakpoint> = {
  [Device.Mobile]: Mobile,
  [Device.Tablet]: Tablet,
  [Device.Laptop]: Laptop,
  [Device.Desktop]: Desktop,
  [Device.BigDesktop]: BigDesktop,
};
