/* eslint-disable no-underscore-dangle */
import React from 'react';

import { CourseDetails } from '../../entities/course-details';
import { useCourseByIdQuery } from '../../generated/client';
import { useLocalizedDateFns } from '../../i18n/localized-date-fns';
import { useTranslation } from '../../i18n/translation-provider';

function extractIds(
  arr: { id: string; type: 'Video' | 'Exams' | 'Pdf' }[],
  v: { sys: { id: string | undefined }; __typename?: string } | undefined | null,
): { id: string; type: 'Video' | 'Exams' | 'Pdf' }[] {
  if (v) {
    arr.push({ id: v.sys.id || '', type: v.__typename as 'Video' | 'Exams' | 'Pdf' });
  }
  return arr;
}

export const useGetCourseDetails = (courseId: string) => {
  const { format } = useLocalizedDateFns();
  const [, i18n] = useTranslation();
  const { data, loading, error } = useCourseByIdQuery({ variables: { courseId, locale: i18n.language } });

  return React.useMemo(() => {
    if (loading || error) {
      return { data: undefined, loading, error };
    }

    const course = data?.course;
    const courseDetails: CourseDetails = {
      id: course?.sys.id || '',
      identifier: course?.identifier || '',
      title: course?.title || '',
      description: course?.description || undefined,
      image: course?.thumbnail?.url || '',
      startDate: course?.dateStart ? format(new Date(course.dateStart), 'd MMMM yyyy HH:mm') : null,
      endDate: course?.dateEnd ? format(new Date(course.dateEnd), 'd MMMM yyyy HH:mm') : null,
      flyer: course?.flyer?.url || '',
      level: course?.level || '',
      location: course?.location || '',
      price: course?.price || '',
      examIds: course?.examsCollection?.items.reduce(extractIds, []) || [],
      pdfIds: course?.pdfFileCollection?.items.reduce(extractIds, []) || [],
      videoIds: course?.videoCollection?.items.reduce(extractIds, []) || [],
    };

    return {
      data: courseDetails,
      loading,
      error,
    };
  }, [data, loading, error, format]);
};
