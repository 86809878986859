import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import Markdown from 'react-markdown';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useAppContext } from '../../app-context';
import { ContentfulRichText } from '../../components/contentful-rich-text';
import { DLSIcon } from '../../components/dls-icon/dls-icon';
import { PageContent } from '../../components/layout/content-page';
import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { PageHeaderTitle } from '../../components/layout/page-header-title';
import { Spinner } from '../../components/spinner/spinner';
import { StyledPaper } from '../../components/styled-dls-components';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useTranslation } from '../../i18n/translation-provider';
import { useGetCourseDetails } from '../../services/course/use-get-course-details';
import { ColorDLS } from '../../utils/styling/colors';
import { Mobile } from '../../utils/styling/media-queries';

import { AssetList } from './asset-list/asset-list';

export const ProgramDetailsPage: React.FC<{ previousPage: (id: string) => string }> = observer(({ previousPage }) => {
  const [t] = useTranslation();
  const params: Record<string, string> = useParams();
  const location = useLocation();

  const previousPageUrl = previousPage(params.id);

  const { assetsStore } = useAppContext();

  const details = useGetCourseDetails(params.id);

  const assetIds = useMemo(() => {
    return [...(details.data?.videoIds || []), ...(details.data?.pdfIds || []), ...(details.data?.examIds || [])];
  }, [details.data]);

  useEffect(() => {
    assetsStore.loadAssets(assetIds);
  }, [assetIds, assetsStore, assetsStore.assetsAvailableForUser]);

  useGAPageViewsTracking({ description: 'ProgramDetailsPage', url: location.pathname });

  return (
    <PageContainer data-testid="program-details-page">
      <HeaderBar title={details.data?.title || ''} previousPage={previousPageUrl} />
      <PageContentContainer>
        <ProgramDetailsPageContent>
          <PageHeaderTitle>{t('LOCATION')}</PageHeaderTitle>
          <ProgramLocation date={details.data?.startDate} location={details.data?.location} />
          <PageHeaderTitle>{t('PREPARATION')}</PageHeaderTitle>
          <PaperDescription>
            <ContentfulRichText content={details.data?.description} />
          </PaperDescription>
          <PageHeaderTitle>{t('OVERVIEW')}</PageHeaderTitle>
          <ProgramVideoGrid>
            {assetsStore.isLoading ? <Spinner /> : <AssetList assets={assetsStore.courseAssets} />}
          </ProgramVideoGrid>
        </ProgramDetailsPageContent>
      </PageContentContainer>
    </PageContainer>
  );
});

const ProgramLocation: React.FC<{ date: string | null | undefined; location: string | undefined }> = ({
  date,
  location,
}) => {
  const [t] = useTranslation();

  return (
    <StyledPaper>
      <ProgramLocationIconContainer>
        <DLSIcon name="Location" size={24} color={ColorDLS.Blue45} />
      </ProgramLocationIconContainer>
      <PaperItemInformationContainer>
        <PaperItemTitleText>{date ?? t('SCHEDULE_FOLLOWS')}</PaperItemTitleText>
        <ProgramLocationSubtitle>
          <Markdown source={location || ''} />
        </ProgramLocationSubtitle>
      </PaperItemInformationContainer>
    </StyledPaper>
  );
};

const ProgramVideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  width: 648px;
  margin-top: 16px;
  margin-bottom: 16px;

  ${Mobile} {
    grid-template-columns: 100%;
    width: 100%;
  }
`;

const PageContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ProgramDetailsPageContent = styled(PageContent)`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const PaperDescription = styled(StyledPaper)`
  && {
    color: ${ColorDLS.Gray75};
    padding: 16px 24px;
  }
`;

const ProgramLocationIconContainer = styled.div`
  margin-top: 3px;
`;

const PaperItemInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const PaperItemTitleText = styled.div`
  font-weight: 500;
  color: ${ColorDLS.Gray75};
`;

const ProgramLocationSubtitle = styled.div`
  font: 14px/18px CentraleSans;
  color: ${ColorDLS.Gray45};
  margin-top: 4px;

  // basically we want one-line markdown
  p {
    margin: 0;
  }
`;
