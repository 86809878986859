import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import React, { ReactElement } from 'react';

export const ContentfulRichText = ({ content }: { content?: ContentfulRichTextType }): ReactElement | null => {
  if (!content) {
    return null;
  }

  const options = {
    renderText: (text: string) => {
      return text.replace(/\u00A0/g, ' ');
    },
  };

  return <>{documentToReactComponents(content.json, options)}</>;
};

export type ContentfulRichTextType = { json: Document };

ContentfulRichText.defaultProps = {
  content: undefined,
};
