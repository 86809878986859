import { Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import React from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';

import { useAppContext } from '../../app-context';
import { ContentfulRichText, ContentfulRichTextType } from '../../components/contentful-rich-text';
import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { Spinner } from '../../components/spinner/spinner';
import { createMicrositeEvent } from '../../ga360/createMicrositeEvent';
import { createMicrositeIdFromEmail } from '../../ga360/createMicrositeId';
import { emitMicrositeEvent } from '../../ga360/micrositeEvent';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useNewsItemsCollectionQuery } from '../../generated/client';
import { useLocalizedDateFns } from '../../i18n/localized-date-fns';
import { useTranslation } from '../../i18n/translation-provider';
import { ColorDLS } from '../../utils/styling/colors';
import { linkStyle } from '../../utils/styling/link';
import { Mobile } from '../../utils/styling/media-queries';

type NewsItem = {
  id: string;
  image: string;
  title: string;
  description: ContentfulRichTextType;
  publishedAt: Date;
  link: string;
  sortIndex: number;
};

export const useNewsItemsCollection = (locale: string) => {
  const { data, loading, error } = useNewsItemsCollectionQuery({ variables: { locale } });

  return React.useMemo(() => {
    if (loading || error) {
      return { data: undefined, loading, error };
    }

    return {
      data: data?.newsItemsCollection?.items
        .map(
          (item) =>
            ({
              id: item?.sys?.id,
              publishedAt: new Date(item?.sys?.firstPublishedAt),
              image: item?.thumbnail?.url,
              title: item?.title,
              link: item?.link,
              sortIndex: item?.sortIndex || 0,
              description: item?.summaryText,
            } as NewsItem),
        )
        .sort((a, b) => a.sortIndex - b.sortIndex),
      loading,
      error,
    };
  }, [data, loading, error]);
};

export const NewsPage = () => {
  const [t, i18n] = useTranslation();
  const { data: newsItems, loading } = useNewsItemsCollection(i18n.language);

  useGAPageViewsTracking({ description: 'NewsPage', url: '/news' });

  return (
    <PageContainer data-testid="news-page">
      <HeaderBar title={t('NEWS')} previousPage="/" />
      <PageContent>
        {loading ? (
          <Spinner />
        ) : (
          newsItems?.map((item) => (
            <NewsCard
              key={item.id}
              id={item.id}
              image={item.image}
              title={item.title}
              description={item.description}
              publishedAt={item.publishedAt}
              link={item.link}
            />
          ))
        )}
      </PageContent>
    </PageContainer>
  );
};

const NewsCard: React.FC<Omit<NewsItem, 'sortIndex'>> = ({ id, link, image, title, description, publishedAt }) => {
  const [t] = useTranslation();
  const { formatDistanceToNow } = useLocalizedDateFns();
  const { userInfoStore } = useAppContext();

  const onClick = () => {
    emitMicrositeEvent(
      createMicrositeEvent({
        micrositeID: createMicrositeIdFromEmail(userInfoStore?.userInfo?.username),
        action: title,
        goal: `news-${title}-${id}`,
      }),
    );
  };

  return (
    <StyledCard>
      {image && <StyledCardMedia image={image} />}
      <CardContent>
        <CardTitle>
          <Markdown source={title} />
        </CardTitle>
        <CardDescription>
          <ContentfulRichText content={description} />
        </CardDescription>
      </CardContent>
      <StyledCardActions>
        <CardLink href={link} onClick={onClick} target="top _blank" data-testid={`${id}:news-item-link`}>
          {t('READ_MORE')}
        </CardLink>
        <PostDate data-testid={`${id}:news-item-date`}>
          {formatDistanceToNow(publishedAt, { addSuffix: true })}
        </PostDate>
      </StyledCardActions>
    </StyledCard>
  );
};

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const StyledCard = styled(Card)`
  &&.MuiCard-root {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    width: 648px;
    margin-bottom: 24px;

    .MuiCardContent-root {
      padding: 24px;
    }

    ${Mobile} {
      width: 100%;
    }
  }
`;

const StyledCardMedia = styled(CardMedia)`
  height: 211px;
`;

const StyledCardActions = styled(CardActions)`
  && {
    justify-content: space-between;
    padding: 10px 24px 32px 24px;
  }
`;

const CardTitle = styled.div`
  font: 20px/24px CentraleSans;
  color: ${ColorDLS.Gray75};
  margin-bottom: 12px;
`;

const CardDescription = styled.div`
  p {
    color: ${ColorDLS.Gray75};
    opacity: 0.8;
    hyphens: auto;
  }
`;

const CardLink = styled.a`
  ${linkStyle};
`;

const PostDate = styled.div`
  font: 14px/18px CentraleSans;
  color: ${ColorDLS.Gray45};
`;
