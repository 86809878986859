export enum ColorDLS {
  Aqua05 = '#F0F9FA',
  Aqua30 = '#5CBCB7',
  Aqua35 = '#42ADA1',
  Aqua80 = '#163843',

  Blue05 = '#F0F8FA',
  Blue10 = '#DAF1F7',
  Blue15 = '#BFE2EB',
  Blue20 = '#9DD3E3',
  Blue25 = '#7CC0D9',
  Blue30 = '#5DAFD1',
  Blue35 = '#439AC1',
  Blue40 = '#2B86B2',
  Blue45 = '#1474A4',
  Blue50 = '#176498',
  Blue55 = '#1B578E',
  Blue60 = '#1D4C84',
  Blue65 = '#1C417A',
  Blue70 = '#1A3670',
  Blue75 = '#1C2D66',
  Blue80 = '#1C255C',
  Blue85 = '#191D4F',
  Blue90 = '#0A0C1E',
  BluePhilips = '#0B5ED7',

  Gray05 = '#F5F5F5',
  Gray10 = '#E8E8E8',
  Gray15 = '#D6D6D6',
  Gray20 = '#C4C4C4',
  Gray25 = '#B0B0B0',
  Gray30 = '#9E9E9E',
  Gray35 = '#8C8C8C',
  Gray40 = '#787878',
  Gray45 = '#696969',
  Gray50 = '#595959',
  Gray55 = '#4D4D4D',
  Gray60 = '#454545',
  Gray65 = '#383838',
  Gray70 = '#2B2B2B',
  Gray75 = '#212121',
  Gray80 = '#171717',
  Gray85 = '#0E0E0E',
  Gray90 = '#050505',

  Green45 = '#00BD5E',

  Orange05 = '#FDF5D7',
  Orange40 = '#FF9F19',
  Orange45 = '#FF830F',
  Orange60 = '#E65800',

  Pink05 = '#FBF5F6',
  Pink10 = '#FAE6EA',
  Pink15 = '#F6D0D7',
  Pink20 = '#F2BAC6',
  Pink25 = '#EEA4B5',
  Pink30 = '#EC8BA1',
  Pink35 = '#E8728E',
  Pink40 = '#E85D80',
  Pink45 = '#E04A71',
  Pink50 = '#D13B66',
  Pink55 = '#BE2A5D',
  Pink60 = '#AD1C57',
  Pink65 = '#97144C',
  Pink70 = '#801145',
  Pink75 = '#6B0E3F',
  Pink80 = '#5C0C3C',
  Pink85 = '#4D0A34',
  Pink90 = '#1E0414',

  Purple25 = '#CDA2DC',

  Red45 = '#FA5A4B',

  SignalGreen45 = '#00C764',
  SignalGreen60 = '#00A658',
  SignalRed45 = '#FA5A4B',
}

// AKA extended color keywords, the X11 colors, or the SVG colors.
// https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#colors_table
export enum ColorX11 {
  Transparent = 'transparent',
  // do not use "black" or "white" as values here because it will break the rgba() helper
  Black = '#000000',
  White = '#ffffff',
}

// These grey colors names are based on theirs values of RGB (0-255)
export enum ColorGrey {
  Shade51 = '#333333',
}

export type Color = ColorDLS | ColorX11 | ColorGrey;
