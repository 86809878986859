import React from 'react';

import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { RoutedTab, TabBarWithRouting } from '../../components/layout/tab-bar-with-routing';
import { useTranslation } from '../../i18n/translation-provider';

import { MyCoursesPage } from './my-courses-page';
import { MyProfilePage } from './my-profile-page';

export const MyIcarusPage: React.FC = () => {
  const [t] = useTranslation();
  return (
    <PageContainer data-testid="my-icarus-page" hasTabs={true}>
      <HeaderBar title={t('MY_ICARUS')} previousPage="/" />
      <TabBarWithRouting>
        <RoutedTab title={t('MY_PROFILE')} route="/my-icarus/my-profile">
          <MyProfilePage />
        </RoutedTab>
        <RoutedTab title={t('MY_COURSES')} route="/my-icarus/my-courses">
          <MyCoursesPage />
        </RoutedTab>
      </TabBarWithRouting>
    </PageContainer>
  );
};
