import { Card, CardActionArea, CardContent, CardMedia, LinearProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { ColorDLS } from '../utils/styling/colors';
import { Mobile } from '../utils/styling/media-queries';

import { DLSIcon } from './dls-icon/dls-icon';
import { DLSIconPlay } from './dls-icon/icons/dls-icon-play';

type VideoCardProps = {
  title: string;
  coverImage: string | null;
  progress: number;
  lengthSecond: number;
  hasBeenWatchedFully?: boolean;
  blockedInPreview?: boolean;
  onPlayVideo: () => void;
};

export const VideoCard: React.FC<VideoCardProps> = ({
  coverImage,
  progress,
  lengthSecond,
  onPlayVideo,
  title,
  hasBeenWatchedFully,
  blockedInPreview,
}) => {
  return (
    <StyledCard className={blockedInPreview ? 'blockedInPreview' : ''}>
      <StyledCardActionArea
        disabled={blockedInPreview}
        onClick={onPlayVideo}
        data-testid={`video-${title}-preview-card`}
      >
        <StyledCardMedia image={coverImage || ''} />
        {!blockedInPreview ? (
          <>
            <PlayIcon>
              <DLSIconPlay />
            </PlayIcon>
            <StyledLinearProgress variant="determinate" value={progress} data-testid="video-progress-id" />
          </>
        ) : null}
      </StyledCardActionArea>
      <StyledCardContent>
        <CardContentInner>
          <CardInformationContainer>
            <CardContentTitle>{title}</CardContentTitle>
            <CardContentSubtitle>{lengthSecondToText(lengthSecond)}</CardContentSubtitle>
          </CardInformationContainer>
          <CardIconContainer>
            <IconCheck>
              {hasBeenWatchedFully && <DLSIcon name="Checkmark" size={24} color={ColorDLS.Green45} />}
            </IconCheck>
          </CardIconContainer>
        </CardContentInner>
      </StyledCardContent>
    </StyledCard>
  );
};

const lengthSecondToText = (lengthSecond: number) => {
  const min = Math.floor(lengthSecond / 60);
  const sec = Math.floor(lengthSecond % 60);
  return `${min} min, ${sec} sec`;
};
const StyledCard = styled(Card)`
  &&.MuiCard-root {
    border-radius: 2px;
    background: white;
    &.blockedInPreview {
      opacity: 0.5;
    }

    .MuiCardActions-root {
      padding: 16px;
    }
  }
`;

const StyledCardActionArea = styled(CardActionArea)`
  &&& {
    color: white;
    position: relative;
  }
`;

const CardContentInner = styled.div`
  display: flex;
  flex: 1;
`;

const CardInformationContainer = styled.div`
  flex: 1 1 auto;
`;

const StyledCardContent = styled(CardContent)`
  &&& {
    padding: 11px 16px 14px 21px;
  }
`;

const StyledCardMedia = styled(CardMedia)`
  height: 211px;
`;

const CardIconContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
`;

const CardContentTitle = styled.div`
  font-weight: 500;
  // basically one line text with ...
  width: 233px;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${Mobile} {
    width: 100%;
  }
`;

const CardContentSubtitle = styled.div`
  font: 14px/18px CentraleSans;
  color: ${ColorDLS.Gray45};
`;

const IconCheck = styled.div`
  padding: 12px;
`;

const PlayIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 48px;
  width: 48px;
  border-radius: 48px;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const StyledLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-colorPrimary {
    background-color: rgba(20, 116, 164, 0.25);
    .MuiLinearProgress-barColorPrimary {
      background-color: ${ColorDLS.Blue45};
    }
  }
`;
