import { AppBarItem, Menu, MenuItem, Label, NavigationTheme, AppBar } from '@dls/react-core';
import { Icon } from '@dls/react-icon';
import React, { MouseEventHandler, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from '../../i18n/translation-provider';
import { ColorDLS } from '../../utils/styling/colors';
import { DLSIcon } from '../dls-icon/dls-icon';

const UserProfileItem = styled(AppBarItem)`
  &&& {
    flex: 0;
    margin-left: auto;
    padding: 0;
  }
`;

export const HeaderBar: React.FC<{ title: string; previousPage?: string; hideProfileIcon?: boolean }> = ({
  title,
  previousPage,
  hideProfileIcon,
}) => {
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const history = useHistory();

  const goToMyProfile = useCallback(() => {
    setAnchorEl(null);
    history.push('/my-icarus/my-profile');
  }, [setAnchorEl, history]);

  const logout = () => {
    setAnchorEl(null);
    window.location.assign('/user/logout');
  };

  const handleClick: MouseEventHandler<Element> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderContainer>
      <NavigationTheme>
        <StyledNavigationBar data-testid="page-title-bar">
          {previousPage ? (
            <BackButton to={previousPage} data-testid="previous-page-button">
              <DLSIcon color="white" name="NavigationLeft" size={24} />
            </BackButton>
          ) : (
            <div />
          )}

          <Title data-testid="page-title">{title}</Title>
          {!hideProfileIcon && (
            <UserProfileItem
              onClick={handleClick}
              data-testid="profile-menu-button"
              role="button"
              aria-label="Profile menu"
            >
              <Icon name="PersonPortrait" />
            </UserProfileItem>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              className: 'DLSMenuList',
            }}
          >
            <MenuItem onClick={goToMyProfile} data-testid="profile-menuitem-myprofile">
              <StyledLabel label_variant="value">{t('MY_PROFILE')}</StyledLabel>
            </MenuItem>
            <MenuItem onClick={logout} data-testid="profile-menuitem-logout">
              <StyledLabel label_variant="value">Logout</StyledLabel>
            </MenuItem>
          </Menu>
        </StyledNavigationBar>
      </NavigationTheme>
    </HeaderContainer>
  );
};

const BackButton = styled(Link)`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 64px;
  position: fixed;
  z-index: 10;
`;

const StyledNavigationBar = styled(AppBar)`
  && {
    font-size: 20px;

    background: ${ColorDLS.Blue40};
    padding: 0 24px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;

    color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 6px 0;
    height: 64px;
    min-height: 64px;
    position: fixed;
    width: 100%;
  }
`;

const Title = styled.div`
  flex: 1;
  font-size: 20px;

  text-align: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
`;

const StyledLabel = styled(Label)`
  && {
    color: ${ColorDLS.Gray75} !important;
    opacity: 0.8;
  }
`;
