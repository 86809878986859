import { Snackbar, Button, IconButton, makeStyles } from '@material-ui/core';
import React, { FC, useEffect } from 'react';

import * as serviceWorker from '../../serviceWorkerRegistration';
import { DLSIcon } from '../dls-icon/dls-icon';

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);
  const classes = useStyles();

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  const dismiss = () => {
    setShowReload(false);
  };

  return (
    <Snackbar
      open={showReload}
      message="An update for this application is available"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <>
          <Button color="inherit" size="medium" onClick={reloadPage}>
            Reload
          </Button>
          <IconButton
            aria-label="close"
            className={classes.close}
            onClick={dismiss}
            data-testid="sw-update-close-button"
          >
            <DLSIcon name="Cross" size={24} />
          </IconButton>
        </>
      }
      onClose={dismiss}
    />
  );
};

export default ServiceWorkerWrapper;
