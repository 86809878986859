import { TableCell } from '@dls/react-core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { Mobile } from '../../utils/styling/media-queries';

export const MenuTableCell = styled(TableCell)`
  &&&.MuiTableCell-root {
    width: 64px;
    padding: 0;
  }
`;

export const AdminPageContainerInner = styled.div`
  padding: 24px;
`;

export const AdminPageContent = styled.div`
  ${Mobile} {
    width: 100%;
  }
`;

export const useAdminPageStyles = makeStyles({
  root: {
    width: '100%',
    background: 'white',
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkbox: {
    display: 'flex',
  },
});
