import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';

import { useAppContext } from '../app-context';
import { getCurrentCookieNoticeScriptLanguage } from '../ga360/getCurrentCookieNoticeScriptLanguage';
import { reloadGAScripts } from '../ga360/reloadGAScripts';
import { LanguageCode } from '../i18n/language-code';
import { useTranslation } from '../i18n/translation-provider';

import { AdminPage } from './admin-page/admin-page';
import { CourseDetailsPage } from './course-details-page/course-details-page';
import { CoursesPage } from './courses-page/courses-page';
import { DashboardPage } from './dashboard-page/dashboard-page';
import { DevelopmentsPage } from './developments-page/developments-page';
import { DeleteAccountPage } from './my-icarus-page/delete-account-page';
import { MyIcarusPage } from './my-icarus-page/my-icarus-page';
import { ProgramDetailsPage } from './my-icarus-page/program-details-page';
import { NewsPage } from './news-page/news-page';
import { SkillsPage } from './skills-page/skills-page';

export const AuthenticatedContainer: React.FC = () => {
  const { assetsStore, userInfoStore } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const [, i18n] = useTranslation();

  useEffect(() => {
    const langCode = global.localStorage.getItem('language');
    const currentLanguageCookieNotice = getCurrentCookieNoticeScriptLanguage();
    if (!langCode) {
      localStorage.setItem('language', i18n.language);
      if (currentLanguageCookieNotice !== i18n.language) {
        reloadGAScripts(i18n.language as LanguageCode);
      }
    } else if (langCode !== i18n.language) {
      i18n.changeLanguage(langCode);
      if (currentLanguageCookieNotice !== langCode) {
        reloadGAScripts(langCode as LanguageCode);
      }
    } else if (langCode !== currentLanguageCookieNotice) {
      reloadGAScripts(langCode as LanguageCode);
    }
  }, [i18n]);

  useEffect(() => {
    if (assetsStore) assetsStore.loadAvailableAssets();
  }, [assetsStore]);

  const checkForAdmin = useCallback(() => {
    if (
      userInfoStore.userInfo?.roles &&
      userInfoStore.userInfo?.roles.includes('ADMIN') &&
      !location.pathname?.includes('/admin')
    ) {
      history.push('/admin');
    }
  }, [userInfoStore.userInfo, history, location.pathname]);

  useEffect(() => {
    if (!userInfoStore.userInfo) {
      userInfoStore.updateUserInfo().then(() => {
        checkForAdmin();
      });
    } else {
      checkForAdmin();
    }
  }, [userInfoStore, checkForAdmin]);

  return (
    <Switch>
      <Route path="/" exact>
        <DashboardPage />
      </Route>
      <Route path="/admin" exact>
        <AdminPage />
      </Route>
      <Route path={['/my-icarus', '/my-icarus/my-courses', '/my-icarus/my-profile']} exact>
        <MyIcarusPage />
      </Route>
      <Route path="/my-icarus/my-courses/:id" exact>
        <ProgramDetailsPage previousPage={() => '/my-icarus/my-courses'} />
      </Route>
      <Route path="/courses" exact>
        <CoursesPage />
      </Route>
      <Route path="/courses/:id" exact>
        <CourseDetailsPage />
      </Route>
      <Route path="/courses/preview/:id" exact>
        <ProgramDetailsPage previousPage={(id) => `/courses/${id}`} />
      </Route>
      <Route path="/developments">
        <DevelopmentsPage />
      </Route>
      <Route path="/delete-account">
        <DeleteAccountPage />
      </Route>
      <Route path={['/skills']}>
        <SkillsPage />
      </Route>
      <Route path="/news">
        <NewsPage />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
