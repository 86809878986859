import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { useAppContext } from '../../../app-context';
import { IFrameDialog } from '../../../components/iframe-dialog/iframe-dialog';
import { VideoItem, VideoPlayer, VideoState } from '../../../components/video-player';
import { FullExam } from '../../../entities/backend/full-exam';
import { FullPdf } from '../../../entities/backend/full-pdf';
import { FullVideo } from '../../../entities/backend/full-video';
import { createMicrositeEvent } from '../../../ga360/createMicrositeEvent';
import { createMicrositeIdFromEmail } from '../../../ga360/createMicrositeId';
import { emitMicrositeEvent } from '../../../ga360/micrositeEvent';

import { AssetComponentExam } from './asset-component-exam';
import { AssetComponentPdf } from './asset-component-pdf';
import { AssetComponentVideo } from './asset-component-video';
import { AssetComponentProps } from './asset-list-types';

const assetTypeToAssetComponentMap: Record<string, React.FC<AssetComponentProps>> = {
  Video: AssetComponentVideo,
  Pdf: AssetComponentPdf,
  Exam: AssetComponentExam,
};

const AssetComponent: React.FC<{
  asset: FullVideo | FullExam | FullPdf;
  isOpened: boolean;
  handleAssetOpen: (assetId: string, assetName: string) => void;
  handlePlayVideo: (video: VideoItem) => void;
  progressPercentage?: number;
  hasBeenWatchedFully?: boolean;
  currentVideo: VideoItem | null;
  onClose: (video: VideoItem | null) => void;
  onUpdateVideoState?: (videoState: VideoState) => void;
  onUpdateDuration?: (duration: number) => void;
  setCurrentExam: (exam: FullExam | null) => void;
}> = ({
  asset,
  isOpened,
  handlePlayVideo,
  handleAssetOpen,
  progressPercentage,
  hasBeenWatchedFully,
  currentVideo,
  setCurrentExam,
  onClose,
  onUpdateDuration,
  onUpdateVideoState,
}) => {
  const renderAssetComponent = assetTypeToAssetComponentMap[asset.type];
  const assetWithProgress: FullPdf | FullVideo | FullExam = {
    ...asset,
    progress: progressPercentage || 0,
    hasBeenWatchedFully: !!hasBeenWatchedFully,
  };

  return (
    <>
      {renderAssetComponent &&
        renderAssetComponent({
          asset: assetWithProgress,
          isOpened,
          handlePlayVideo,
          handleAssetOpen,
          currentVideo,
          onClose,
          setCurrentExam,
          onUpdateDuration,
          onUpdateVideoState,
        })}
    </>
  );
};

type SavedAssetState = {
  playedSeconds: number;
  duration: number;
  hasBeenWatchedFully: boolean;
  isOpened: boolean;
};

const defaultAssetState: SavedAssetState = {
  duration: 0,
  playedSeconds: 0,
  hasBeenWatchedFully: false,
  isOpened: false,
};

const SECONDS_BEFORE_END_OF_VIDEO_TO_BE_REGARDED_AS_FULLY_WATCHED_PERCENTAGE = 0.9;
export const AssetList: React.FC<{ assets: Array<FullPdf | FullVideo | FullExam | undefined> }> = ({ assets }) => {
  const { userInfoStore } = useAppContext();
  const [currentVideo, setCurrentVideo] = React.useState<VideoItem | null>(null);
  const [currentExam, setCurrentExam] = React.useState<FullExam | null>(null);
  const [savedAssetStateRecord, setSavedAssetStateRecord] =
    useLocalStorage<Record<string, SavedAssetState>>('program-details-asset-state');

  const [currentVideoProgressPercentage, setCurrentVideoProgressPercentage] = useState<
    Record<string, { progressPercentage: number; hasBeenFullyWatched: boolean }>
  >({});

  const videoHasBeenWatchedFully = useCallback(
    (assetId: string): boolean => {
      return (savedAssetStateRecord && savedAssetStateRecord[assetId]?.hasBeenWatchedFully) || false;
    },
    [savedAssetStateRecord],
  );

  const progressState = useMemo(() => {
    const playedSeconds =
      savedAssetStateRecord && currentVideo ? savedAssetStateRecord[currentVideo.id]?.playedSeconds || 0 : 0;
    const duration = savedAssetStateRecord && currentVideo ? savedAssetStateRecord[currentVideo.id]?.duration || 0 : 0;
    const hasBeenWatchedFully = currentVideo ? videoHasBeenWatchedFully(currentVideo.id) : false;
    return { playedSeconds, duration, hasBeenWatchedFully };
  }, [currentVideo, savedAssetStateRecord, videoHasBeenWatchedFully]);

  const getProgressPercentage = (id: string) => {
    return savedAssetStateRecord && savedAssetStateRecord[id]?.duration
      ? (savedAssetStateRecord[id]?.playedSeconds / savedAssetStateRecord[id]?.duration) * 100
      : 0;
  };

  // We leave getProgressPercentage out of the array as we don't want to run this every time the progress is written
  // (and thus getProgressPercentage changes) as it will rerender and restart the video
  /* eslint-disable  react-hooks/exhaustive-deps */
  useEffect(() => {
    if (assets) {
      const assetMap: Record<string, { progressPercentage: number; hasBeenFullyWatched: boolean }> = {};
      assets.forEach((a) => {
        if (a) {
          assetMap[a.sys.id] = {
            progressPercentage: getProgressPercentage(a.sys.id),
            hasBeenFullyWatched: videoHasBeenWatchedFully(a.sys.id),
          };
        }
      });
      setCurrentVideoProgressPercentage(assetMap);
    }
  }, [assets, setCurrentVideoProgressPercentage]);
  /* eslint-enable  react-hooks/exhaustive-deps */

  function calculateVideoProgress(video: VideoItem) {
    return (prevState: Record<string, { progressPercentage: number; hasBeenFullyWatched: boolean }>) => ({
      ...prevState,
      [video.id]: {
        progressPercentage: getProgressPercentage(video.id),
        hasBeenFullyWatched: videoHasBeenWatchedFully(video.id),
      },
    });
  }

  const handlePlayVideo = (video: VideoItem) => {
    const { id, title } = video;
    emitMicrositeEvent(
      createMicrositeEvent({
        micrositeID: createMicrositeIdFromEmail(userInfoStore?.userInfo?.username),
        action: `course-watching-${title}`,
        goal: `course-watching-${id}-${title}`,
      }),
    );
    setCurrentVideo(video);
    setCurrentVideoProgressPercentage(calculateVideoProgress(video));
  };

  const handleVideoClose = (video: VideoItem | null) => {
    if (video) {
      setCurrentVideoProgressPercentage(calculateVideoProgress(video));
      setCurrentVideo(null);
    }
  };

  const setExam = (exam: FullExam | null) => {
    setCurrentExam(exam);
    if (exam) {
      emitMicrositeEvent(
        createMicrositeEvent({
          micrositeID: createMicrositeIdFromEmail(userInfoStore?.userInfo?.username),
          action: `exam-${exam.name}`,
          goal: `exam-${exam.name}-${exam.examUrl || ''}`,
        }),
      );
    }
  };

  const saveVideoState = React.useCallback(
    (video: VideoItem, videoState: VideoState) => {
      if (videoState.playedSeconds !== 0) {
        const videoWatchedPercentage = videoState.playedSeconds / video.lengthSecond;
        const hasBeenWatchedFully =
          (savedAssetStateRecord && savedAssetStateRecord[video.id]?.hasBeenWatchedFully) ||
          videoWatchedPercentage >= SECONDS_BEFORE_END_OF_VIDEO_TO_BE_REGARDED_AS_FULLY_WATCHED_PERCENTAGE;

        setSavedAssetStateRecord({
          ...savedAssetStateRecord,
          [video.id]: {
            ...defaultAssetState,
            ...((savedAssetStateRecord && savedAssetStateRecord[video.id]) || {}),
            isOpened: true,
            playedSeconds: videoState.playedSeconds,
            hasBeenWatchedFully,
          },
        });
      }
    },
    [savedAssetStateRecord, setSavedAssetStateRecord],
  );

  const saveDuration = React.useCallback(
    (video: VideoItem, duration: number) => {
      setSavedAssetStateRecord({
        ...savedAssetStateRecord,
        [video.id]: {
          ...defaultAssetState,
          ...((savedAssetStateRecord && savedAssetStateRecord[video.id]) || {}),
          isOpened: true,
          duration,
        },
      });
    },
    [savedAssetStateRecord, setSavedAssetStateRecord],
  );

  const handleAssetOpen = React.useCallback(
    (assetId: string, assetName: string) => {
      setSavedAssetStateRecord({
        ...savedAssetStateRecord,
        [assetId]: {
          ...defaultAssetState,
          ...((savedAssetStateRecord && savedAssetStateRecord[assetId]) || {}),
          isOpened: true,
        },
      });
      emitMicrositeEvent(
        createMicrositeEvent({
          micrositeID: createMicrositeIdFromEmail(userInfoStore?.userInfo?.username),
          action: `asset-${assetId}`,
          goal: `asset-${assetId}-${assetName}`,
        }),
      );
    },
    [savedAssetStateRecord, setSavedAssetStateRecord, userInfoStore],
  );

  const getAssetState = React.useCallback(
    (assetId: string) => {
      return (savedAssetStateRecord && savedAssetStateRecord[assetId]) || defaultAssetState;
    },
    [savedAssetStateRecord],
  );

  return (
    <>
      {currentVideo && (
        <VideoPlayer
          data-testid={`video-${currentVideo.id}-video-player`}
          onClose={() => handleVideoClose(currentVideo)}
          videoUrl={currentVideo.videoUrl}
          progress={progressState.playedSeconds}
          title={currentVideo.title}
          onUpdateVideoState={(videoState: VideoState) => saveVideoState(currentVideo, videoState)}
          onUpdateDuration={(duration) => saveDuration(currentVideo, duration)}
        />
      )}

      {currentExam && (
        <IFrameDialog title={currentExam.name} url={currentExam.examUrl} onClose={() => setCurrentExam(null)} />
      )}
      {assets.map(
        (asset) =>
          asset && (
            <AssetComponent
              key={asset.sys.id}
              asset={asset}
              handlePlayVideo={handlePlayVideo}
              progressPercentage={currentVideoProgressPercentage[asset.sys.id]?.progressPercentage}
              hasBeenWatchedFully={currentVideoProgressPercentage[asset.sys.id]?.hasBeenFullyWatched}
              currentVideo={currentVideo}
              setCurrentExam={setExam}
              onClose={handleVideoClose}
              onUpdateVideoState={(videoState: VideoState) => {
                if (currentVideo) saveVideoState(currentVideo, videoState);
              }}
              onUpdateDuration={(duration) => {
                if (currentVideo) saveDuration(currentVideo, duration);
              }}
              handleAssetOpen={handleAssetOpen}
              isOpened={getAssetState(asset.sys.id).isOpened}
            />
          ),
      )}
    </>
  );
};
