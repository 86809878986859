import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { ColorDLS } from '../../utils/styling/colors';

import { AdminBooking } from './admin-booking';
import { BookingState } from './booking-state';

const StatusDateFormat = 'D MMM YYYY';

const bookingStateColor = Object.freeze({
  [BookingState.Subscribed]: ColorDLS.Green45,
  [BookingState.Rejected]: ColorDLS.Gray15,
  [BookingState.Requested]: '#FF9514',
  [BookingState.Revoked]: ColorDLS.Gray15,
});

export const SubscriptionStatusIndicator = styled.span<{ status: BookingState }>`
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  width: 10px;
  height: 10px;
  background-color: ${(props) => bookingStateColor[props.status]};
`;

export const SubscriptionStatus = ({ booking }: { booking: AdminBooking }) => {
  switch (booking.state) {
    case BookingState.Subscribed:
      return (
        <div>
          <SubscriptionStatusIndicator status={booking.state} />{' '}
          {booking.expireDate ? `Expires on ${moment(booking.expireDate).format(StatusDateFormat)}` : 'No expiry'}
        </div>
      );
    case BookingState.Rejected:
      return (
        <div>
          <SubscriptionStatusIndicator status={booking.state} />{' '}
          {`Rejected on ${moment(booking.lastUpdate).format(StatusDateFormat)}`}
        </div>
      );
    case BookingState.Revoked:
      /* TODO: Missing case for expired date. */
      return (
        <div>
          <SubscriptionStatusIndicator status={booking.state} />{' '}
          {`Revoked on ${moment(booking.lastUpdate).format(StatusDateFormat)}`}
        </div>
      );
    case BookingState.Requested:
      return (
        <div>
          <SubscriptionStatusIndicator status={booking.state} />{' '}
          {`Requested on ${moment(booking.requestDate).format(StatusDateFormat)}`}
        </div>
      );
    default:
      throw new Error(booking.state as never);
  }
};
