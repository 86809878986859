import React from 'react';

export const DLSIconThreeDotsVertical: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg viewBox="0 0 12 24" width="100%" height="100%">
      <circle fill={fill} cx={6} cy={6} r={2} />
      <circle fill={fill} cx={6} cy={12} r={2} />
      <circle fill={fill} cx={6} cy={18} r={2} />
    </svg>
  );
};
