import { Button, List, Typography } from '@dls/react-core';
import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DLSIcon } from '../../components/dls-icon/dls-icon';
import { PageContent } from '../../components/layout/content-page';
import { PageHeaderTitle } from '../../components/layout/page-header-title';
import { NoCoursesIcon } from '../../components/no-courses-icon';
import { Spinner } from '../../components/spinner/spinner';
import { Booking } from '../../entities/profile/bookingData';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { CourseCollectionQuery, useCourseCollectionQuery } from '../../generated/client';
import { TranslateFunction, useTranslation } from '../../i18n/translation-provider';
import { useBookings } from '../../services/bookings/use-bookings';
import { ColorDLS } from '../../utils/styling/colors';
import { BookingState, DerivedBookingState, FrontendBookingState } from '../admin-page/booking-state';

const getLatestBookingForEachCourse = (bookings: Booking[]) => {
  const resultArray: Booking[] = [];
  const sortedBookings = bookings.sort((a, b) => b.lastUpdate.valueOf() - a.lastUpdate.valueOf());

  sortedBookings.forEach((booking) => {
    const existingBooking = resultArray.find((result) => result.courseId === booking.courseId);
    if (!existingBooking) {
      resultArray.push(booking);
    }
  });

  return resultArray;
};

export const MyCoursesPage: React.FC = () => {
  const [t, i18n] = useTranslation();
  const { bookedCourses, isLoading: isBookingsLoading } = useBookings();
  const { data: courses, loading: isGraphqlLoading } = useCourseCollectionQuery({
    variables: { locale: i18n.language },
  });

  useGAPageViewsTracking({ description: 'my-courses', url: '/my-icarus/my-courses' });

  const uniqueBookedCourses: Booking[] = useMemo(
    () => getLatestBookingForEachCourse(bookedCourses).filter((booking) => booking.courseId !== 'Effigos'),
    [bookedCourses],
  );

  return (
    <PageContainer>
      <PageContent>
        {isBookingsLoading || isGraphqlLoading ? (
          <Spinner />
        ) : (
          <>
            <PageHeaderTitle>{t('MY_COURSES_TITLE')}</PageHeaderTitle>
            {courses && uniqueBookedCourses.length > 0 ? (
              <>
                <CourseList bookings={uniqueBookedCourses} allCourses={courses} />
              </>
            ) : (
              <EmptyCourseList />
            )}
          </>
        )}
      </PageContent>
    </PageContainer>
  );
};

const EmptyCourseList: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <EmptyContainer>
      <StyledNoCoursesImg>
        <NoCoursesIcon />
      </StyledNoCoursesImg>
      <Typography variant="h3" style={{ color: '#1474A4' }}>
        {t('NO_COURSES_TITLE')}{' '}
      </Typography>
      <Typography>{t('NO_COURSES_NOT_SUBSCRIBED')}</Typography>
      <Typography>{t('NO_COURSES_BROWSE_COURSES')}</Typography>
      <StyledBrowseButton
        dls_variant="primary"
        onClick={() => history.push(`/courses`)}
        data-testid="browse-courses-button"
      >
        {t('NO_COURSES_BROWSE_BUTTON')}
      </StyledBrowseButton>
    </EmptyContainer>
  );
};

const CourseList: React.FC<{ bookings: Booking[]; allCourses: CourseCollectionQuery }> = ({ bookings, allCourses }) => {
  return (
    <ListContainer>
      <List>
        {bookings.map((p) => {
          const course = allCourses.courseCollection?.items.find((c) => c?.identifier === p.courseId);
          const bookingStatus: DerivedBookingState =
            p.expireDate && p.expireDate < new Date() ? FrontendBookingState.Expired : p.state;
          if (course !== null && course !== undefined) {
            return (
              <ProgramItemContainer key={course.sys.id}>
                <ProgramItem
                  id={course.sys.id}
                  title={course.title as string}
                  image={course.thumbnail?.url as string}
                  status={bookingStatus}
                />
              </ProgramItemContainer>
            );
          }
          return null;
        })}
      </List>
    </ListContainer>
  );
};

function convertStatusToTranslation(status: DerivedBookingState, translations: TranslateFunction): string {
  switch (status) {
    case BookingState.Subscribed:
      return translations('STATUS_BOOKED');
    case BookingState.Requested:
      return translations('STATUS_REQUESTED');
    case BookingState.Revoked:
      return translations('STATUS_REVOKED');
    case BookingState.Rejected:
      return translations('STATUS_REJECTED');
    case FrontendBookingState.Expired:
      return translations('STATUS_EXPIRED');
    default:
      return status;
  }
}

const ProgramItem: React.FC<{ id: string; title: string; image: string; status: DerivedBookingState }> = ({
  id,
  title,
  image,
  status,
}) => {
  const history = useHistory();
  const [t] = useTranslation();

  return (
    <ListItem button onClick={() => history.push(`/my-icarus/my-courses/${id}`)} data-testid={`program-item-${id}`}>
      <ListItemImage src={image} />
      <ListItemText
        primary={title}
        secondary={<ProgramItemSubtitle>{convertStatusToTranslation(status, t)}</ProgramItemSubtitle>}
      />
      <StyledListItemSecondaryAction>
        <NavigationRightIcon name="NavigationRight" size={24} color={ColorDLS.Gray75} />
      </StyledListItemSecondaryAction>
    </ListItem>
  );
};

const EmptyContainer = styled.div`
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBrowseButton = styled(Button)`
  margin: 24px;
`;

const StyledNoCoursesImg = styled.div`
  margin: 24px;
`;

const NavigationRightIcon = styled(DLSIcon)`
  &,
  svg {
    opacity: 0.7;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ListItemImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 20px;
  object-fit: cover;
`;

const ListContainer = styled.div`
  && > ul.MuiList-root {
    padding: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  && > ul > div {
    background-color: white;
  }

  &&& .MuiListItem-root {
    padding: 16px 24px;

    .MuiListItemText-primary {
      font-weight: bold;
      color: ${ColorDLS.Gray75};
    }

    .MuiListItemText-secondary {
      font: 12px/16px CentraleSans;
      color: ${ColorDLS.Gray75};
      opacity: 0.7;
      margin-top: 3px;
    }
  }
`;

const ProgramItemSubtitle = styled.span`
  font: 12px/16px CentraleSans;
  color: ${ColorDLS.Gray75};
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  cursor: pointer;
`;

const ProgramItemContainer = styled.div`
  box-shadow: inset 0 -1px 0 0 rgba(33, 33, 33, 0.1);

  &:first-child {
    border-radius: 2px 2px 0 0;
  }

  &:last-child {
    border-radius: 0 0 2px 2px;
    box-shadow: none;
  }
`;
