import { ButtonBase } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { ColorDLS } from '../utils/styling/colors';
import { Mobile } from '../utils/styling/media-queries';

import { DLSIcon, IconName } from './dls-icon/dls-icon';
import { StyledPaper } from './styled-dls-components';

export const ClickablePaperItem: React.FC<{
  disabled?: boolean;
  icon: IconName;
  label: string;
  onClick: () => void;
  isOpened?: boolean;
}> = ({ icon, label, onClick, disabled, isOpened = false }) => {
  return (
    <FlexButtonBase disabled={disabled} onClick={onClick} data-testid={`clickable-paper-item-${label}`}>
      <StyledPaper>
        <IconContainer>
          <DLSIcon name={icon} size={24} color={ColorDLS.Blue45} />
        </IconContainer>
        <InformationContainer>
          <Title>{label}</Title>
          <ActionsArea>
            {isOpened && <DLSIcon name="Checkmark" size={24} color={ColorDLS.Green45} />}
            {!disabled && <NavigationRightIcon name="NavigationRight" size={24} color={ColorDLS.Gray75} />}
          </ActionsArea>
        </InformationContainer>
      </StyledPaper>
    </FlexButtonBase>
  );
};

const NavigationRightIcon = styled(DLSIcon)`
  &,
  svg {
    opacity: 0.7;
  }
`;

const ActionsArea = styled.div`
  display: flex;

  & > * {
    margin-left: 10px;
  }
`;

const InformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 15px;
`;

const IconContainer = styled.div``;

const FlexButtonBase = styled(ButtonBase)`
  && {
    &:disabled {
      opacity: 0.5;
    }

    grid-column: 1 / span 2;
    display: block;
    flex: 1;
    width: 100%;

    ${Mobile} {
      grid-column: 1;
    }
  }
`;

const Title = styled.div`
  font-weight: 500;
  color: ${ColorDLS.Gray75};
`;
