import { CheckmarkCircle, Cross16, WarningISO } from '@dls/react-icons';
import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { NotificationWithId } from './notification';
import { NotificationContext, NotificationContextType } from './notification-context';

const id = (i: string) => `Notification-${i}`;

enum NotificationID {
  WRAPPER = 'Wrapper',
  CLOSE = 'Close',
  TITLE = 'Title',
  BODY = 'Body',
}

export enum HorizontalNotificationPosition {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface NotificationProviderProps {
  context: NotificationContextType;
  children: React.ReactNode;
  defaultOptions?: NotificationProviderOptions;
  notifications: NotificationWithId[];
  setNotifications: (value: ((prevState: NotificationWithId[]) => NotificationWithId[]) | NotificationWithId[]) => void;
}

export interface NotificationProviderOptions {
  fullWidth?: boolean;
  containerMargin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  width?: number;
  horizontalPosition?: HorizontalNotificationPosition;
}

export interface NotificationProviderFilledOptions {
  fullWidth: boolean;
  containerMargin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  width: number;
  horizontalPosition: HorizontalNotificationPosition;
}

const notificationProviderDefaultOptions = {
  fullWidth: true,
  containerMargin: {
    top: 24,
    right: 24,
    bottom: 24,
    left: 24,
  },
  width: 500,
  horizontalPosition: HorizontalNotificationPosition.Center,
};

export const NotificationProvider = (props: NotificationProviderProps) => {
  const options: NotificationProviderFilledOptions = {
    ...notificationProviderDefaultOptions,
    ...props.defaultOptions,
    containerMargin: {
      ...notificationProviderDefaultOptions.containerMargin,
      ...(props.defaultOptions?.containerMargin || {}),
    },
  };

  return (
    <NotificationContext.Provider value={props.context}>
      {props.children}

      {createPortal(
        <StyledNotificationContainer margin={options.containerMargin}>
          {props.notifications.map((n) => (
            <NotificationToast key={`${n.title}${n.body}${(n.date as Date).toISOString()}`} options={options}>
              <NotificationLine>
                <NotificationIconAndTitle>
                  {n.error ? <IndicatorIcon /> : <OKIcon />}
                  <NotificationTitle className={id(NotificationID.TITLE)} error={n.error}>
                    {n.title}
                  </NotificationTitle>
                </NotificationIconAndTitle>
                <NotificationBody className={id(NotificationID.BODY)}>{n.body}</NotificationBody>
              </NotificationLine>
              <NotificationCross>
                <CloseButton
                  id={id(NotificationID.CLOSE)}
                  data-cy={id(NotificationID.CLOSE)}
                  onClick={() => props.context.clearNotification(n)}
                >
                  <Cross16 />
                </CloseButton>
              </NotificationCross>
            </NotificationToast>
          ))}
        </StyledNotificationContainer>,
        document.body,
      )}
    </NotificationContext.Provider>
  );
};

const StyledNotificationContainer = styled.div<{
  margin: { top: number; right: number; left: number; bottom: number };
}>`
  position: fixed;
  top: ${(props) => props.margin.top}px;
  left: ${(props) => props.margin.left}px;
  right: ${(props) => props.margin.right}px;
  height: calc(100vh - ${(props) => props.margin.top + props.margin.bottom}px);

  @media (max-width: 680px) {
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
  }
  z-index: 10000;
  pointer-events: none;
`;

const NotificationToast = styled.div<{ options: NotificationProviderFilledOptions }>`
  position: relative;
  display: block;
  margin-bottom: 0.75em;
  overflow: hidden;
  z-index: 1000;
  pointer-events: auto;

  width: ${(props) => (props.options.fullWidth ? '100%' : `${props.options.width}px`)};

  @media (max-width: 680px) {
    width: calc(
      100% - ${(props) => props.options.containerMargin.left}px - ${(props) => props.options.containerMargin.right}px
    );
    left: ${(props) => props.options.containerMargin.left}px;
    right: ${(props) => props.options.containerMargin.right}px;
    top: ${(props) => props.options.containerMargin.top}px;
    bottom: ${(props) => props.options.containerMargin.bottom}px;
  }

  left: ${(props) => {
    if (props.options.fullWidth) return 0;

    let marginFromRight: string;
    switch (props.options.horizontalPosition) {
      case 'right':
        marginFromRight = `${
          props.options.containerMargin.left + props.options.containerMargin.right + props.options.width
        }px`;
        break;
      case 'center':
        marginFromRight = `50vw + ${props.options.width * 0.5 + props.options.containerMargin.right}px`;
        break;
      default:
        marginFromRight = '100vw';
    }
    return `calc(100vw - (${marginFromRight}))`;
  }};
  max-width: 100%;
  border-radius: 2px;

  background: white;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  border: none;
  background: transparent;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 16px;
`;

const IndicatorIcon = styled(WarningISO)`
  color: #e65800;
  fill: #e65800;
  font-size: 24px;
  margin-right: 8px;
  line-height: 24px;
  vertical-align: inherit;
`;

const NotificationIconAndTitle = styled.span`
  margin-top: 5px;
  vertical-align: middle;
`;

const OKIcon = styled(CheckmarkCircle)`
  color: #00a658;
  fill: #00a658;
  font-size: 24px;
  margin-right: 8px;
  line-height: 24px;
  vertical-align: inherit;
`;

const NotificationLine = styled.div`
  margin-top: -5px;
  padding: 18px 36px 18px 16px;
  display: flex;
  align-items: center;
`;

const NotificationTitle = styled.span<{ error?: boolean }>`
  color: #212121;
  font-size: 14px;
  font-weight: bold;
  padding-right: 4px;
  flex: 1 1 auto;

  @media (max-width: 680px) {
    color: ${(props) => (props.error ? '#E65800' : '#00A658')};
  }
`;

const NotificationBody = styled.span`
  margin-top: 5px;
  color: rgba(33, 33, 33, 0.75);
  font-size: 14px;
  line-height: 18px;
`;

const NotificationCross = styled.span`
  position: absolute;
  top: 21px;
  right: 14px;
`;
