import { LanguageCode } from '../i18n/language-code';

import type { EddlLocale } from './eddlLocaleForLanguageCode';

enum EddlPageViewActionInteractionGoal {
  VirtualPageView = 'virtual_pageview',
}

type EddlPageViewAction = {
  trigger: string;
  event: string;
  interaction: {
    goal: EddlPageViewActionInteractionGoal;
    description: string;
    url: string;
    interactive: boolean;
  };
  locale?: EddlLocale;
};

enum EddlMicrositeEventType {
  CustomEvent = 'customEvent',
}

type EddlMicrositeEvent = {
  trigger: string;
  event: EddlMicrositeEventType.CustomEvent;
  interaction: {
    goal: string;
    description?: string;
    status?: string;
    url?: string;
    value?: string;
    id?: string;
    interactive: boolean;
  };
  component: {
    name: string;
    type: string;
    id: string;
  };
};

type EddlEventsPush = (eddleAction: EddlPageViewAction | EddlMicrositeEvent) => void;

type EddlEvents = {
  push: EddlEventsPush;
};

declare global {
  interface Window {
    eddlEvents: EddlEvents;
  }
}

type GA360PageDescription = {
  langCode?: LanguageCode;
  description: string;
  url: string;
};

type GA360MicrositeEvent = {
  micrositeName: string;
  micrositeID: string;
  componentType: string;
  action: string;
  goal: string;
  interactive: boolean;
  description?: string;
  status?: string;
  url?: string;
  value?: string;
  interactionID?: string;
};

export { EddlPageViewActionInteractionGoal, EddlMicrositeEventType };

export type { GA360PageDescription, GA360MicrositeEvent, EddlPageViewAction, EddlMicrositeEvent };
