import React from 'react';

import { Development } from '../../entities/development';
import { useAllDevelopmentsQuery } from '../../generated/client';

export const useGetDevelopments = (locale: string) => {
  const { data, loading, error } = useAllDevelopmentsQuery({ variables: { locale } });

  return React.useMemo(() => {
    if (loading || error) {
      return { data: undefined, loading, error };
    }

    return {
      data: data?.developmentsCollection?.items
        .map(
          (item) =>
            ({
              id: item?.sys?.id || '',
              publishedAt: item?.sys?.firstPublishedAt ? new Date(item.sys.firstPublishedAt) : null,
              image: item?.thumbnail?.url || null,
              title: item?.title || '',
              sortIndex: item?.sortIndex || 0,
              postDate: item?.postDate || null,
              link: item?.link || '',
              description: item?.description || undefined,
            } as Development),
        )
        .sort((a, b) => a.sortIndex - b.sortIndex),
      loading,
      error,
    };
  }, [data, loading, error]);
};
