import React from 'react';

export const DLSIconNavigationRight: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width="100%"
      height="100%"
    >
      <path fill={fill} d="M9.7 18.7L8.3 17.3 13.6 12 8.3 6.7 9.7 5.3 16.4 12z" />
    </svg>
  );
};
