import { UserInfoStore } from './services/user-info/user-info.context';
import { AssetsStore } from './store/assets.store';

export class AppStore {
  assetsStore: AssetsStore;

  userInfoStore: UserInfoStore;

  constructor() {
    this.assetsStore = new AssetsStore();
    this.userInfoStore = new UserInfoStore();
  }
}
