import { Select } from '@dls/react-core';
import { Button, Checkbox as CheckboxDLS, Input, Label } from '@dls/react-labs';
import { MenuItem } from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { DLSIcon } from '../../components/dls-icon/dls-icon';
import { NotificationContext } from '../../components/notification/notification-context';
import { Spinner } from '../../components/spinner/spinner';
import { FocusArea } from '../../entities/profile/focus-area';
import { ORGANIZATIONS } from '../../entities/profile/organizations';
import { Professions } from '../../entities/profile/professions';
import { ProfileInfo } from '../../entities/profile/profile-info';
import { WorkArea } from '../../entities/profile/work-area';
import { WorkExperience } from '../../entities/profile/work-experience';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useTranslation } from '../../i18n/translation-provider';
import { ColorDLS } from '../../utils/styling/colors';
import { Mobile } from '../../utils/styling/media-queries';
import { InferReactComponentProps } from '../../utils/type-util';

const StyledCheckBox = styled(CheckboxDLS)`
  font-family: CentraleSans, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${ColorDLS.Gray75};
`;

const SORTED_ORGANIZATIONS = Object.keys(ORGANIZATIONS).sort();

export const MyProfilePage: React.FC = () => {
  const [t] = useTranslation();
  const [profile, setProfile] = useState<ProfileInfo>({
    email: '',
    workArea: '',
    workExperience: '',
    jobDescriptionIdentifier: '',
    firstName: '',
    lastName: '',
    professionFocusIdentifiers: [],
    provider: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { addNotification } = useContext(NotificationContext);

  const updateProfile = useCallback(
    (result: AxiosResponse<ProfileInfo | ''>) => {
      if (result?.data !== '') {
        const updatedProfile = { ...result.data };

        updatedProfile.professionFocusIdentifiers = result.data.professionFocusIdentifiers || [];
        setProfile(updatedProfile);
      }
    },
    [setProfile],
  );

  function updateProfessionFocus(focus: FocusArea, add: boolean) {
    if (add) {
      setProfile({
        ...profile,
        professionFocusIdentifiers: profile.professionFocusIdentifiers.filter((f) => f !== focus),
      });
    } else {
      setProfile({
        ...profile,
        professionFocusIdentifiers: [...profile.professionFocusIdentifiers, focus],
      });
    }
  }

  function SignOut() {
    window.location.assign('/user/logout');
  }

  async function DeleteProfile() {
    window.location.assign('/delete-account');
  }

  async function OnSubmitForm(event: React.FormEvent) {
    event.preventDefault();
    let profileSavedSuccessfully = false;
    try {
      setIsSaving(true);
      const result = await axios.post<ProfileInfo | ''>('/api/profiles/me', { ...profile });
      if (result) {
        updateProfile(result);
      }
      profileSavedSuccessfully = !!result;
    } catch {
      // error handled in finally
    } finally {
      if (!profileSavedSuccessfully) {
        addNotification({
          title: 'Error saving profile',
          error: true,
          body: '',
        });
      }
      setIsSaving(false);
    }
  }

  useGAPageViewsTracking({ description: 'my-profile', url: '/my-icarus/my-profile' });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const result = await axios.get<ProfileInfo | ''>('/api/profiles/me');
        updateProfile(result);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfile();
  }, [updateProfile]);

  return (
    <Container>
      <TopBar>
        <PersonAvatar>
          <DLSIcon name="PersonPortrait" size={48} color={ColorDLS.Blue30} />
        </PersonAvatar>
      </TopBar>
      {isLoading ? (
        <Spinner />
      ) : (
        <ProfileForm onSubmit={OnSubmitForm} data-testid="my-profile-form">
          <InputFieldGrid>
            <InputContainer>
              <StyledLabel>{t('EMAIL_ADDRESS')}</StyledLabel>
              <StyledInput
                data-testid="profile-email"
                disabled
                name="email"
                placeholder={t('EMAIL_ADDRESS_HINT')}
                value={profile.email}
                onChange={(evt) => setProfile({ ...profile, email: evt.target.value })}
              />
            </InputContainer>
            <InputContainer>
              <StyledLabel>{t('FIRST_NAME')}</StyledLabel>
              <StyledInput
                name="firstName"
                data-testid="profile-first-name"
                placeholder={t('FIRST_NAME_HINT')}
                value={profile.firstName}
                onChange={(evt) => setProfile({ ...profile, firstName: evt.target.value })}
              />
            </InputContainer>
            <InputContainer>
              <StyledLabel>{t('LAST_NAME')}</StyledLabel>
              <StyledInput
                data-testid="profile-last-name"
                name="lastName"
                placeholder={t('LAST_NAME_HINT')}
                value={profile.lastName}
                onChange={(evt) => setProfile({ ...profile, lastName: evt.target.value })}
              />
            </InputContainer>
            <InputContainer>
              <StyledLabel>{t('PROFESSION')}</StyledLabel>
              <SelectContainer>
                <Select
                  inputProps={{ 'data-testid': 'profile-profession' }}
                  name="profession"
                  placeholder={t('PROFESSION_HINT')}
                  value={profile.jobDescriptionIdentifier}
                  onChange={(evt) => setProfile({ ...profile, jobDescriptionIdentifier: evt.target.value as string })}
                >
                  <MenuItem value={Professions.MTRA}>{t('PROFESSIONS_MTRA')}</MenuItem>
                  <MenuItem value={Professions.LEADING_MTRA}>{t('PROFESSIONS_LEADING_MTRA')}</MenuItem>
                  <MenuItem value={Professions.MFA}>{t('PROFESSIONS_MFA')}</MenuItem>
                  <MenuItem value={Professions.RADIOLOGIST}>{t('PROFESSIONS_RADIOLOGIST')}</MenuItem>
                  <MenuItem value={Professions.OTHER_CERTIFIED_XRAY}>{t('PROFESSIONS_OTHER_CERTIFIED_XRAY')}</MenuItem>
                  <MenuItem value={Professions.OTHER}>{t('PROFESSIONS_OTHER')}</MenuItem>
                </Select>
              </SelectContainer>
            </InputContainer>
            <InputContainer>
              <StyledLabel>{t('FIELD_OF_ACTIVITY_LABEL')}</StyledLabel>
              <SelectContainer>
                <Select
                  data-testid="organization-type-select"
                  name="fieldOfActivity"
                  placeholder={t('FIELD_OF_ACTIVITY_HINT')}
                  value={profile.workArea}
                  onChange={(evt) =>
                    setProfile({
                      ...profile,
                      workArea: evt.target.value as string,
                      provider: evt.target.value === WorkArea.HOSPITAL ? profile.provider : '',
                    })
                  }
                >
                  <MenuItem value={WorkArea.HOSPITAL}>{t('FIELD_OF_ACTIVITY_HOSPITAL')}</MenuItem>
                  <MenuItem value={WorkArea.OFFICE}>{t('FIELD_OF_ACTIVITY_OFFICE')}</MenuItem>
                  <MenuItem value={WorkArea.MVZ}>{t('FIELD_OF_ACTIVITY_MVZ')}</MenuItem>
                </Select>
              </SelectContainer>
            </InputContainer>
            {profile.workArea === WorkArea.HOSPITAL ? (
              <InputContainer>
                <StyledLabel>{t('ORGANIZATION_LABEL')}</StyledLabel>
                <SelectContainer>
                  <Select
                    inputProps={{ 'data-testid': 'profile-organization' }}
                    name="provider"
                    value={profile.provider}
                    placeholder={t('ORGANIZATION_HINT')}
                    onChange={(evt) => setProfile({ ...profile, provider: evt.target.value as string })}
                  >
                    {SORTED_ORGANIZATIONS.map((o) => (
                      <MenuItem key={o} value={ORGANIZATIONS[o]}>
                        {o}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectContainer>
              </InputContainer>
            ) : (
              <></>
            )}
            <InputContainer>
              <StyledLabel>{t('WORKING_EXPERIENCE_LABEL')}</StyledLabel>
              <SelectContainer>
                <Select
                  inputProps={{ 'data-testid': 'profile-working-experience' }}
                  name="workingExperience"
                  placeholder={t('WORKING_EXPERIENCE_HINT')}
                  value={profile.workExperience}
                  onChange={(evt) => setProfile({ ...profile, workExperience: evt.target.value as string })}
                >
                  <MenuItem value={WorkExperience.SHORT}>{t('WORK_EXPERIENCE_SHORT')}</MenuItem>
                  <MenuItem value={WorkExperience.MEDIUM}>{t('WORK_EXPERIENCE_MEDIUM')}</MenuItem>
                  <MenuItem value={WorkExperience.LONG}>{t('WORK_EXPERIENCE_LONG')}</MenuItem>
                </Select>
              </SelectContainer>
            </InputContainer>
          </InputFieldGrid>
          <FocusHeaderLabel>{t('FOCUS')}</FocusHeaderLabel>
          <CheckboxGrid>
            <Checkbox
              checked={profile.professionFocusIdentifiers.includes(FocusArea.CT)}
              onChange={(evt) => updateProfessionFocus(FocusArea.CT, !evt?.target.checked)}
            >
              {t('FOCUS_AREA_CT')}
            </Checkbox>
            <Checkbox
              checked={profile.professionFocusIdentifiers.includes(FocusArea.MR)}
              onChange={(evt) => updateProfessionFocus(FocusArea.MR, !evt?.target.checked)}
            >
              {t('FOCUS_AREA_MR')}
            </Checkbox>
            <Checkbox
              checked={profile.professionFocusIdentifiers.includes(FocusArea.XRAY)}
              onChange={(evt) => updateProfessionFocus(FocusArea.XRAY, !evt?.target.checked)}
            >
              {t('FOCUS_AREA_XRAY')}
            </Checkbox>
            <Checkbox
              checked={profile.professionFocusIdentifiers.includes(FocusArea.NUCLEAR_MEDICINE)}
              onChange={(evt) => updateProfessionFocus(FocusArea.NUCLEAR_MEDICINE, !evt?.target.checked)}
            >
              {t('FOCUS_AREA_NUCLEAR_MEDICINE')}
            </Checkbox>
            <Checkbox
              checked={profile.professionFocusIdentifiers.includes(FocusArea.VETERINARY_MEDICINE)}
              onChange={(evt) => updateProfessionFocus(FocusArea.VETERINARY_MEDICINE, !evt?.target.checked)}
            >
              {t('FOCUS_AREA_VETERINARY_MEDICINE')}
            </Checkbox>
            <Checkbox
              checked={profile.professionFocusIdentifiers.includes(FocusArea.RADIOTHERAPY)}
              onChange={(evt) => updateProfessionFocus(FocusArea.RADIOTHERAPY, !evt?.target.checked)}
            >
              {t('FOCUS_AREA_RADIOTHERAPY')}
            </Checkbox>
            <Checkbox
              checked={profile.professionFocusIdentifiers.includes(FocusArea.INTERVENTIONAL_RADIOLOGY)}
              onChange={(evt) => updateProfessionFocus(FocusArea.INTERVENTIONAL_RADIOLOGY, !evt?.target.checked)}
            >
              {t('FOCUS_AREA_INTERVENTIONAL_RADIOLOGY')}
            </Checkbox>
          </CheckboxGrid>
          <StyledButton type="submit" variant="primary" disabled={isSaving} data-testid="profile-save-button">
            {t('SAVE')}
          </StyledButton>
          <StyledButton variant="quietEmphasis" onClick={SignOut} data-testid="profile-signout-button">
            {t('SIGN_OUT')}
          </StyledButton>
          <StyledButton
            type="button"
            variant="quietEmphasis"
            onClick={DeleteProfile}
            data-testid="profile-delete-button"
          >
            {t('DELETE')}
          </StyledButton>
        </ProfileForm>
      )}
    </Container>
  );
};

const Checkbox: React.FC<InferReactComponentProps<typeof CheckboxDLS>> = (props) => {
  return <StyledCheckBox {...props} />;
};

const StyledButton = styled(Button)<InferReactComponentProps<typeof Button>>`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;

const StyledInput = styled(Input)`
  font: 16px/20px CentraleSans;
  min-width: 310px;
  width: 100%;
  padding: 9px 12px;
  border-radius: 4px;
  -webkit-appearance: none;
`;

const StyledLabel = styled(Label)`
  font: 16px/20px CentraleSans;
`;

const FocusHeaderLabel = styled(Label)`
  font: 16px/20px CentraleSans;
  padding-top: 16px;
`;

const InputContainer = styled.div``;

const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 84px;

  ${Mobile} {
    width: 100%;
    padding: 0 16px 16px 16px;
  }
`;

const InputFieldGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 12px;

  ${Mobile} {
    grid-template-columns: 1fr;
  }
`;

const PersonAvatar = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: ${ColorDLS.Blue10};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 84px;
`;

const TopBar = styled.div`
  width: 100%;
  height: 84px;
  background-color: ${ColorDLS.Blue05};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const CheckboxGrid = styled.div`
  margin-bottom: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 12px;

  ${Mobile} {
    grid-template-columns: 1fr;
  }
`;

const SelectContainer = styled.div`
  .MuiInput-root {
    min-width: 100%;
    border-radius: 4px;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: 0;
  }
`;
