import React, { useContext, useState, MouseEventHandler } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DLSIcon, IconName } from '../../components/dls-icon/dls-icon';
import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { SelectLanguageBar } from '../../components/layout/select-language-bar';
import { NotificationContext } from '../../components/notification/notification-context';
import { Spinner } from '../../components/spinner/spinner';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useCourseCollectionQuery } from '../../generated/client';
import { useTranslation } from '../../i18n/translation-provider';
import { linkStyle } from '../../utils/styling/link';
import { Mobile } from '../../utils/styling/media-queries';

export const DashboardPage: React.FC = () => {
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const [gaAvailable, setGaAvailable] = useState(true);
  const { data, loading, error } = useCourseCollectionQuery({ variables: { locale: i18n.language } });
  const { addNotification, notifications } = useContext(NotificationContext);

  if (error && !notifications.some((n) => n.body === error.message)) {
    addNotification({
      title: '',
      error: true,
      body: error.message,
    });
  }

  useGAPageViewsTracking({ description: 'dashboard', url: '/' });

  const to = (link: string) => () => history.push(link);

  const showCookieNotice: MouseEventHandler<Element> = (e) => {
    e.preventDefault();
    if (window.philips && window.philips.cookiebar && window.philips.cookiebar.openConsentManager) {
      window.philips.cookiebar.openConsentManager();
      setGaAvailable(true);
    } else {
      setGaAvailable(false);
    }
  };

  const effigosCourse = data?.courseCollection?.items?.find((course) => course?.identifier === 'Effigos');
  const effigosLink = effigosCourse?.sys?.id ? `/courses/${effigosCourse?.sys?.id}` : '#';

  return (
    <PageContainer data-testid="dashboard-page">
      <HeaderBar title={t('PROJECT_TITLE')} />
      <Container>
        <Title>{t('DASHBOARD_TITLE')}</Title>
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <BoxContainer>
              <Box icon="PersonPortrait" onClick={to('/my-icarus')} background="#1474A4" title={t('MY_ICARUS')} />
              <Box icon="List" onClick={to('/skills')} background="#299F8F" title={t('SKILLS')} />
              <Box icon="Study" onClick={to('/courses')} background="#77A422" title={t('COURSES')} />
              <Box icon="Idea" onClick={to('/news')} background="#E38B10" title={t('NEWS')} />
              <Box icon="Operating" onClick={to('/developments')} background="#E85D80" title={t('DEVELOPMENTS')} />
              <Box
                icon="Xray"
                onClick={to(effigosLink)}
                background="#B35BB2"
                title={t('EFFIGOS')}
                data-testid="effigos-tile"
              />
            </BoxContainer>
          )}
        </div>
        <LinkContainer>
          <StyledLink to="/terms-and-conditions">{t('TERMS_AND_CONDITIONS')}</StyledLink>
          <StyledExternalLink onClick={(e) => showCookieNotice(e)}>{t('COOKIE_BELEID')}</StyledExternalLink>
          <StyledLink to="/privacy-policy">{t('PRIVACY_POLICY')}</StyledLink>
          <StyledLink to="/about">{t('ABOUT')}</StyledLink>
          <SelectLanguageBar />
          {!gaAvailable && <Text>Google Analytics does not seem to be available. Please contact support.</Text>}
        </LinkContainer>
      </Container>
    </PageContainer>
  );
};

const Box: React.FC<{
  onClick: () => void;
  background: string;
  title: string;
  icon: IconName;
}> = ({ onClick, background, title, icon }) => {
  return (
    <StyledBox role="button" onClick={onClick} background={background}>
      <StyledBoxLink>
        <DLSIcon size={44} name={icon} />
        <StyledBoxLinkText>{title}</StyledBoxLinkText>
      </StyledBoxLink>
    </StyledBox>
  );
};

const StyledLink = styled(Link)`
  ${linkStyle};

  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const StyledExternalLink = styled.a`
  ${linkStyle};

  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const Text = styled.p`
  color: #777;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const StyledBoxLinkText = styled.div`
  font-size: 16px;
  margin-top: 16px;
  text-align: center;
`;

const StyledBoxLink = styled.div`
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledBox = styled.div<{ background?: string }>`
  font-size: 16px;
  line-height: 20px;

  ${Mobile} {
    height: 165px;
  }

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: white;
  border-radius: 2px;
  cursor: pointer;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 16px;
  font-style: italic;
  color: #696969;
  margin-bottom: 16px;
  margin-top: 24px;
`;

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 208px 208px 208px;
  grid-template-rows: 208px 208px;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  margin-bottom: 24px;

  ${Mobile} {
    grid-template-columns: 165px 165px;
    grid-template-rows: 165px 165px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
`;
