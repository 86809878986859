import React from 'react';

export const DLSIconPlay: React.FC = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <title>134FF48E-10CF-47D2-9484-17A799EA52C8</title>
      <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="4.0-programs---content-overview---375" transform="translate(-176.000000, -610.000000)">
          <g id="card-content" transform="translate(0.000000, 516.000000)">
            <g id="btn_play" transform="translate(164.000000, 82.000000)">
              <g id="ic_play" transform="translate(12.000000, 12.000000)">
                <rect id="container" x="0" y="0" width="24" height="24" />
                <path
                  d="M8.51449576,3.90869745 L20.5708451,11.1425071 C21.0444254,11.4266552 21.1979905,12.0409155 20.9138423,12.5144958 C20.8293925,12.6552454 20.7115947,12.7730432 20.5708451,12.8574929 L8.51449576,20.0913025 C8.04091549,20.3754507 7.42665523,20.2218856 7.14250707,19.7483054 C7.04925783,19.59289 7,19.4150536 7,19.2338096 L7,4.76619038 C7,4.21390563 7.44771525,3.76619038 8,3.76619038 C8.18124395,3.76619038 8.35908035,3.81544821 8.51449576,3.90869745 Z"
                  id="Rectangle"
                  fill="#212121"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
