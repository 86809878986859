import { Button } from '@dls/react-core';
import { Checkbox } from '@dls/react-labs';
import { CardContent, FormControl } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { Spinner } from '../../components/spinner/spinner';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useTranslation } from '../../i18n/translation-provider';
import { toggleInList } from '../../utils/array';
import { ColorDLS } from '../../utils/styling/colors';

import QuizHeader from './quiz-header.svg';
import { SkillsBackButton, SkillsPageContent, StyledCard, StyledCardActions, StyledCardMedia } from './skills.styles';
import { useGetQuizData } from './use-get-quiz-data';

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

const Title = styled.h3`
  font: 20px/24px CentraleSans;
  color: ${ColorDLS.Gray75};
`;

const AreaList = styled.div`
  margin-bottom: 16px;
`;

export const SkillsInterestAreasPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const params = useParams<{ interestAreas?: string }>();
  const prefilledInterestAreas = params.interestAreas?.split(',') || [];

  const { data, loading } = useGetQuizData();

  useGAPageViewsTracking({ description: 'SkillsInterestAreasPage', url: '/skills/interest-areas' });

  const [preferredInterestAreas, setPreferredInterestAreas] = useState<string[]>(prefilledInterestAreas);
  const interestAreas =
    data?.interestAreaCollection?.items.map((area) => ({
      id: area?.sys.id || '',
      name: area?.interestArea || '',
    })) || [];

  const togglePreferredInterestArea = (areaId: string) => {
    setPreferredInterestAreas(toggleInList(areaId, preferredInterestAreas));
  };

  const quiz = data?.skillsetAnalysisCollection?.items[0];
  const quizTitle = quiz?.name || '';

  async function onSubmitForm(event: React.FormEvent) {
    event.preventDefault();
    history.push(`/skills/quiz/${preferredInterestAreas.join(',')}`);
  }

  return (
    <PageContainer data-testid="skills-interest-areas-page">
      <HeaderBar title={quizTitle} previousPage="/skills" />
      <SkillsPageContent>
        <FormControl component="form" onSubmit={onSubmitForm}>
          <StyledCard>
            <StyledCardMedia image={QuizHeader} title={t('SKILLS')} />
            <CardContent>
              {loading ? (
                <Spinner />
              ) : (
                <AreaList>
                  <Title>{t('CHOOSE_AREAS')}</Title>
                  {interestAreas.map((area) => (
                    <div key={area.id}>
                      <Checkbox
                        data-testid="interest-area-entry"
                        checked={preferredInterestAreas.includes(area.id)}
                        onChange={() => togglePreferredInterestArea(area.id)}
                      >
                        {area.name}
                      </Checkbox>
                    </div>
                  ))}
                </AreaList>
              )}
            </CardContent>
            <StyledCardActions>
              <UnstyledLink to="/skills" data-testid="skills-back-button">
                <SkillsBackButton variant="quietEmphasis">{t('BACK')}</SkillsBackButton>
              </UnstyledLink>
              <Button
                dls_variant="primary"
                type="submit"
                data-testid="skills-interest-areas-continue-button"
                disabled={preferredInterestAreas.length === 0}
              >
                {t('CONTINUE')}
              </Button>
            </StyledCardActions>
          </StyledCard>
        </FormControl>
      </SkillsPageContent>
    </PageContainer>
  );
};
