import { Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';

import { useAppContext } from '../../app-context';
import { ContentfulRichText, ContentfulRichTextType } from '../../components/contentful-rich-text';
import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { Spinner } from '../../components/spinner/spinner';
import { createMicrositeEvent } from '../../ga360/createMicrositeEvent';
import { createMicrositeIdFromEmail } from '../../ga360/createMicrositeId';
import { emitMicrositeEvent } from '../../ga360/micrositeEvent';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useTranslation } from '../../i18n/translation-provider';
import { useGetDevelopments } from '../../services/development/use-get-developments';
import { ColorDLS } from '../../utils/styling/colors';
import { linkStyle } from '../../utils/styling/link';
import { Mobile } from '../../utils/styling/media-queries';

export const DevelopmentsPage = () => {
  const [t, i18n] = useTranslation();
  const { data: developments, loading } = useGetDevelopments(i18n.language);

  useGAPageViewsTracking({ description: 'DevelopmentsPage', url: '/developments' });

  return (
    <PageContainer data-testid="developments-page">
      <HeaderBar title={t('DEVELOPMENTS')} previousPage="/" />
      <PageContent>
        {loading || !developments ? (
          <Spinner />
        ) : (
          <>
            {developments.map((d) => (
              <DevelopmentCard
                link={d.link}
                image={d.image}
                title={d.title}
                description={d.description}
                postDate={d.postDate}
                key={d.id}
              />
            ))}
          </>
        )}
      </PageContent>
    </PageContainer>
  );
};

const DevelopmentCard: React.FC<{
  image: string | null;
  title: string;
  description: ContentfulRichTextType | undefined;
  link: string;
  postDate: string | null;
}> = ({ image, title, description, link, postDate }) => {
  const [t] = useTranslation();
  const { userInfoStore } = useAppContext();

  const onClick = () => {
    emitMicrositeEvent(
      createMicrositeEvent({
        micrositeID: createMicrositeIdFromEmail(userInfoStore?.userInfo?.username),
        action: title,
        goal: `developments-${title}`,
      }),
    );
  };

  return (
    <StyledCard>
      {image ? <StyledCardMedia image={image} /> : null}
      <CardContent>
        <CardTitle>
          <Markdown source={title} />
        </CardTitle>
        <CardDescription>
          <ContentfulRichText content={description} />
        </CardDescription>
      </CardContent>
      <StyledCardActions>
        <CardLink href={link} onClick={onClick} target="top _blank">
          {t('READ_MORE')}
        </CardLink>
        <PostDate>{postDate ? format(new Date(postDate), 'PP') : ''}</PostDate>
      </StyledCardActions>
    </StyledCard>
  );
};

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const StyledCard = styled(Card)`
  &&.MuiCard-root {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    width: 648px;
    margin-bottom: 24px;

    ${Mobile} {
      width: 100%;
    }

    .MuiCardContent-root {
      padding: 24px;
    }
  }
`;

const StyledCardMedia = styled(CardMedia)`
  height: 211px;
`;

const StyledCardActions = styled(CardActions)`
  && {
    justify-content: space-between;
    padding: 10px 24px 32px 24px;
  }
`;

const CardTitle = styled.div`
  font: 20px/24px CentraleSans;
  color: ${ColorDLS.Gray75};
  margin-bottom: 12px;
`;

const CardDescription = styled.div`
  p {
    color: ${ColorDLS.Gray75};
    opacity: 0.8;
  }
`;

const CardLink = styled.a`
  ${linkStyle};
`;

const PostDate = styled.div`
  font: 14px/18px CentraleSans;
  color: ${ColorDLS.Gray45};
`;
