import React from 'react';

export const DLSIconArrowDown: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width="100%"
      height="100%"
    >
      <path fill={fill} d="M17,10l-5,5l-5-5V9h10V10z" />
    </svg>
  );
};
