/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@dls/react-core';
import { Divider } from '@dls/react-mui-divider';
import { Typography } from '@dls/react-mui-typography';
import axios, { AxiosResponse } from 'axios';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PhilipsShield from '../../assets/philips-shield.svg';
import { NotificationContext } from '../../components/notification/notification-context';
import { ProfileInfo } from '../../entities/profile/profile-info';
import { useTranslation } from '../../i18n/translation-provider';

export const DeleteAccountPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [profile, setProfile] = useState<ProfileInfo>({
    email: '',
    workArea: '',
    workExperience: '',
    jobDescriptionIdentifier: '',
    firstName: '',
    lastName: '',
    professionFocusIdentifiers: [],
    provider: '',
  });
  const { addNotification } = useContext(NotificationContext);

  const updateProfile = useCallback(
    (result: AxiosResponse<ProfileInfo | ''>) => {
      if (result?.data !== '') {
        const updatedProfile = { ...result.data };

        updatedProfile.professionFocusIdentifiers = result.data.professionFocusIdentifiers || [];
        setProfile(updatedProfile);
      }
    },
    [setProfile],
  );
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const result = await axios.get<ProfileInfo | ''>('/api/profiles/me');
        updateProfile(result);
      } finally {
        // setIsLoading(false);
      }
    };
    fetchProfile();
  }, [updateProfile]);

  function SignOut() {
    window.location.assign('/user/logout');
  }

  const DeleteFunction = async () => {
    let profileDeletedSuccessfully = false;
    try {
      const result = await axios.post<ProfileInfo | '' | any>('/api/profiles/delete', { ...profile });
      if (result) {
        SignOut();
      }
      profileDeletedSuccessfully = !!result;
    } catch {
      // error handled in finally
    } finally {
      if (!profileDeletedSuccessfully) {
        addNotification({
          title: 'Error deleting profile',
          error: true,
          body: '',
        });
      }
    }
  };

  return (
    <div>
      <FormScreenContainer>
        <BlueHeader />
        <FormScreenLogo src={PhilipsShield} alt="Philips Logo" />
        <Title data-test-id="form-screen-title">{t('PHILIPS_PROJECT_TITLE')}</Title>
        <FormScreenForm>
          <CreateAccountInputsContainer>
            <FormTitle variant="h3">{t('DELETE_ACCOUNT_QUESTION')}</FormTitle>
            <FormLabel>{t('DELETE_ACCOUNT_ARE_YOU_SURE')}</FormLabel>
          </CreateAccountInputsContainer>
          <FormDivider className="DLS-divider" variant="fullWidth" />
          <FormDoubleButtonContainer>
            <Button
              data-test-id="create-account-back-button"
              dls_variant="secondary"
              onClick={() => history.push('/my-icarus/my-profile')}
            >
              {t('CANCEL')}
            </Button>
            <Button
              data-test-id="create-account-submit-button"
              dls_variant="quietEmphasis"
              onClick={() => DeleteFunction()}
            >
              {t('PERMANENTLY_DELETE')}
            </Button>
          </FormDoubleButtonContainer>
        </FormScreenForm>
      </FormScreenContainer>
    </div>
  );
};

const FormScreenContainer = styled.div`
  background: #f5f5f5;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 0;
`;

const BlueHeader = styled.header`
  height: 369px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #1474a4 radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 0;
`;

type FormScreenLogoProps = Readonly<{
  src: string;
  alt: string;
}>;

const FormScreenLogo = styled.img<FormScreenLogoProps>`
  height: 96px;
  width: 75px;
  z-index: 1;
`;

const Title = styled.h3`
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  margin: 72px 0 48px 0;
  font-family: CentraleSans, sans-serif;
  font-weight: 300;
  font-style: normal;
  z-index: 1;
`;

const FormScreenForm = styled.form`
  width: 100%;
  max-width: 480px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
`;

const FormTitle = styled(Typography)`
  &&&& {
    color: #1474a4;
    font-weight: 400;
  }
`;

const FormDivider = styled(Divider)`
  &&& {
    margin: 0;
  }
`;

const FormDoubleButtonContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 16px;
  row-gap: 12px;
  padding: 16px 32px;
`;
const FormLabel = styled.label`
  display: block;
  font-family: CentraleSans;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
`;

const CreateAccountInputsContainer = styled.div`
  padding: 24px 32px 24px 32px;
  color: #212121;
`;
