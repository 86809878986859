export const ORGANIZATIONS: Record<string, string> = Object.freeze({
  '-Other-': 'unknown',
  'Med360°': 'med360',
  'Helios': 'helios',
  'Sana Kliniken AG': 'sana',
  'Asklepios': 'asklepios',
  'SRH Kliniken': 'srh',
  'Paracelsus': 'paracelsus',
  'Vivantes': 'vivantes',
  'Schön Klinik': 'schoen',
  'Agaplesion': 'agaplesion',
  'BG Kliniken': 'bg',
  'Ameos': 'ameos',
  'Johanniter': 'johanniter',
  'Marienhaus': 'marienhaus',
  'Rhön-Kliniken': 'roehn',
  'DRK Kliniken': 'drk',
  'Knappschaft': 'knappschaft',
  'Regiomed Kliniken': 'regiomed',
  'Barmherzige Brüder': 'barmherzige',
});
