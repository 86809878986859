import React from 'react';
import { useHistory } from 'react-router-dom';

import { ContentfulRichText } from '../../components/contentful-rich-text';
import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { useAllStaticPagesQuery } from '../../generated/client';
import { useTranslation } from '../../i18n/translation-provider';
import { useMobile } from '../../utils/styling/media-queries';

import { IcQuoteIcon } from './ic-quote-icon';
import {
  SkillsBackButton,
  SkillsPageContent,
  StyledCard,
  StyledCardActions,
  StyledCardMedia,
  StyledCardContent,
  CardTitle,
  CardText,
  EmptyCoursesBlock,
  RecommendedCoursesTitle,
  Courses,
  EmptyCourse,
  CourseImage,
  CourseContent,
  CourseContentLine,
  QuoteBox,
  QuoteText,
  TakeQuizButton,
} from './skills.styles';

export const SkillsEmptyPage: React.FC = () => {
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const isMobile = useMobile();

  const emptyCoursesAmount = isMobile ? 2 : 4;

  const result = useAllStaticPagesQuery({ variables: { locale: i18n.language } });
  const sectionOne = result?.data?.staticPageCollection?.items.find((i) => i?.pageName === 'skillsquiz intro 1');
  const sectionTwo = result?.data?.staticPageCollection?.items.find((i) => i?.pageName === 'skills quiz intro 2');
  const sectionQuote = result?.data?.staticPageCollection?.items.find((i) => i?.pageName === 'Skills quiz intro quote');

  return (
    <PageContainer data-testid="skills-empty-page">
      <HeaderBar title={t('SKILLS')} previousPage="/" />
      <SkillsPageContent>
        <StyledCard>
          {sectionOne && sectionOne.image && sectionOne.image.url ? (
            <StyledCardMedia image={sectionOne.image.url} title={t('SKILLS')} />
          ) : null}
          <StyledCardContent>
            <CardTitle>{sectionOne?.title}</CardTitle>
            <CardText>
              <ContentfulRichText content={sectionOne?.text || undefined} />
            </CardText>
            <EmptyCoursesBlock>
              <RecommendedCoursesTitle>{t('RECOMMENDED_COURSES')}</RecommendedCoursesTitle>
              <Courses>
                {Array.from(Array(emptyCoursesAmount).keys()).map((index) => {
                  const courseKey = `empty-course-${index}`;
                  return (
                    <EmptyCourse key={courseKey} data-testid="empty-course">
                      <CourseImage />
                      <CourseContent>
                        <CourseContentLine width={144} />
                        <CourseContentLine width={87} />
                      </CourseContent>
                    </EmptyCourse>
                  );
                })}
              </Courses>
            </EmptyCoursesBlock>
            <CardText>
              <ContentfulRichText content={sectionTwo?.text || undefined} />
            </CardText>
            <QuoteBox>
              <IcQuoteIcon />
              <QuoteText>
                <ContentfulRichText content={sectionQuote?.text || undefined} />
              </QuoteText>
            </QuoteBox>
          </StyledCardContent>
          <StyledCardActions>
            <SkillsBackButton
              variant="quietEmphasis"
              onClick={() => history.push('/')}
              data-testid="skills-back-button"
            >
              {t('BACK')}
            </SkillsBackButton>
            <TakeQuizButton
              variant="primary"
              onClick={() => history.push('/skills/interest-areas')}
              data-testid="take-quiz-button"
            >
              {t('TAKE_QUIZ')}
            </TakeQuizButton>
          </StyledCardActions>
        </StyledCard>
      </SkillsPageContent>
    </PageContainer>
  );
};
