import React from 'react';

export const DLSIconTag: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg viewBox="0 0 32 32" width="100%" height="100%">
      <path
        fill={fill}
        d="M17,2L2,17l13,13l15-15V2H17z M24,12c-2.209,0-4-1.791-4-4c0-2.209,1.791-4,4-4s4,1.791,4,4C28,10.209,26.209,12,24,12z"
      />
    </svg>
  );
};
