import { ApolloClient, concat, HttpLink, InMemoryCache } from '@apollo/client';
import { ApolloAuthenticatedMiddleware, ApolloUnauthorizedErrorHandler } from '@philips/iam-service';

const httpLink = new HttpLink({ uri: '/content/graphql' });

export const createApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(ApolloUnauthorizedErrorHandler, concat(ApolloAuthenticatedMiddleware, httpLink)),
  });
