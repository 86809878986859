import { createTheme as createMuiTheme } from '@material-ui/core/styles';

import { Breakpoint } from './utils/styling/breakpoint';
import { ColorDLS } from './utils/styling/colors';

export const muiTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: Breakpoint.XS_MIN,
      sm: Breakpoint.SM_MIN,
      md: Breakpoint.MD_MIN,
      lg: Breakpoint.LG_MIN,
      xl: Breakpoint.XL_MIN,
    },
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontFamily: ['CentraleSans', 'sans-serif'].join(','),
    fontWeightMedium: 'normal',
    fontSize: 16,
  },
  palette: {
    background: {
      default: ColorDLS.Gray05,
    },
    primary: {
      main: ColorDLS.Blue45,
    },
    text: {
      primary: ColorDLS.Gray75,
    },
  },
  overrides: {
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTabs: {
      indicator: {
        background: 'white',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '2px',
      },
    },
    MuiSnackbar: {
      root: {
        background: 'white',
        color: '#212121',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'white',
        color: '#212121',
      },
      action: {
        color: ColorDLS.SignalGreen45,
      },
    },
  },
});
