import { useCallback } from 'react';

import { NotificationWithId, Notification } from './notification';

const MAX_VISIBLE_NOTIFICATIONS = 2;
const NOTIFICATION_ID_PREFIX = 'notification-';
let idCounter = 0;

export const useNotificationStore = (
  notifications: NotificationWithId[],
  setNotifications: (value: ((prevState: NotificationWithId[]) => NotificationWithId[]) | NotificationWithId[]) => void,
) => ({
  notifications,

  clearNotification: useCallback(
    (notification: NotificationWithId) => {
      setNotifications((prevState) => prevState.filter((n) => n.id !== notification.id));
    },
    [setNotifications],
  ),

  addNotification: useCallback(
    (notification: Notification) => {
      const newNotification: NotificationWithId = {
        ...notification,
        id: `${NOTIFICATION_ID_PREFIX}${idCounter}`,
        date: new Date(),
      };
      const allNotifications = [...notifications, newNotification];
      const updatedNotifications = allNotifications.slice(
        Math.max(0, allNotifications.length - MAX_VISIBLE_NOTIFICATIONS),
      );
      setNotifications(updatedNotifications);
      idCounter += 1;

      if (notification.timeout !== null) {
        setTimeout(() => {
          setNotifications((prevState) => prevState.filter((n) => n.id !== newNotification.id));
        }, notification.timeout || 5000);
      }
    },
    [notifications, setNotifications],
  ),
});
