import { Button, Menu, MenuItem, Label } from '@dls/react-core';
import { Icon } from '@dls/react-icon';
import React, { useState } from 'react';

import { BookingState } from './booking-state';

export enum AdminTableMenuOption {
  Accept = 'accept',
  AcceptFor3Months = 'acceptFor3Months',
  AcceptFor6Months = 'acceptFor6Months',
  AcceptFor12Months = 'acceptFor12Months',
  Reject = 'reject',
  Revoke = 'revoke',
}

export const AdminTableMenu = (props: {
  id: number;
  onSelect: (value: AdminTableMenuOption, id: number) => void;
  currentState: BookingState;
}) => {
  const options: {
    label: string;
    value: AdminTableMenuOption;
    showOnStates: BookingState[];
  }[] = [
    {
      label: 'Accept',
      value: AdminTableMenuOption.Accept,
      showOnStates: [BookingState.Requested, BookingState.Revoked, BookingState.Rejected],
    },
    {
      label: 'Accept for 3 months',
      value: AdminTableMenuOption.AcceptFor3Months,
      showOnStates: [BookingState.Requested, BookingState.Revoked, BookingState.Rejected],
    },
    {
      label: 'Accept for 6 months',
      value: AdminTableMenuOption.AcceptFor6Months,
      showOnStates: [BookingState.Requested, BookingState.Revoked, BookingState.Rejected],
    },
    {
      label: 'Accept for 12 months',
      value: AdminTableMenuOption.AcceptFor12Months,
      showOnStates: [BookingState.Requested, BookingState.Revoked, BookingState.Rejected],
    },
    { label: 'Reject', value: AdminTableMenuOption.Reject, showOnStates: [BookingState.Requested] },
    { label: 'Revoke', value: AdminTableMenuOption.Revoke, showOnStates: [BookingState.Subscribed] },
  ];

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (_event: React.MouseEvent, index: number, value: AdminTableMenuOption) => {
    setSelectedIndex(index);
    props.onSelect(value, props.id);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        data-testid={`admin-menu-${props.id}`}
        dls_variant="quietDefault"
        aria-controls="default-menu-button"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon name="ThreeDotsHorizontal" />
      </Button>
      <Menu
        id="default-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          className: 'DLSMenuList',
        }}
      >
        {options
          .filter((o) => o.showOnStates.indexOf(props.currentState) !== -1)
          .map((option, index) => (
            <MenuItem
              data-testid={`menu-item-${option.value}`}
              key={option.value}
              selected={index === selectedIndex}
              onClick={(event: React.MouseEvent) => handleMenuItemClick(event, index, option.value)}
            >
              <Label label_variant="value">{option.label}</Label>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};
