import React from 'react';

export const DLSIconNavigationLeft: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width="100%"
      height="100%"
    >
      <path fill={fill} d="M14.3 18.7L7.6 12 14.3 5.3 15.7 6.7 10.4 12 15.7 17.3z" />
    </svg>
  );
};
