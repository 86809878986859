import React from 'react';

import { ClickablePaperItem } from '../../../components/clickable-paper-item';
import { FullPdf } from '../../../entities/backend/full-pdf';

import { AssetComponentProps } from './asset-list-types';

export const AssetComponentPdf: React.FC<AssetComponentProps> = ({ asset, handleAssetOpen, isOpened }) => {
  const pdf = asset as FullPdf;
  return (
    <ClickablePaperItem
      disabled={!pdf.pdfFile?.url}
      key={asset.sys.id}
      icon="DocumentPDF"
      label={pdf.pdfTitle}
      isOpened={isOpened}
      onClick={() => {
        handleAssetOpen(asset.sys.id, pdf.pdfTitle);
        window.open(pdf.pdfFile?.url, '_blank');
      }}
    />
  );
};
