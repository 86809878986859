import { AllDataForQuizQueryResult } from '../../generated/client';

export const useGetQuestionsPerAreaFromQuiz = ({ data }: Pick<AllDataForQuizQueryResult, 'data'>) => {
  const quiz = data?.skillsetAnalysisCollection?.items[0];
  const filledInterestAreas = quiz?.interestAreasCollection?.items?.flatMap((i) =>
    data?.interestAreaCollection?.items.filter((interestArea) => interestArea?.sys.id === i?.sys.id),
  );
  const questionPerArea = filledInterestAreas?.map((interestArea) => ({
    id: interestArea?.sys.id,
    name: interestArea?.interestArea || '',
    questionsCollection: (
      interestArea?.questionsCollection?.items.flatMap((question) =>
        (
          data?.skillQuestionCollection?.items.filter((skQuestion) => skQuestion?.sys.id === question?.sys.id) || []
        ).flatMap((skQuestion) => ({
          id: skQuestion?.sys.id || '',
          title: skQuestion?.skillQuestion || '',
          sortIndex: skQuestion?.sortIndex || 0,
          answers: (
            skQuestion?.skillAnswersCollection?.items
              .flatMap((answerId) =>
                data?.skillAnswerCollection?.items.filter((answer) => answerId?.sys.id === answer?.sys.id),
              )
              .map((answer) => ({
                answer: answer?.answer || '',
                id: answer?.sys.id || '',
                sortIndex: answer?.sortIndex || 0,
                points: `${answer?.points}` || '0',
              })) || []
          ).sort((a, b) => a.sortIndex - b.sortIndex),
        })),
      ) || []
    ).sort((a, b) => a.sortIndex - b.sortIndex),
  }));

  return questionPerArea;
};
