import { getUserInfo, UserInfo } from '@philips/iam-service/build/auth-service';
import { observable, runInAction } from 'mobx';

export interface UserInfoContextType {
  updateUserInfo: () => void;
  userInfo: UserInfo;
}

export class UserInfoStore {
  @observable userInfo: UserInfo | null = null;

  async updateUserInfo() {
    await runInAction('updateUserInfo', async () => {
      this.userInfo = await getUserInfo();
    });
  }
}
