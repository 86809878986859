import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  && {
    display: flex;
    padding: 15px 24px;
    border-radius: 2px;
  }
`;
