import axios from 'axios';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import { Booking, BookingData } from '../../entities/profile/bookingData';

export const useBookings = () => {
  const [bookedCourses, setBookedCourses] = useState<Booking[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const booked = await axios.get<BookingData[]>('/api/bookings');
        const bookedCoursesWithDates: Booking[] = booked.data
          ? booked.data.map((b: BookingData) => ({
              ...b,
              expireDate: b.expireDate ? parseISO(b.expireDate) : null,
              lastUpdate: parseISO(b.lastUpdate),
              requestDate: parseISO(b.requestDate),
            }))
          : [];

        setBookedCourses(bookedCoursesWithDates);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);

  return {
    bookedCourses,
    isLoading,
  };
};
