import React from 'react';

export const DLSIconCross: React.FC<{ fill: React.CSSProperties['fill'] }> = ({ fill }) => {
  return (
    <svg viewBox="0 0 24 24" width="100%" height="100%">
      <path
        fill={fill}
        d="M18.7 6.7l-1.4-1.4-5.3 5.3-5.3-5.3-1.4 1.4 5.3 5.3-5.3 5.3 1.4 1.4 5.3-5.3 5.3 5.3 1.4-1.4-5.3-5.3z"
      />
    </svg>
  );
};
