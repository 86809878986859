import { css } from 'styled-components';

export const linkStyle = css`
  font-family: CentraleSans, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1474a4;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
`;
