import { Dialog, DialogActions, DialogContent, DialogTitle } from '@dls/react-core';
import { Button } from '@dls/react-labs';
import React from 'react';
import { useBoolean } from 'react-hanger';
import styled from 'styled-components';

import { reloadGAScripts } from '../../ga360/reloadGAScripts';
import { AVAILABLE_LANGUAGES } from '../../i18n/language-code';
import type { LanguageCode } from '../../i18n/language-code';
import { useTranslation } from '../../i18n/translation-provider';
import { ColorDLS } from '../../utils/styling/colors';
import { linkStyle } from '../../utils/styling/link';
import { DLSIcon } from '../dls-icon/dls-icon';

const LanguageSelectModal: React.FC<{ onClose: (language: string) => void }> = ({ onClose }) => {
  const [t, i18n] = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language);

  return (
    <StyledDialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="language-select-modal"
    >
      <StyledDialogTitle id="alert-dialog-title">Select language</StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="alert-dialog-description">
          <LanguageListContainer>
            {AVAILABLE_LANGUAGES.map((lang) => (
              <LanguageListItem
                key={lang.code}
                data-testid={`language-select-modal-language-item-${lang.code}`}
                onClick={() => setSelectedLanguage(lang.code)}
              >
                <span>{lang.label}</span>
                {selectedLanguage === lang.code && <DLSIcon name="Checkmark" color={ColorDLS.Blue45} size={24} />}
              </LanguageListItem>
            ))}
          </LanguageListContainer>
        </StyledDialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <StyledButton
          data-testid="language-select-modal-apply-button"
          onClick={() => onClose(selectedLanguage)}
          variant="primary"
        >
          {t('APPLY')}
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

const languageCodeToLanguage = (code: string) => {
  return AVAILABLE_LANGUAGES.find((l) => l.code === code);
};

export const SelectLanguageBar: React.FC = () => {
  const showModal = useBoolean(false);
  const [t, i18n] = useTranslation();

  const currentLanguageLabel = languageCodeToLanguage(i18n.language);

  if (!currentLanguageLabel) {
    return null;
  }

  return (
    <>
      {showModal.value && (
        <LanguageSelectModal
          onClose={(langCode: string) => {
            localStorage.setItem('language', langCode);
            i18n.changeLanguage(langCode);
            showModal.setFalse();
            reloadGAScripts(langCode as LanguageCode);
          }}
        />
      )}
      <SelectLanguageContainer data-testid="select-language-button" onClick={showModal.setTrue}>
        {t('LANGUAGE')}: {currentLanguageLabel.label} <DLSIcon name="ArrowDown" size={14} />
      </SelectLanguageContainer>
    </>
  );
};

const LanguageListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LanguageListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  flex: 1;
  width: 100%;
  cursor: pointer;
  height: 54px;

  border-top: 1px solid ${ColorDLS.Gray10};

  &:last-child {
    border-bottom: 1px solid ${ColorDLS.Gray10};
  }
`;

const SelectLanguageContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;

  ${linkStyle};
  font-size: 14px;
  line-height: 20px;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 280px;
  }

  && .MuiDialogContent-root {
    padding-right: 0;
    padding-left: 0;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  .MuiTypography-root {
    font: bold 20px/24px CentraleSans;
    color: ${ColorDLS.Gray75};
  }
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
`;

const StyledDialogContentText = styled(DialogContent)`
  font: 16px/20px CentraleSans;
  color: ${ColorDLS.Gray75};
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  flex: 1;
`;
