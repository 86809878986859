import axios from 'axios';
import { useState, useEffect } from 'react';

import { RadarChartDataset } from '../../components/radar-chart';
import { SkillQuizResult } from '../../entities/quiz/skill-quiz-result';

export const useGetQuizScores = () => {
  const [data, setData] = useState<RadarChartDataset[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const score = await axios.get<SkillQuizResult[]>('/api/skillset-quiz/results/me');
      setData(score?.data?.map((s) => ({ label: s.interestArea, value: s.scorePercentage })) || []);

      setLoading(false);
    };

    fetchData();
  }, []);

  return { data, loading };
};
