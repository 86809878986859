import { TranslationValues } from '../translation-types';

export const translationLocaleDe: TranslationValues = {
  ADMIN_PAGE: 'Learning Academy ICARUS',
  STATUS_REQUESTED: 'Status: Angefordert',
  STATUS_BOOKED: 'Status: Gebucht',
  STATUS_REVOKED: 'Status: Widerrufen',
  STATUS_REJECTED: 'Status: Abgelehnt',
  STATUS_EXPIRED: 'Status: Abgelaufen',
  PROJECT_TITLE: 'Learning Academy ICARUS',
  DASHBOARD_TITLE: 'Qualifizieren - Fördern - Verbinden',
  MY_ICARUS: 'Mein ICARUS',
  MY_COURSES: 'Meine Kurse',
  MY_PROFILE: 'Mein Profil',
  SKILLS: 'Skill set',
  COURSES: 'Kurse',
  NEWS: 'Neuigkeiten',
  DEVELOPMENTS: 'Zusatz­qualifikationen',
  EFFIGOS: 'Röntgensimulator',
  TERMS_AND_CONDITIONS: 'Allgemeine Geschäftsbedingungen',
  COOKIE_BELEID: 'Cookie-Richtlinie',
  PRIVACY_POLICY: 'Datenschutz',
  ABOUT: 'Impressum',
  COMING_SOON: 'Coming Soon',
  COMING_SOON_DESCRIPTION: 'Der Community-Bereich befindet sich in der Entwickelung und steht demnächst zur Verfügung.',
  OK: 'Ok',
  COURSE_DETAILS: 'Kursdetails',
  DATE: 'Datum',
  SCHEDULE_FOLLOWS: 'Termin folgt',
  LOCATION: 'Ort',
  DETAILS: 'Einzelheiten',
  COURSE_FEE: 'Kursgebühr',
  DOWNLOAD_FLYER: 'Flyer herunterladen',
  PREVIEW: 'Vorschau',
  OPEN_COURSE: 'Öffnen',
  SUBSCRIBE: 'Anmelden',
  MEDICAL_COURSES: 'Medizinische Kurse',
  NO_COURSES_FOUND: 'Keine Kurse gefunden',
  LEARN_MORE: 'Mehr erfahren',
  READ_MORE: 'Weiterlesen',
  SKILLS_OVERVIEW_TITLE: 'Verbesserungsmöglichkeiten',
  BACK: 'Zurück',
  PERSONAL_PLAN: 'Persönlicher Plan',
  PERSONAL_PLAN_TITLE: 'Personalisierter Lernplan',
  TEST_AGAIN: 'Erneut testen',
  SUBSCRIBED: 'Angemeldet',
  BOOKED: 'Gebucht',
  SUBSCRIBE_AFTER_EXPIRATION: 'Anmelden',
  EXPIRED: 'Abgelaufen',
  REQUESTED: 'Angefordert',
  STATUS_SUBSCRIBED: 'Status: Angemeldet',
  STATUS_UNSUBSCRIBED: 'Status: Abgemeldet',
  EMAIL_ADDRESS: 'E-Mail-Adresse',
  EMAIL_ADDRESS_HINT: 'E-Mail-Adresse eingeben',
  FIRST_NAME: 'Vorname',
  FIRST_NAME_HINT: 'Vorname eingeben',
  LAST_NAME: 'Familienname',
  LAST_NAME_HINT: 'Familienname eingeben',
  PROFESSION: 'Beruf',
  PROFESSION_HINT: 'Beruf auswählen',
  FIELD_OF_ACTIVITY_LABEL: 'Organisationstyp',
  FIELD_OF_ACTIVITY_HINT: 'Organisationstyp auswählen',
  ORGANIZATION_LABEL: 'Organisation',
  ORGANIZATION_HINT: 'Organisation auswählen',
  WORKING_EXPERIENCE_LABEL: 'Berufserfahrung',
  WORKING_EXPERIENCE_HINT: 'Berufserfahrung auswählen',
  FOCUS: 'Schwerpunkte (Mehrfachauswahl möglich)',
  SAVE: 'Speichern',
  SIGN_OUT: 'Abmelden',
  PROFESSIONS_MTRA: 'MTRA',
  PROFESSIONS_LEADING_MTRA: 'Leitende/r MTRA',
  PROFESSIONS_MFA: 'MFA',
  PROFESSIONS_RADIOLOGIST: 'Radiologe',
  PROFESSIONS_OTHER_CERTIFIED_XRAY: 'Andere technische Berufe mit Röntgen',
  PROFESSIONS_OTHER: 'Other',
  FIELD_OF_ACTIVITY_HOSPITAL: 'Krankenhaus',
  FIELD_OF_ACTIVITY_OFFICE: 'Praxis',
  FIELD_OF_ACTIVITY_MVZ: 'MVZ',
  WORK_EXPERIENCE_LONG: '> 5 Jahre',
  WORK_EXPERIENCE_MEDIUM: '3-5 Jahre',
  WORK_EXPERIENCE_SHORT: '1-2 Jahre',
  FOCUS_AREA_CT: 'CT',
  FOCUS_AREA_MR: 'MR',
  FOCUS_AREA_XRAY: 'Röntgen',
  FOCUS_AREA_NUCLEAR_MEDICINE: 'Nuklearmedizin',
  FOCUS_AREA_VETERINARY_MEDICINE: 'Veterinärmedizin',
  FOCUS_AREA_RADIOTHERAPY: 'Strahlentherapie',
  FOCUS_AREA_INTERVENTIONAL_RADIOLOGY: 'Interventionelle Radiologie',
  LANGUAGE: 'Sprache',
  APPLY: 'Anwenden',
  NO_COURSES_TITLE: 'Sie sind für keine Kurse angemeldet',
  NO_COURSES_NOT_SUBSCRIBED: 'Sie sind für keine Kurse angemeldet.',
  NO_COURSES_BROWSE_COURSES: 'Klicken Sie auf die Schaltfläche Kurse durchsuchen, um Kurse zu finden.',
  NO_COURSES_BROWSE_BUTTON: 'Kurse durchsuchen',
  SKILLS_QUIZ: 'Fähigkeiten Quiz',
  MY_COURSES_TITLE: 'Meine Kurse',
  PREPARATION: 'Vorbereitung',
  OVERVIEW: 'Überblick',
  SKILLS_RECOMMENDATION: 'Nehmen Sie am Quiz teil, um persönliche Kursempfehlungen zu erhalten.',
  CONTINUE: 'Weiter',
  CHOOSE_AREAS: 'Wählen Sie die für Sie relevanten Bereiche aus.',
  TAKE_QUIZ: 'Zum Quiz',
  RECOMMENDED_COURSES: 'Empfohlene Kurse',
  DELETE: 'Löschen',
  PERMANENTLY_DELETE: 'Konto dauerhaft löschen',
  PHILIPS_PROJECT_TITLE: 'Philips Learning Academy Icarus',
  DELETE_ACCOUNT_QUESTION: 'Lösche deinen Account?',
  DELETE_ACCOUNT_ARE_YOU_SURE:
    'Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Diese Aktion ist nicht rückgängig zu machen!',
  CANCEL: 'Stornieren',
};
