/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@dls/react-core';
import { em } from '@dls/react-theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import clsx from 'clsx';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { PageHeaderTitle } from '../../components/layout/page-header-title';
import { NotificationContext } from '../../components/notification/notification-context';
import { SearchBox } from '../../components/search-box/search-box';
import { Spinner } from '../../components/spinner/spinner';
import { useGAPageViewsTracking } from '../../ga360/useGAPageViewsTracking';
import { useCourseCollectionQuery } from '../../generated/client';
import { useTranslation } from '../../i18n/translation-provider';

import { AdminBooking } from './admin-booking';
import { AdminPageContainerInner, AdminPageContent, MenuTableCell, useAdminPageStyles } from './admin-page.styles';
import { AdminTableMenu, AdminTableMenuOption } from './admin-table-menu';
import { BookingState } from './booking-state';
import { HighlightText } from './highlight-text';
import { SubscriptionStatus } from './subscription-status';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useAdminPageStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'courseName',
    numeric: false,
    disablePadding: true,
    label: 'Course name',
  },
  { id: 'userId', numeric: false, disablePadding: true, label: 'E-mail address' },
  { id: 'stateUpdateDate', numeric: true, disablePadding: true, label: 'Subscription status' },
  { id: 'lastUpdateDate', numeric: true, disablePadding: true, label: 'Last update' },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className="sorting-header"
          >
            <TableSortLabel
              data-testid={`admin-sort-handler-${headCell.id}`}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key="actions" />
      </TableRow>
    </TableHead>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getStateUpdateDate(b: AdminBooking): number | null {
  if (b.state === BookingState.Rejected || b.state === BookingState.Revoked) {
    return moment(b.lastUpdate).valueOf();
  }

  if (b.state === BookingState.Requested) {
    return moment(b.requestDate).valueOf();
  }

  return b.expireDate ? moment(b.expireDate).valueOf() : null;
}

const changeBookingState = (booking: AdminBooking, requestedUpdate: AdminTableMenuOption) => {
  if (
    booking.state === BookingState.Rejected ||
    booking.state === BookingState.Requested ||
    booking.state === BookingState.Revoked
  ) {
    let newState = null;
    switch (requestedUpdate) {
      case AdminTableMenuOption.Accept:
        newState = {
          state: BookingState.Subscribed,
          expireDate: null,
        };
        break;
      case AdminTableMenuOption.AcceptFor3Months:
        newState = {
          state: BookingState.Subscribed,
          expireDate: moment().add(3, 'months').toISOString(),
        };
        break;
      case AdminTableMenuOption.AcceptFor6Months:
        newState = {
          state: BookingState.Subscribed,
          expireDate: moment().add(6, 'months').toISOString(),
        };
        break;
      case AdminTableMenuOption.AcceptFor12Months:
        newState = {
          state: BookingState.Subscribed,
          expireDate: moment().add(12, 'months').toISOString(),
        };
        break;
      case AdminTableMenuOption.Reject:
        newState = {
          state: BookingState.Rejected,
          expireDate: null,
        };
        break;
      default:
        throw new Error(`${requestedUpdate}is not a valid menu option for state ${booking.state}`);
    }

    return newState;
  }

  if (booking.state === BookingState.Subscribed && requestedUpdate === AdminTableMenuOption.Revoke) {
    return {
      state: BookingState.Revoked,
      expireDate: null,
    };
  }

  return null;
};

const LastUpdateFormat = 'D MMM YYYY HH:mm';

export const AdminPage: React.FC = () => {
  const classes = useAdminPageStyles();
  const [t, i18n] = useTranslation();
  const { data: courseCollection, loading: loadingCourses } = useCourseCollectionQuery({
    variables: { locale: i18n.language },
  });

  const [loading, setLoading] = useState<boolean>();
  const [bookings, setBookings] = useState<AdminBooking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<AdminBooking[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('name');

  const courses: ({ sys: { id: string }; title?: string | null; identifier?: string | null } | null)[] | undefined =
    courseCollection?.courseCollection?.items;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useGAPageViewsTracking({ description: 'admin', url: '/admin' });

  useEffect(() => {
    setFilteredBookings(
      bookings.filter(
        (b) =>
          b.userId.toLowerCase().includes(searchText.toLowerCase()) ||
          b.courseName.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [searchText, setFilteredBookings, bookings]);

  useEffect(() => {
    setLoading(true);
    const getBookings = async () => {
      const updatedBookings = await axios.get('/api/admin/bookings');
      const bookingsWithCourseData = updatedBookings.data.map((b: AdminBooking) => ({
        ...b,
        courseName: courses?.find((c) => c?.identifier === b.courseId)?.title || '',
        stateUpdateDate: getStateUpdateDate(b),
        lastUpdateDate: moment(b.lastUpdate).valueOf(),
      }));
      setBookings(bookingsWithCourseData);
      setOrder('desc');
      setOrderBy('lastUpdateDate');
      setLoading(false);
    };

    getBookings();
  }, [courses, setOrder, setOrderBy]);

  const { addNotification } = useContext(NotificationContext);

  const handleMenuClick = async (value: AdminTableMenuOption, bookingId: number) => {
    const booking = bookings.find((b) => b.id === bookingId);
    if (booking) {
      try {
        const newState = changeBookingState(booking, value);
        if (newState) {
          await axios.put(`/api/admin/bookings/${bookingId}`, newState);
          booking.state = newState.state;
          booking.expireDate = newState.expireDate;
          booking.stateUpdateDate = getStateUpdateDate(booking);
          booking.lastUpdateDate = moment().valueOf();
          const updatedBookings = [...bookings];
          setBookings(updatedBookings);
        }
      } catch (e) {
        addNotification({
          title: 'Error changing state',
          error: true,
          body: (e as Error).toString(),
        });
      }
    }
  };

  return (
    <PageContainer data-testid="admin-page">
      <HeaderBar title={t('ADMIN_PAGE')} />
      <AdminPageContainerInner>
        <AdminPageContent>
          <PageHeaderTitle />
          <div>
            {loading || loadingCourses ? (
              <Spinner />
            ) : (
              <div className={classes.root}>
                <Paper className="dlsTable">
                  <EnhancedTableHeaderToolbar setSearchText={setSearchText} />
                  <TableContainer>
                    <Table aria-label="simple table">
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {stableSort(filteredBookings, getComparator(order, orderBy)).map((row: AdminBooking) => {
                          return (
                            <TableRow key={row.id} tabIndex={-1} data-testid="admin-table-row">
                              <TableCell scope="row">
                                <HighlightText searchText={searchText} text={row.courseName} />
                              </TableCell>
                              <TableCell>
                                <HighlightText searchText={searchText} text={row.userId} />
                              </TableCell>
                              <TableCell>
                                <SubscriptionStatus booking={row} />
                              </TableCell>
                              <TableCell>{moment(row.lastUpdateDate).format(LastUpdateFormat)}</TableCell>
                              <MenuTableCell>
                                <AdminTableMenu id={row.id} onSelect={handleMenuClick} currentState={row.state} />
                              </MenuTableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            )}
          </div>
        </AdminPageContent>
      </AdminPageContainerInner>
    </PageContainer>
  );
};

const useToolbarStyles = makeStyles(() =>
  createStyles({
    title: {
      flex: '1 1 100%',
      paddingRight: `${em(24)}`,
      fontSize: `${em(18)}`,
      marginBottom: 'none',
    },
  }),
);

const EnhancedTableHeaderToolbar = ({ setSearchText }: { setSearchText: (searchText: string) => void }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx('dlsTableHeader')}>
      <Typography
        weight="bold"
        style={{ fontSize: `${em(18)}`, paddingRight: `${em(24, 18)}` }}
        className={classes.title}
        id="tableTitle"
      >
        Subscription overview
      </Typography>
      <SearchBox id="bookings-searchbox" onInput={setSearchText} onSearch={setSearchText} />
    </Toolbar>
  );
};
