import { Button } from '@dls/react-core';
import { Divider, List, ListItem, ListItemSecondaryAction, CardContent } from '@material-ui/core';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { DLSIcon } from '../../components/dls-icon/dls-icon';
import { HeaderBar } from '../../components/layout/header-bar';
import { PageContainer } from '../../components/layout/page-container';
import { useTranslation } from '../../i18n/translation-provider';
import { ColorDLS } from '../../utils/styling/colors';
import { InferReactComponentProps } from '../../utils/type-util';

import { SkillsBackButton, SkillsPageContent, StyledCard, StyledCardActions } from './skills.styles';

enum ProgramStatus {
  Unavailable = 'Unavailable',
  Available = 'Available',
  Subscribed = 'Subscribed',
}

type ProgramItem = {
  id: string;
  image: string;
  place: string;
  title: string;
  status: ProgramStatus;
};

export const SkillsPersonalPlanPage: React.FC = () => {
  const [t] = useTranslation();

  const programs = [
    {
      id: 'program-id-1',
      image: 'https://picsum.photos/200/300',
      place: 'Place 1',
      title: 'Digital radiography',
      status: ProgramStatus.Subscribed,
    },
    {
      id: 'program-id-2',
      image: 'https://picsum.photos/200/300',
      place: 'Place 2',
      title: 'Computed tomography',
      status: ProgramStatus.Subscribed,
    },
    {
      id: 'program-id-3',
      image: 'https://picsum.photos/200/300',
      place: 'Place 3',
      title: 'Communication',
      status: ProgramStatus.Available,
    },
    {
      id: 'program-id-4',
      image: 'https://picsum.photos/200/300',
      place: 'Place 4',
      title: 'Communication',
      status: ProgramStatus.Unavailable,
    },
  ];

  return (
    <PageContainer data-testid="skills-personal-plan-page">
      <HeaderBar title={t('SKILLS')} previousPage="/" />
      <SkillsPageContent>
        <StyledCard>
          <CardContent>
            <CardTitle>{t('PERSONAL_PLAN_TITLE')}</CardTitle>
            <ListContainer>
              <List>
                {programs.map((p, index: number) => (
                  <PersonalPlanItemContainer key={p.id}>
                    <PersonalPlanItem id={p.id} place={p.place} title={p.title} image={p.image} status={p.status} />
                    {index < programs.length - 1 && <Divider className="DLS-divider-in-list" />}
                  </PersonalPlanItemContainer>
                ))}
              </List>
            </ListContainer>
          </CardContent>
          <StyledCardActions>
            <SkillsBackButton variant="quietEmphasis">{t('BACK')}</SkillsBackButton>
            <UnstyledLink to="/skills/personal-plan" data-testid="skills-test-again-button">
              <Button dls_variant="quietEmphasis">{t('TEST_AGAIN')}</Button>
            </UnstyledLink>
          </StyledCardActions>
        </StyledCard>
      </SkillsPageContent>
    </PageContainer>
  );
};

const PersonalPlanItem: React.FC<ProgramItem> = ({ id, place, title, image, status }) => {
  const history = useHistory();

  const handleSubscribe = () => {
    history.push(`/courses/${id}`);
  };

  return (
    <ListItem button onClick={() => history.push(`/my-icarus/my-courses/${id}`)}>
      <ListItemImage src={image} />
      <ListItemContent>
        <ItemPlace>{place}</ItemPlace>
        <ItemTitle>{title}</ItemTitle>
        <ProgramItemStatus onSubscribe={handleSubscribe} status={status} />
      </ListItemContent>
      <StyledListItemSecondaryAction>
        <DLSIcon name="NavigationRight" size={24} />
      </StyledListItemSecondaryAction>
    </ListItem>
  );
};

const ProgramItemStatus: React.FC<{ status: ProgramStatus; onSubscribe: () => void }> = ({ status, onSubscribe }) => {
  const [t] = useTranslation();
  switch (status) {
    case ProgramStatus.Unavailable:
      return <StyledProgramItemStatus>{t('COMING_SOON')}!</StyledProgramItemStatus>;
    case ProgramStatus.Subscribed:
      return <StyledProgramItemStatus>{t('SUBSCRIBED')}</StyledProgramItemStatus>;
    case ProgramStatus.Available:
      // TODO: do something about click event causing ripple effect on ListItem, seems a bit challenging at the moment
      return (
        <ProgramItemSubscribeButton
          dls_variant="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSubscribe();
          }}
        >
          {t('SUBSCRIBE')}
        </ProgramItemSubscribeButton>
      );
    default: {
      throw new Error(status as never);
    }
  }
};

const ListItemContent = styled.div`
  display: grid;
  grid-template-rows: repeat(1fr);
  grid-row-gap: 4px;
`;

const ItemPlace = styled.div`
  font: 500 14px/18px CentraleSans;
  color: ${ColorDLS.Blue45};
`;

const ItemTitle = styled.div`
  font-weight: bold;
`;

const StyledProgramItemStatus = styled.div`
  font: 14px/18px CentraleSans;
`;

const ProgramItemSubscribeButton = styled(Button)<InferReactComponentProps<typeof Button>>`
  display: flex;
  justify-content: center;
  width: 168px;
`;

const CardTitle = styled.div`
  font: 20px/24px CentraleSans;
  color: ${ColorDLS.Gray75};
  padding: 0 24px 24px 24px;
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  cursor: pointer;
`;

const PersonalPlanItemContainer = styled.div``;

const ListItemImage = styled.img`
  width: 96px;
  height: 96px;
  margin-right: 24px;
`;

const ListContainer = styled.div`
  && {
    .MuiListItem-root {
      background-color: white;
      padding: 24px;
      align-items: flex-start;
    }
  }
`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;
