import { ApplicationThemeConfiguration } from '@dls/react-labs';
import React from 'react';
import { ThemeProvider } from 'styled-components';

export const theme: ApplicationThemeConfiguration = {
  // ...defaultTheme,
  color: 'group-blue',
  accent: 'group-blue',
  navigation: 'bright',
  tone: 'ultra-light',
  density: 'touch',
};

export const DlsThemeProvider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>{children}</>
    </ThemeProvider>
  );
};
